import { useEffect } from 'react'
import selectActiveUser from './store/user/userSelector'
import { $ReduxCoreType } from './types/reduxCore.ts'
import { useCustomSelector } from './utils/deepCheckSelector'

function ThirdPartyIntegrations() {
  const { orgId, userData, orgName } = useCustomSelector((state: $ReduxCoreType) => {
    return {
      orgId: state.appInfo?.currentOrgId,
      userData: selectActiveUser(state),
      orgName: state?.orgs?.orgs?.[state.appInfo?.currentOrgId]?.name
    }
  })
  // const  chatbottoken = useCustomSelector((state)=>(state.appInfo.chatbottoken))

  // Setting data to Microsoft Clarity and Hotjar
  //   useEffect(() => {
  //     // Set userId and orgId as custom properties in Microsoft Clarity
  //     if ((window as any).clarity && typeof (window as any).clarity === 'function') {
  //       ;(window as any).clarity('set', 'userEmail', userData?.userEmail)
  //       ;(window as any).clarity('set', 'orgId', orgId)
  //       ;(window as any).clarity('set', 'userId', userData?.userId)
  //     }

  //     if ((window as any).hj) {
  //       ;(window as any).hj('identify', userData?.userId, {
  //         orgId: orgId,
  //         userEmail: userData?.userEmail,
  //         orgName: orgName
  //       })
  //     }
  //   }, [userData?.userEmail, orgId, orgName, userData?.userId])

  // sedintg data to zaraz
  useEffect(() => {
    if (window?.zaraz && userData?.userId) {
      window.zaraz.set('userId', userData.userId)
      window.zaraz.set('userEmail', userData.email)
      window.zaraz.set('userFullName', userData.fullName)
    }
    if (orgId && orgName && window?.zaraz) {
      window.zaraz.set('orgId', orgId)
      window.zaraz.set('orgName', orgName)
    }
  }, [userData?.userId, userData?.email, userData?.fullName, orgId, orgName])

  return <div>{/* Third Party tools */}</div>
}

export default ThirdPartyIntegrations
