import { Skeleton } from '@mui/material'
import { Box } from '@mui/system'

export function EventPageSkeleton() {
  return (
    <Box className='gap-1 h-100 w-100 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
      {Array.from({ length: 10 }).map((_, index) => (
        // eslint-disable-next-line
        <Skeleton variant='rounded' key={index} className='event-page-actions' height={60} />
      ))}
    </Box>
  )
}
