import React from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useFetchActionsTriggers } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import ActionsListComponent from './ActionsListComponent.tsx'

/**
 * Props for the SelectedPlugToAddStep component.
 * @typedef {Object} SelectedPlugToAddStepProps
 * @property {Object} plug - The plug object containing details about the service.
 * @property {string} plug.name - The name of the service.
 * @property {string} plug.iconurl - The URL of the service icon.
 * @property {number} plug.rowid - The row ID of the service.
 * @property {Function} [addActionFunction] - Function to add an action.
 */

/**
 * A component that displays a list of actions for a selected plug.
 * @param {SelectedPlugToAddStepProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
function SelectedPlugToAddStep({
  plug,
  addActionFunction = () => {},
  type = 'action',
  showHeader = true,
  addAIAction = true
}: {
  plug: { name: string; iconurl: string; rowid: number }
  addActionFunction?: () => void
}): JSX.Element {
  const preferedauthversion = plug?.preferedauthversion
  const { data: serviceActions, isLoading } = useFetchActionsTriggers(plug?.rowid, type)
  const serviceActionsArray = Object.values(serviceActions || {}) || []
  if (preferedauthversion && type === 'action' && addAIAction)
    serviceActionsArray.push({
      name: 'Add Action (Contribute)',
      rowid: `custom-action-ai-${plug.rowid}`,
      pluginverified: true,
      type: 'action'
    })
  return (
    <ActionsListComponent
      serviceName={plug?.name}
      serviceIconUrl={plug?.iconurl}
      isLoading={isLoading}
      actionsArray={serviceActionsArray}
      showHeader={showHeader}
      addActionFunction={addActionFunction}
      isPluginVerified={plug?.pluginverified}
    />
  )
}

export default React.memo(addUrlDataHoc(React.memo(SelectedPlugToAddStep), []))
