import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { Api, AutoAwesome, Code, Javascript, Webhook } from '@mui/icons-material'
import { Box, lighten, MenuItem, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../enums'
import { useCustomSelector } from '../deepCheckSelector'
import { RecursiveMenu } from './RecursiveMenu.tsx'
import IconWrapper from '../../components/IconWrapper/IconWrapper.tsx'
import { filterObjectByKey } from '../../store/invocationV2/invocationSelectorV2'
import { lastIndexOfSpecialChar } from '../utilities'

const componentrenderer = {
  api: <Api className='w-full h-full' />,
  function: <Javascript className='w-full h-full' />,
  webhook: <Webhook className='w-full h-full' />,
  variable: <Code className='w-full h-full' />,
  aistep: <AutoAwesome className='w-full h-full' />
}

const initialMenuState = { anchorEl: null, childData: null, pathToPass: '' }

/**
 * @typedef {Object} ContextVariableMenuProps
 * @property {Function} useIt - Function to use the selected item.
 * @property {HTMLElement} parentAnchorEl - The parent element to anchor the menu.
 * @property {Function} setParentAnchorEl - Function to set the parent anchor element.
 * @property {string} sectionIdOrScriptId - The section or script ID.
 */

/**
 * ContextVariableMenu component
 * @param {ContextVariableMenuProps} props
 * @returns {JSX.Element}
 */
function ContextVariableMenu({ useIt, parentAnchorEl, setParentAnchorEl = () => {}, sectionIdOrScriptId, slugName }) {
  const { dataToList } = useCustomSelector((state) => {
    const flowjsonBlocks = state.flowJsonV2?.[sectionIdOrScriptId]?.flowJson?.blocks
    const context = filterObjectByKey(state, slugName, sectionIdOrScriptId)?.context
    const data = []

    // Refactored function to handle 'req', 'res', and 'vals'
    const processData = (contextKey) => {
      const componentType = contextKey === 'vals' ? 'variable' : contextKey === 'req' ? 'webhook' : 'function'
      const defaultKeys = contextKey === 'req' ? ['query', 'body'] : []
      const keys = Object.keys(context[contextKey])?.length ? Object.keys(context?.[contextKey]) : defaultKeys

      keys.forEach((key) => {
        const dataToPush = { content: context?.[contextKey]?.[key], label: key, path: `context.${contextKey}.${key}` }
        if (flowjsonBlocks?.[key]?.iconUrl) {
          dataToPush['iconUrl'] = flowjsonBlocks[key]?.iconUrl
        }
        if (flowjsonBlocks?.[key]?.aistep) dataToPush['component'] = 'aistep'
        else dataToPush['component'] = flowjsonBlocks?.[key]?.type === 'api' ? 'api' : componentType
        data.push(dataToPush)
      })
    }

    // Process 'req', 'res', and 'vals'
    ;(window.location.pathname.startsWith('/developer/') ? ['authData', 'inputData'] : ['req', 'res', 'vals']).forEach((key) => {
      if (context?.[key]) {
        processData(key)
      }
    })

    return { dataToList: data }
  })
  const [menuState, setMenuState] = React.useState(initialMenuState)

  /**
   * Handle menu item hover event
   * @param {React.MouseEvent<HTMLElement>} event
   * @param {Object} item
   */
  const handleMenuItemHover = (event, item) => {
    setMenuState({
      anchorEl: event.currentTarget,
      childData: item.content,
      pathToPass: item.path,
      nameToPass: item.label
    })
  }

  const handleClick = () => {
    setMenuState(initialMenuState)
    setParentAnchorEl(null)
  }
  useEffect(() => {
    window.addEventListener('click', handleClick)
    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  const position = parentAnchorEl?.getBoundingClientRect()

  const style = {
    background: 'rgba(255, 255, 255, 0.9)' /* Semi-transparent white */,
    backdropFilter: 'blur(1px)' /* Blurry effect */,
    border: '0.2px solid #ccc',
    height: 'fit-content'
  }

  const parentStyle = {
    paddingTop: position.bottom + 50 > window.innerHeight ? `${position.top - 60}px` : `${position.bottom + 15}px`,
    maxHeight: '100vh',
    height: 'fit-content',
    width: 'fit-content',
    position: 'fixed',
    top: 0,
    right: window.innerWidth - position.left - 30,
    zIndex: 1000000,
    minWidth: '200px',
    overflowY: 'auto'
  }
  const addSenetorychecksForUseIt = (inputval) => {
    if (inputval?.startsWith('context.req')) {
      useIt({ content: inputval })
      return
    }
    const parts = inputval.split(/\.|\[|\]/).filter(Boolean)
    // Initialize the result with the first part and build the rest using optional chaining
    let result = parts[0]
    for (let i = 1; i < parts.length; i++) {
      // Check if it's a number (array index), so we use the bracket notation for it
      if (!Number.isNaN(Number(parts[i]))) {
        result += `?.[${parts[i]}]`
      } else if (i <= 2 && ['vals', 'res'].includes(parts[1])) {
        result += `.${parts[i]}`
      } else result += lastIndexOfSpecialChar(parts[i]) > -1 ? `?.['${parts[i]}']` : `?.${parts[i]}`
    }
    useIt({ content: result })
  }
  return (
    <>
      {parentAnchorEl ? (
        <Box sx={parentStyle}>
          <Box sx={style}>
            {dataToList?.length ? (
              dataToList.map((item) => {
                const isNestingInside =
                  typeof item.content === 'object' && Object.keys(item?.content || {})?.length && item.content !== null
                const selected = menuState?.pathToPass === item.path

                return item.label ? (
                  <MenuItem
                    key={item?.path}
                    sx={selected ? { backgroundColor: lighten('#0288d1', 0.85), color: 'white' } : {}}
                    onMouseEnter={(event) => {
                      if (isNestingInside) handleMenuItemHover(event, item)
                      else setMenuState(initialMenuState)
                    }}
                    onClick={() => addSenetorychecksForUseIt(item.path)}
                    className='flex justify-start items-center gap-2'
                  >
                    {isNestingInside ? <ArrowLeftIcon color='gray' /> : <Box className='w-6' />}
                    {item?.iconUrl ? (
                      <IconWrapper iconUrl={item?.iconUrl} size='24px' />
                    ) : (
                      <IconWrapper size='24px' component={componentrenderer[item?.component]} />
                    )}
                    <Typography>{item.label}</Typography>
                    {!isNestingInside && ['boolean', 'string', 'number']?.includes(typeof item?.content) && (
                      <Typography
                        variant='smallgrey'
                        className='text-ellipsis overflow-hidden max-w-[150px]'
                      >{`${item?.content}`}</Typography>
                    )}
                  </MenuItem>
                ) : null
              })
            ) : (
              <MenuItem>
                <Typography>No options</Typography>
              </MenuItem>
            )}
          </Box>
        </Box>
      ) : null}
      {menuState.childData && (
        <RecursiveMenu
          data={menuState.childData}
          parentAnchorEl={menuState.anchorEl}
          level={1}
          setParentAnchorEl={() => setMenuState(initialMenuState)}
          useIt={addSenetorychecksForUseIt}
          path={menuState.pathToPass}
          name={menuState?.nameToPass}
        />
      )}
    </>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(ContextVariableMenu), [
    ParamsEnums.stepId,
    ParamsEnums.sectionIdOrScriptId,
    ParamsEnums.slugName,
    ParamsEnums.tabName
  ])
)
