import socketIcon from './socketIcon.png'
import socketAIIcon from './Viasocket_AI.svg'
import masterCard from './aftersignupPage/mastercard.png'
import visa from './aftersignupPage/visa.png'
import paypal from './aftersignupPage/paypal.png'
import mi from './aftersignupPage/mi.png'
import americanExpress from './aftersignupPage/american-express.png'
import ixigo from './aftersignupPage/ixigo.png'
import unacadamy from './aftersignupPage/unacadamy.png'
import socket from './aftersignupPage/socket.svg'
import dream11 from './aftersignupPage/dream11.png'
import indeed from './aftersignupPage/indeed.png'
import logos from './Logos.png'
import surprise from './surpriseimg.png'
import defaultApp from './App.png'
import viasocketBlackImage from './viasocketImageBlack.svg'
import JSimage from './JSIcon.svg'
import viasocketWhiteImage from './viasocketImageWhite.svg'
import aiStarLogo from './aiStarLogo.svg'
import newLogo from './newLogo.svg'
import Image from './Image.png'
import GradientBar from './gradientBar.svg'
import CreateFlowGradient from './CreateFlowGradient.svg'
import supportGirlImage from './supportGirl.png'
import pathImage from './path.png'
import interventionImage from './intervention.png'
import apiImage from './api.png'
import aiImage from './ai.png'
import delayImage from './delay.png'
import commentImage from './comment.png'
import memoryImage from './memory.png'

export const GradientBarBG = GradientBar
export const CreateFlowGradientBG = CreateFlowGradient
export const ImageBar = Image
export const ChatBotIcon = socketAIIcon
export const SocketIcon = socketIcon
export const MasterCard = masterCard
export const Visa = visa
export const Paypal = paypal
export const AmericanExpress = americanExpress
export const Ixigo = ixigo
export const Dream11 = dream11
export const Unacadamy = unacadamy
export const Indeed = indeed
export const Mi = mi
export const JSIcon = JSimage
export const pathIcon = pathImage
export const interventionIcon = interventionImage
export const apiIcon = apiImage
export const aiIcon = aiImage
export const supportGirlIcon = supportGirlImage
export const delayIcon = delayImage
export const commentIcon = commentImage
export const memoryIcon = memoryImage

export const Trusted = logos
export const Surprise = surprise

export const DefaultApp = defaultApp
export const ViaocketBlackIcon = viasocketBlackImage
export const ViaocketWhiteIcon = viasocketWhiteImage
export const AiStarLogo = aiStarLogo
export const newLabelLogo = newLogo
export const socketLogoWithImage = socket

function makeImageUrl(imageId: string): string {
  return `https://imagedelivery.net/Vv7GgOGQbSyClWJqhyP0VQ/${imageId}/public`
}

export const AI_WHITE_ICON = makeImageUrl('b1357e23-2fc6-4dc3-855a-7a213b1fa100')
export const AI_BLACK_ICON = makeImageUrl('91ee0bff-cfe3-4e2d-64e5-fadbd9a3a200')
