import * as React from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Divider } from '@mui/material'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { changeScriptStatus } from '../../store/scripts/scriptsThunk'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, sliderTypes, Tabnames } from '../../enums'
import { openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import config from '../../config'
// import { TalkToSupport } from '../../pages/dashboard/NewDashboardSidbar.tsx'
import FlowShareButton from '../functiondashboard/workFlow/workFlowNavbar/FlowShareButton.tsx'

/**
 * A React component providing more options for flow page actions  like [ Move To Trash , Transfer Data , Move , Duplicate , Pause , Active ] and are handle for embed case also
 *
 * @param {Object} props - The properties passed to the component are scriptId , serviceId , projectId ,orgId
 * @returns {JSX.Element} - The rendered drop down of options.
 */
export const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    PaperProps={{
      sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
          width: 32,
          height: 32
        },
        '&:before': {
          content: '""',
          display: 'block',
          position: 'absolute',
          top: 0,
          right: 6,
          width: 10,
          height: 10,
          bgcolor: 'background.paper', // Equivalent to theme.palette.background.paper
          transform: 'translateY(-50%) rotate(45deg)',
          zIndex: 0
        }
      }
    }}
    {...props}
  />
))()
function FlowPageMoreOptions(props) {
  const { orgId, projectId, isEmbedUrl } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { scriptInfo, transferOption, responseType, hideDelete } = useCustomSelector((state) => {
    const { flowJson, publishedFlowJson } = state.flowJsonV2?.[props?.scriptId] || {}
    const triggerId = flowJson?.trigger?.selectedCreate
    const hideDelete = state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.permitsingleflow?.[triggerId]
    return {
      scriptInfo: state.scripts.scripts[props?.scriptId],
      transferOption: publishedFlowJson?.trigger?.transferOption,
      responseType: flowJson?.blocks?.response?.responseType,
      hideDelete
    }
  })
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [openShareModal, setOpenShareModal] = React.useState(false)

  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleStatusChange = (status) => {
    dispatch(changeScriptStatus({ projectId: scriptInfo?.project_id, scriptId: scriptInfo?.id, oldstatus: scriptInfo?.status, status }))
  }

  const handleTransferDataClick = () => {
    const { orgId, projectId, serviceId, scriptId } = props
    navigate(
      isEmbedUrl
        ? `/integrations/embed/${orgId}/${projectId}${serviceId ? `/service/${serviceId}` : ''}/workflow/${scriptId}/${
            Tabnames.DRAFT
          }/transfer`
        : `${config.projectsBaseUrl}/${orgId}/${projectId}/service/${serviceId}${config.workflowBaseUrl}/${scriptId}/${Tabnames.DRAFT}/transfer`
    )
  }
  const handleAddDelay = () => {
    dispatch(openModalRedux({ modalType: 'delayModal' }))
  }

  return (
    <>
      <MoreHorizIcon
        className='cur-pointer'
        onClick={handleClick}
        color='black'
        size='small'
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
      />
      <StyledMenu
        anchorEl={anchorEl}
        id='account-menu'
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {scriptInfo?.status !== '2' && <MenuItem onClick={() => handleStatusChange('2')}>Pause</MenuItem>}
        {!isEmbedUrl &&
          scriptInfo?.json_script?.trigger?.triggerType === sliderTypes.WEBHOOK &&
          (responseType === 'custom' ? (
            <Tooltip placement='left' title='Cannot add delay in custom response'>
              <span>
                <MenuItem disabled>Add Delay</MenuItem>
              </span>
            </Tooltip>
          ) : (
            <MenuItem onClick={handleAddDelay}>Add Delay</MenuItem>
          ))}
        {transferOption && <MenuItem onClick={handleTransferDataClick}>Transfer Data</MenuItem>}
        {/* <TalkToSupport /> */}
        {!isEmbedUrl && (
          <MenuItem
            onClick={(e) => {
              e.stopPropagation()
              setOpenShareModal(true)
            }}
          >
            Share
          </MenuItem>
        )}
        {/* <MenuItem onClick={redirectToLearnBasicPage}>Learn Basics</MenuItem> */}
        {scriptInfo?.status !== '0' && !hideDelete && (
          <>
            <Divider />
            <MenuItem color='error' onClick={() => handleStatusChange('0')}>
              Move To Trash
            </MenuItem>
          </>
        )}
      </StyledMenu>
      <FlowShareButton open={openShareModal} setOpen={setOpenShareModal} handleMenuClose={handleClose} />
    </>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(FlowPageMoreOptions), [
    ParamsEnums.scriptId,
    ParamsEnums.serviceId,
    ParamsEnums.projectId,
    ParamsEnums.orgId,
    ParamsEnums.isEmbedUrl
  ])
)
