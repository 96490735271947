import React, { useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Avatar, Box, Button, Divider, IconButton, ListItemIcon, MenuItem, TextField, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { Logout } from '@mui/icons-material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { fetchOrgs, renameOrgThunk } from '../../store/orgs/orgsThunk'
import { closeModalRedux, openModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { getCurrentEnvironment, removeCookie, validateOrgName } from '../../utils/utilities'
import AllOrgs from './AllOrgs.tsx'
import { logoutUserFromMsg91 } from '../../api/index'
import CreateOrgModal from '../../pages/formbilling/createOrgModal.tsx'
import AdaptiveComponentForModalAndPage from '../AdaptiveComponentForModalAndPage/AdaptiveComponentForModalAndPage.tsx'
import SearchBar from '../projectdashboard/searchBar'
import selectActiveUser from '../../store/user/userSelector'
import { StyledMenu } from '../flowPageMoreOptions/flowPageMoreOptions'
import config from '../../config'

const returnTimezoneAbbreviationsList = (orgList: any) => {
  const date = new Date()
  return orgList.map((obj) => {
    const timeZoneObj = {
      timeZone: obj?.meta?.timezoneIdentifier,
      timeZoneName: 'short'
    }
    const formatter = new Intl.DateTimeFormat('en-US', timeZoneObj)
    const formatted = formatter.format(date)
    const match = formatted.match(/[A-Za-z]+/) // Match the first sequence of letters
    return {
      ...obj,
      timezone: obj?.timezone ? `${obj?.timezone}${match?.[0]}` : obj?.timezone
    }
  })
}

export const handleLogOut = async (navigate) => {
  try {
    await logoutUserFromMsg91()
    removeCookie(getCurrentEnvironment())
    localStorage.clear()
    sessionStorage.clear()
    if (process.env.REACT_APP_API_ENVIRONMENT === 'prod') window.location.replace(process.env.REACT_APP_VIASOCKET_HOME_PAGE_URL)
    else navigate('/')
  } catch (e) {
    console.error(e)
  }
}
function WorkspacesComponent({ isModal = false, orgId = '' }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { userDetails, orgList, orgObj, appInfo } = useCustomSelector((state: $ReduxCoreType) => ({
    userDetails: selectActiveUser(state),
    orgList: selectActiveOrgs(state)?.sort((a, b) => a?.name?.localeCompare(b?.name)) || [],
    orgObj: state?.orgs?.orgs?.[orgId],
    appInfo: state.appInfo
  }))
  const orgIcon = orgObj?.meta?.iconUrl

  useEffect(() => {
    const handleEscKeyPress = (event) => {
      if (event.key === 'Escape') {
        dispatch(closeModalRedux())
      }
    }

    document.addEventListener('keydown', handleEscKeyPress)

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress)
    }
  }, [dispatch])
  useEffect(() => {
    dispatch(fetchOrgs())
  }, [])

  const [newTitle, setNewTitle] = useState('')
  // const [showTextField, setShowTextField] = useState('-1')
  const [orgListVariable, setOrgListVariable] = useState(orgList || [])

  useEffect(() => {
    const newOffsetAddedOrgList = returnTimezoneAbbreviationsList(orgList)
    setOrgListVariable(newOffsetAddedOrgList)
  }, [orgList])
  const addNewWorkspace = () => {
    dispatch(openModalRedux({ modalType: 'createOrg' }))
  }

  const handleTextFieldClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setNewTitle(e.target.value)
  }

  const inputFieldHandler = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const handleRenameOnClick = (org, value) => {
    if (!validateOrgName(value, orgList, setOrgListVariable)) return
    const orgId = org.id
    dispatch(renameOrgThunk({ body: { title: value }, orgId }))
  }
  const handleBackButton = () => navigate('/org')
  const [showOnHover, setShowOnHover] = useState(false)
  const selectedName = (
    <Box className='flex justify-start items-center gap-2 text-truncate'>
      <Box onMouseEnter={() => setShowOnHover(true)} onMouseLeave={() => setShowOnHover(false)}>
        {showOnHover ? (
          <IconButton onClick={handleBackButton}>
            <ArrowBackIcon />
          </IconButton>
        ) : (
          <IconWrapper
            size='24px'
            iconUrl={orgIcon}
            component={
              orgIcon ? (
                <img src={orgIcon} alt='icon' width='100%' />
              ) : (
                <Avatar variant='square'>
                  <Typography className='flex item-center justify-center text-right pl-2' fontWeight='600'>
                    {orgObj?.name?.toUpperCase()?.substring(0, 2) || ''}
                  </Typography>
                </Avatar>
              )
            }
          />
        )}
      </Box>
      <Typography fontWeight='600' className='ml-2'>
        {orgObj?.name || ''}
      </Typography>
    </Box>
  )

  const returnComponentForRenamingScript = (org) => {
    return (
      <Box
        key={org.id}
        className={`${isModal ? 'workspace__modal__element' : 'workspace__element '} ${
          orgId === org.id ? 'selected_org' : ''
        } flex justify-between items-center w-full box-border`}
      >
        <Box className='flex justify-start items-center w-full gap-3'>
          <Avatar variant='rounded'>
            <Typography fontWeight='600'>{org?.name?.toUpperCase()?.substring(0, 2) || ''}</Typography>
          </Avatar>
          <TextField
            inputProps={{ maxLength: 25 }}
            size='small'
            noborder='true'
            autoFocus
            fullWidth
            className='title-textfield'
            scriptname='true'
            onBlur={() => {
              // setShowTextField('-1')
              handleRenameOnClick(org, newTitle)
            }}
            id='outlined-helperText'
            defaultValue={org.name}
            onChange={handleTextFieldClick}
            onClick={inputFieldHandler}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                // setShowTextField('-1')
                handleRenameOnClick(org, newTitle)
              }
            }}
          />
        </Box>
      </Box>
    )
  }

  const [orgSearch, setOrgSearch] = useState('')
  const newOrgButtonAndSearchbar = (
    <Box className='flex justify-between'>
      <Box className='w-1/3'>
        <SearchBar
          height='49px'
          styles={{
            borderBottom: 'none',
            paddingLeft: '8px',
            DataGridAndButtonWithSearchBarCombined: 'true'
          }}
          iconStyles={{
            color: 'var(--col-dark)'
          }}
          placeholder='Search'
          handleSearchBar={(value) => {
            setOrgSearch(value)
          }}
          value={orgSearch}
        />
      </Box>
      {appInfo.mode !== MiscTypes.SSO_MODE && (
        <Button className='max-w-max create-org-button !border-b-0' variant='contained' createOrgButton onClick={addNewWorkspace}>
          CREATE NEW WORKSPACE
          <AddIcon fontSize='small' />
        </Button>
      )}
    </Box>
  )
  const userProfilePic =
    typeof userDetails?.userProfilePic === 'string' && userDetails?.userProfilePic
      ? userDetails?.userProfilePic
      : userDetails?.fullName
          ?.split(' ')
          .slice(0, 2)
          .map((name) => name.charAt(0).toUpperCase())
          .join('')

  const isValidProfilePic = userProfilePic?.length > 2
  const [anchorEl, setAnchorEl] = useState(null)
  const anchorRef = useRef()
  const handleClick = (event) => {
    if (anchorRef?.current) setAnchorEl(anchorRef?.current)
    else setAnchorEl(event.target)
  }

  const handleClose = () => {
    setAnchorEl(null) // Close the menu
  }
  const profileMenu = (
    <Box className='flex max-h-max items-center'>
      <Box onClick={handleClick} className='flex cursor-pointer hover:bg-gray-200 hover:shadow-md hover:bg-opacity-75 '>
        <Box ref={anchorRef}>
          <IconWrapper
            iconUrl={isValidProfilePic ? userProfilePic : undefined}
            component={!isValidProfilePic ? userProfilePic : undefined}
          />
        </Box>
        <ExpandMoreIcon expandAcoordion big isExpanded={Boolean(anchorEl)} selectedWorkspace />
      </Box>
      <StyledMenu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Typography className='p-2.5'>{userDetails?.email}</Typography>
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose()
            navigate(config.updateUserPage)
          }}
          className='p-2'
        >
          <ListItemIcon>
            <AccountCircleIcon />
          </ListItemIcon>
          Edit Profile
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleLogOut(navigate)
            handleClose()
          }}
          className='p-2'
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Logout
        </MenuItem>
      </StyledMenu>
    </Box>
  )

  return (
    <Box className='min-h-screen bg-workspace flex justify-center'>
      <AdaptiveComponentForModalAndPage
        selectedName={selectedName}
        description='Create your workspace and take the first step toward easier task management.'
        // subheading={userDetails.email}
        newOrgButtonAndSearchbar={newOrgButtonAndSearchbar}
        isModal={isModal}
        closeOnChangeOf={orgId}
        heading='Select a Workspace'
        body={<AllOrgs {...{ orgListVariable, returnComponentForRenamingScript, isModal, orgSearch }} />}
        profileMenu={profileMenu}
      />
      <CreateOrgModal />
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(WorkspacesComponent), [ParamsEnums.orgId]))
