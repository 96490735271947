import React from 'react'
import Button from '@mui/material/Button'
import { Box, DialogActions, DialogTitle } from '@mui/material'
import { useDispatch } from 'react-redux'
import axios from '../../interceptor/interceptor'
import { closeModalRedux, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { errorToast } from '../customToast'
import { MiscTypes, ModalTypeEnums, ParamsEnums } from '../../enums'
import CustomModal from '../customModal/customModal'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

function AuthorizeOrgModal({ orgId }) {
  const dispatch = useDispatch()
  const { selectedOrg, modalType } = useCustomSelector((state) => ({
    selectedOrg: state?.orgs?.orgs?.[orgId],
    modalType: state.appInfo.modalType
  }))

  const closeModal = () => {
    dispatch(closeModalRedux())
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    const extractDataFromSessionStorage = () => {
      const client_id = sessionStorage.getItem('client_id')
      const redirect_uri = sessionStorage.getItem('redirect_uri')
      const scope = sessionStorage.getItem('scope')
      const response_type = sessionStorage.getItem('response_type')
      const state = sessionStorage.getItem('state')
      return {
        client_id,
        redirect_uri,
        scope,
        response_type,
        state
      }
    }

    // 2. Build a new URL with the extracted data
    const { client_id, redirect_uri, scope, response_type, state } = extractDataFromSessionStorage()
    const apiUrl = process.env.REACT_APP_CODE_AUTHORIZATION // Replace with your API endpoint
    const urlWithParams = new URL(apiUrl)
    urlWithParams.searchParams.append('client_id', client_id)
    urlWithParams.searchParams.append('redirect_uri', redirect_uri)
    urlWithParams.searchParams.append('scope', scope)
    urlWithParams.searchParams.append('response_type', response_type)
    // 3. Make an API call using Axios
    try {
      const response = await axios.post(urlWithParams.toString(), { org_id: selectedOrg.id }).catch((error) => {
        // Handle errors here
        console.error('API call error:', error)
      })
      const code = response.data?.code // Assuming the API response contains the code
      // Redirect the user to the redirect_uri with the code in query params
      dispatch(updateAppInfoState({ mode: MiscTypes.FLOW }))
      dispatch(closeModalRedux())
      window.location.replace(`${redirect_uri}?code=${code}&state=${state}`)
    } catch (error) {
      errorToast('unable to authorize')
    }
  }

  return (
    <CustomModal
      className='orgbox__model'
      fullScreen={false}
      openModal={modalType === ModalTypeEnums.AUTHORIZE_ORG}
      // openModal={appInfo.isModalOpen }
    >
      <Box className='custom-modal p-2  dialogMinMax OauthAuthorizationModal' key='authorization-org' component='form' onSubmit={onSubmit}>
        <DialogTitle>Authorize Organization - {selectedOrg?.name}</DialogTitle>
        <DialogActions>
          <Button className='m-2' type='submit' variant='contained'>
            Allow
          </Button>
          <Button className='m-2' onClick={closeModal}>
            Cancel
          </Button>
        </DialogActions>
      </Box>
    </CustomModal>
  )
}
export default React.memo(addUrlDataHoc(React.memo(AuthorizeOrgModal), [ParamsEnums.orgId]))
