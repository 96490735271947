import { Alert, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useMemo, useState } from 'react'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'

type AuthSuccessPopUpLevelProps = {
  setAuthLevel: (level: string) => void
  authLevel: string
  isEmbedUrl?: boolean
  orgId: string
  projectId?: string
  scriptId?: string
  currentOrgName?: string
}

function AuthSuccessPopUpLevel({
  authLevel,
  setAuthLevel,
  orgId,
  isEmbedUrl,
  projectId,
  scriptId,
  currentOrgName
}: AuthSuccessPopUpLevelProps) {
  const { folderName, scriptName } = useCustomSelector((state: $ReduxCoreType) => ({
    folderName: state.projects?.projects?.[orgId]?.active?.[projectId]?.title,
    scriptName: state.scripts?.scripts?.[scriptId]?.title || 'Webhook flow'
  }))
  const [dropdown, setDropdown] = useState(false)
  const [showAlert, setShowAlert] = useState(false)

  const setDropdownTrue = () => {
    if (!isEmbedUrl) {
      setDropdown(true)
    } else {
      setShowAlert(true)
    }
  }
  const handleChange = (event = null) => {
    try {
      setAuthLevel(event?.target?.value)
      setDropdown(false)
    } catch (error) {
      // do nothing
    }
  }

  const titleToShow: string = useMemo(
    () => (authLevel === orgId ? currentOrgName || 'Workspace' : authLevel === projectId ? folderName : scriptName),
    [authLevel]
  )

  return (
    !isEmbedUrl && (
      <Box className='!w-full border-b authentication-success-modal'>
        {!dropdown && (
          <Typography
            className='!w-full cursor-pointer whitespace-nowrap !truncate'
            onClick={() => {
              setDropdownTrue()
            }}
          >
            This connection will be available for entire{' '}
            {authLevel === orgId ? 'workspace' : authLevel === projectId ? 'project' : 'script'} -{' '}
            <Tooltip title={titleToShow} placement='top-start'>
              <span className='underline-offset-2 text-[var(--colo-blue)] font-bold !truncate'>{titleToShow}</span>
            </Tooltip>
          </Typography>
        )}
        {showAlert && (
          <Alert severity='warning' className='py-2' style={{ maxWidth: '500px' }}>
            You cant change connection level here
          </Alert>
        )}
        {dropdown && (
          <Box className='!w-full'>
            <Typography className='pt-2'>Choose where you want to add your connection</Typography>
            <Box className='py-2 w-100'>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>level</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={authLevel || ''}
                  label='Age'
                  onChange={handleChange}
                >
                  {orgId && <MenuItem value={orgId}>Workspace level</MenuItem>}
                  {folderName && <MenuItem value={projectId}>Folder level</MenuItem>}
                  {scriptId && <MenuItem value={scriptId}>Script level</MenuItem>}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
      </Box>
    )
  )
}

export default React.memo(AuthSuccessPopUpLevel)
