import { Box, Typography } from '@mui/material'
import React from 'react'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { categories } from '../../pages/developerHub/constants/developerHubConstants.ts'
import AppsCategoryWise from './AppsCategoryWise.tsx'

/**
 * A functional component that renders a list of plugins for a given organization and project.
 * Displays skeleton loader while fetching data and renders plugin cards when data is loaded.
 *
 * @param {Object} props - Component props
 * @param {string} props.orgId - The organization ID
 * @param {string} props.projectId - The project ID
 * @param {Function} [props.onClickOnElement] - Callback function for element click, defaults to a no-op function
 *
 * @returns {JSX.Element} The rendered component
 */
function AllAppsAddStep(props: {
  orgId: string
  projectId: string
  pageState: string
  triggerServiceIdToSkip?: string
  onClickOnElement?: (plug: any) => void
}): JSX.Element {
  const { orgId, projectId, pageState, setPageState = () => {}, triggerServiceIdToSkip } = props
  const { filteredPlugins } = useCustomSelector((state) => {
    const filteredServices =
      (state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.filteredServices
        : state.appInfo.localConfiguration?.filteredServices) || {}
    return {
      filteredPlugins: Object.keys(filteredServices) || []
    }
  })
  const { data: plugins, isLoading: loading } = useFetchPlugins(orgId, [], filteredPlugins)
  const pluginsArray = loading ? [] : Object.values(plugins || {})?.filter((plug) => plug.rowid !== triggerServiceIdToSkip)

  if (pageState?.category) return <AppsCategoryWise category={pageState?.category} {...props} />
  return (
    <Box className='flex flex-col w-full'>
      <Typography className='p-2' variant='button' sx={{ color: 'text.secondary' }}>
        Categories
      </Typography>
      {categories?.map((category, index) => {
        const icons = pluginsArray
          ?.filter((plug) => plug?.category.includes(category))
          ?.slice(0, 3)
          ?.map((plug) => plug?.iconurl)
        return (
          <Box
            sx={{
              borderBottom: categories?.length - 1 !== index ? '0.2px solid #ccc' : 'unset'
            }}
            onClick={() => setPageState((prev) => ({ ...prev, category }))}
            key={category}
            className='px-3 py-2 cursor-pointer hoverable-block w-full gap-2 flex items-center justify-between'
          >
            <Typography variant='h6'>{category}</Typography>
            <Box className='flex justify-start items-center gap-3'>
              {icons?.map((icon) => {
                return <IconWrapper iconUrl={icon} key={icon} size='24px' />
              })}
              <ArrowForwardIosIcon className='opacity-0' fontSize='small' />
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AllAppsAddStep), [ParamsEnums.projectId, ParamsEnums.orgId]))
