import { Box, Divider, List, ListItemButton, ListItemText, ListSubheader, Typography } from '@mui/material'
import React from 'react'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums } from '../../../enums'
import { useFetchActionsTriggers } from '../../../react-query/allPluginsData/allPluginsDataQueries.ts'
import ShowTriggerGroupingWithActions from './ShowTriggerGroupingWithActions.tsx'

function SelectedServiceForIntegration({
  firstService,
  secondService,
  permittedEvents = [],
  type = 'both',
  unpermittedEvents = [],
  createflow = () => {},
  showDrawerOnly = false
}) {
  const secondServiceActionsAndTriggers = useFetchActionsTriggers(secondService, 'both')?.data || {}

  const secondServiceTriggers =
    type === 'action' ? [] : Object.values(secondServiceActionsAndTriggers)?.filter((action) => action.type === 'trigger')
  const secondServiceActions = Object.values(secondServiceActionsAndTriggers)?.filter((action) => action.type !== 'trigger')

  const firstServiceActionsAndTriggers = useFetchActionsTriggers(firstService, 'both', permittedEvents)?.data || {}

  const firstServiceTriggers = secondServiceActions?.length
    ? Object.values(firstServiceActionsAndTriggers)?.filter(
        (action) => !unpermittedEvents.includes(action.rowid) && action.type === 'trigger'
      )
    : []
  const firstServiceActions = secondServiceTriggers?.length
    ? Object.values(firstServiceActionsAndTriggers)?.filter(
        (action) => !unpermittedEvents.includes(action.rowid) && action.type !== 'trigger'
      )
    : []

  const handleScrollToTrigger = (id) => {
    const triggerGrouping = document?.getElementById(`${id}-grouping`)
    if (triggerGrouping) triggerGrouping?.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return showDrawerOnly ? (
    <Box className='flex h-screen sticky top-0 left-0 ' id='triggers-scroll-sidebar' sx={{ minWidth: '60px', maxWidth: '200px' }}>
      <List disablePadding>
        <ListSubheader className='no-wrap'>{firstServiceTriggers?.[0]?.pluginname} Triggers</ListSubheader>
        {firstServiceTriggers?.map((trigger) => (
          <ListItemButton key={trigger.rowid} onClick={() => handleScrollToTrigger(trigger.rowid)}>
            <ListItemText primary={<Typography noWrap>{trigger.name}</Typography>} />
          </ListItemButton>
        ))}
        {secondServiceTriggers?.length ? (
          <ListSubheader className='no-wrap'>{secondServiceTriggers?.[0]?.pluginname} Triggers</ListSubheader>
        ) : null}

        {secondServiceTriggers?.map((trigger) => (
          <ListItemButton key={trigger.rowid} onClick={() => handleScrollToTrigger(trigger.rowid)}>
            <ListItemText primary={<Typography noWrap>{trigger.name}</Typography>} />
          </ListItemButton>
        ))}
      </List>
      <Divider orientation='vertical' />
    </Box>
  ) : (
    <Box className='w-full flex flex-col gap-5 h-full  ' sx={{ paddingBottom: '50vh' }}>
      <ShowTriggerGroupingWithActions triggers={firstServiceTriggers} actions={secondServiceActions} createflow={createflow} />
      <ShowTriggerGroupingWithActions triggers={secondServiceTriggers} actions={firstServiceActions} createflow={createflow} />
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(SelectedServiceForIntegration), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.isEmbedUrl
  ])
)
