import React from 'react'
import { Box, Tooltip } from '@mui/material'
import { JavascriptOutlined, ApiSharp } from '@mui/icons-material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import AutoAwesomeSharpIcon from '@mui/icons-material/AutoAwesomeSharp'
import CodeIcon from '@mui/icons-material/Code'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useCustomSelector } from '../../../../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../../../../types/reduxCore.ts'
import addUrlDataHoc from '../../../../../hoc/addUrlDataHoc.tsx'
import { BlockTypes, ParamsEnums } from '../../../../../enums'
import IconWrapper from '../../../../IconWrapper/IconWrapper.tsx'
import ShowTriggerIcon from './ShowTriggerIcon.tsx'

function showStepsIcon(order, blocks) {
  function getBlockOfEachStep(singleBlock, index, stepSlugName) {
    function getIconOfEachStep() {
      if ([BlockTypes.FUNCTION, BlockTypes.API].includes(singleBlock?.type)) {
        let icon = null
        if (singleBlock?.iconUrl) icon = <img alt='' className='w-full' src={singleBlock?.iconUrl} />
        else if (singleBlock?.type === 'api') icon = <ApiSharp className='w-full h-full' />
        else if (singleBlock?.aistep) icon = <AutoAwesomeSharpIcon className='w-full h-full' />
        else icon = <JavascriptOutlined className='w-full h-full' />
        return <IconWrapper component={icon} size='24px' />
      }
      // if (singleBlock?.type === BlockTypes.COMMENT) {
      //   return <></>
      //   // return '//'
      // }
      // if (singleBlock?.type === BlockTypes.IFGROUP) {
      //   return <></>
      //   // return <AltRouteIcon className='transform-180' fontSize='small' />
      // }
      // if (singleBlock?.type === BlockTypes.IFBLOCK) {
      //   return <></>
      //   // return <Chip label='If' color='primary' />
      // }
      if (singleBlock?.type === BlockTypes.VARIABLE) {
        return <IconWrapper component={<CodeIcon className='w-full h-full' />} size='24px' />
      }
      if (singleBlock?.type === BlockTypes.PLUG) {
        return <IconWrapper iconUrl={singleBlock?.iconUrl} size='24px' />
      }
      return null
    }
    return (
      <Tooltip
        placement='top'
        // onClick={(e) => {
        //   e.preventDefault()
        //   setSearchParams({ slugName: stepSlugName, stepId: singleBlock.identifier })
        // }}
        title={stepSlugName}
        className='flex items-center'
      >
        {getIconOfEachStep()} &nbsp; &nbsp; &nbsp;
      </Tooltip>
    )
  }
  return (
    <>
      {(order?.['root'] || []).length === 0 && <AddCircleIcon />}
      {(order?.['root'] || [])?.map((stepSlugName, index) => {
        return getBlockOfEachStep(blocks[stepSlugName], index + 1, stepSlugName)
      })}
    </>
  )
}

function WorkFlowTitleWithStepsIcons({ scriptId, flowJsonOfTemplate }: any) {
  const { flowJson } = useCustomSelector((state: $ReduxCoreType) => ({
    flowJson: flowJsonOfTemplate || state.flowJsonV2[scriptId]?.draftedFlowJson || {}
  }))

  return (
    <Box className='flex items-center overflow-x-scroll whitespace-nowrap dont-show-on-expand'>
      {/* TRIGGER BLOCK */}
      <ShowTriggerIcon triggerInfo={flowJson?.trigger} />
      &nbsp;&nbsp;
      <ArrowForwardIcon />
      &nbsp;&nbsp;
      {/* steps */}
      {showStepsIcon(flowJson?.order || {}, flowJson?.blocks || {})}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(WorkFlowTitleWithStepsIcons), [ParamsEnums.scriptId]))
