import { useDispatch } from 'react-redux'
import { Box, Button, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ParamsEnums, Tabnames } from './enums'
import addUrlDataHoc from './hoc/addUrlDataHoc.tsx'
import { getSingleTemplate } from './api/index'
import WorkFlowTitleWithStepsIcons from './components/functiondashboard/workFlow/workFlowNavbar/workFlowTitleDetail/workFlowTitleWithStepsIcons.tsx'
import { saveScriptTemplate } from './store/scripts/scriptsThunk'
import config from './config'
import { errorToast } from './components/customToast'

function SingleTemplateComponent({ templateId, orgId }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [template, setTemplate] = useState({})
  async function getAndSetTemplate() {
    const data = await getSingleTemplate(templateId)
    setTemplate(data)
  }
  useEffect(() => {
    getAndSetTemplate()
  }, [templateId])
  function createFlowWithTemplate() {
    dispatch(saveScriptTemplate({ projectId: `proj${orgId}`, templateId })).then((e) => {
      if (e.payload?.id) {
        const flowUrl = `${config.projectsBaseUrl}/${orgId}/proj${orgId}${config.workflowBaseUrl}/${e.payload?.id}/${Tabnames.DRAFT}`
        navigate(flowUrl)
      } else errorToast('Flow creation failed.')
    })
  }
  return (
    <Box className='w-full  flex flex-col h-screen gap-3 p-4'>
      <Typography variant='h5'>{template?.title}</Typography>
      <Typography>{template?.metadata?.description}</Typography>
      <WorkFlowTitleWithStepsIcons flowJsonOfTemplate={template?.published_json_script} />

      <Button variant='contained' onClick={createFlowWithTemplate}>
        Create Flow
      </Button>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(SingleTemplateComponent), [ParamsEnums.orgId, ParamsEnums.templateId]))
