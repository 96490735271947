import { Outlet, useNavigate } from 'react-router-dom'
import { Box, Divider, List, ListItemButton, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getAllTemplatesOfOrg } from './api'
import addUrlDataHoc from './hoc/addUrlDataHoc.tsx'
import AddTemplateButton from './AddTemplateButton.tsx'
import { ParamsEnums } from './enums'

function NewTemplateslider({ orgId, templateId }) {
  const navigate = useNavigate()
  const [templates, setTemplates] = useState([])

  async function getTemplates() {
    const results = await getAllTemplatesOfOrg(orgId)
    setTemplates(results)
  }
  useEffect(() => {
    getTemplates()
  }, [])

  return (
    <Box className='flex  w-full   h-screen  '>
      <Box className='flex flex-col w-full overflow-y-auto h-full' sx={{ maxWidth: '250px' }}>
        <Typography variant='h5' className='p-2'>
          Templates
        </Typography>
        <List className='overflow-scroll-y'>
          {templates?.map((template) => {
            return (
              <ListItemButton
                key={template?.id}
                onClick={() => {
                  navigate(`/org/${orgId}/templates/${template?.id}`)
                }}
                selected={templateId === template?.id}
                divider
              >
                {template.title || '[NO NAME]'}
              </ListItemButton>
            )
          })}
        </List>
        <AddTemplateButton setTemplates={setTemplates} />
      </Box>
      <Divider orientation='vertical' />
      <Outlet />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(NewTemplateslider), [ParamsEnums.orgId, ParamsEnums.templateId]))
