import { SliceCaseReducers, createSlice } from '@reduxjs/toolkit'
import { initialState, reducers, extraReducers } from './flowJsonReducerV2.ts'
import { $FlowJsonReduxType } from '../../types/reduxCore.ts'

const flowJsonSlice = createSlice<$FlowJsonReduxType, SliceCaseReducers<$FlowJsonReduxType>, 'flowJsonV2'>({
  name: 'flowJsonV2',
  initialState,
  reducers,
  extraReducers
})

export const {
  setUpdateBlockForSampleData,
  setRequiredFlowJson,
  setFlowJsonIsLoading,
  setIsCronLoading,
  clearFlowJson,
  setMetaDataForFlowJson,
  setFlowJsonAndUsedVariables,
  setFlowAndDraftFlowJson,
  setPublishedFlowJson,
  setIsTriggerEditable,
  setIsTriggerDeleted,
  startCreateDraftStepThunk,
  successDraftStepThunk,
  setTemplateSetting,
  rejectedDraftStepThunk,
  updateDraftedFlowJsonTrigger
} = flowJsonSlice.actions

export default flowJsonSlice.reducer
