import { createSlice } from '@reduxjs/toolkit'
import actionType from '../../../types/utility.ts'
import { $AllPluginsType } from '../../../types/reduxCore.ts'
import { ActionType, PluginInfoAirtableType } from '../../../types/Plugin.ts'

const initialState: $AllPluginsType = {
  isLoading: true,
  isLoadingAction: true,
  allPluginList: [],
  allTriggersList: [],
  allActionOfSelectedPluginOrTriggerList: [],
  allActionOfTriggerList: [],
  configurationJsonEncrypted: '',
  configurationJson: ''
}
export const allPlugins = createSlice({
  name: 'allPlugins',
  initialState,
  reducers: {
    updatePluginData: (state, action) => {
      state.configurationJson = action.payload?.configurationJson
      state.configurationJsonEncrypted = action.payload?.configurationJsonEncrypted
    },
    resetConfigurationJson: (state) => {
      state.configurationJson = undefined
      state.configurationJsonEncrypted = undefined
    },
    resetAllPlugins: () => {
      return { ...initialState }
    },
    fetchPluginData: (state) => {
      state.isLoading = true
    },
    onFetchPlugInFetchSuccess: (state, action: actionType<PluginInfoAirtableType[]>) => {
      state.allPluginList = action.payload.pluginData
      state.allTriggersList = action.payload.triggerData
      state.isLoading = false
    },
    onFetchPlugInFetchRejected: (state) => {
      state.isLoading = false
    },
    setTriggerList: (state, action: actionType<ActionType[]>) => {
      state.allTriggersList = action.payload
    }
  }
})
export const {
  resetAllPlugins,
  fetchPluginData,
  onFetchPlugInFetchSuccess,
  onFetchPlugInFetchRejected,
  setTriggerList,
  updatePluginData,
  resetConfigurationJson
} = allPlugins.actions
export default allPlugins.reducer
