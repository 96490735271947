import React from 'react'
import { Box, Skeleton } from '@mui/material'

function FlowPageSkeleton() {
  return (
    <Box className='h-100 w-100 flex flex-col items-center' sx={{ bgcolor: 'var(--col-main-container)' }}>
      <Skeleton variant='rectangular' width='100%' height={50} />

      <Box
        className='flex justify-center items-center overflow-hidden h-full w-full my-4 border-sharp workflow-background'
        sx={{
          maxWidth: '1440px',
          display: 'flex',
          justifyContent: 'center', // Centers horizontally
          alignItems: 'start' // Centers vertically (optional)
        }}
      >
        <Box className=' flex-col-start-start'>
          <Box className='workflow__flow w-100 flex-col-start-start gap-3 '>
            <Skeleton variant='text' className='w-50 font-3rem' />
            <Box className='w-100 flex-start-center gap-1'>
              <Skeleton variant='rectangular' width={90} height={30} />
              <Skeleton variant='rectangular' width={90} height={30} />
            </Box>
            <Box>
              <Box className='w-100 flex-verticalcenter gap-2 mb-2'>
                <Skeleton variant='text' width={75} className='font-2rem' />
                <Skeleton variant='text' width={200} className='font-1rem' />
              </Box>
              <Skeleton variant='text' className='w-100 font-1rem' />
            </Box>
            <Box className='w-100'>
              <Skeleton variant='text' width={50} className='font-2rem' />
              <Box className='flex-col-start-center gap-2'>
                <Skeleton variant='rectangular' width={500} height={50} />
                <Skeleton variant='rectangular' width={500} height={50} />
                <Skeleton variant='rectangular' width={500} height={50} />
                <Box className='flex-center w-100 gap-3'>
                  <Skeleton variant='rectangular' width={30} height={30} />
                  <Skeleton variant='rectangular' width={454} height={30} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box className='workflow__dryrun flex-spaceAround-center py-2 w-100 gap-3 cursor-pointer '>
            <Skeleton variant='rectangular' width={454} height={30} />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default FlowPageSkeleton
