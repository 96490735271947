import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import Avatar from '@mui/material/Avatar'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCompanyBilling, updateCompanyBilling, verifyBasicAuth } from '../../api'
import { errorToast, successToast } from '../../components/customToast'
import AuthFields from '../../components/plugin/pluginComponents/basicAuth/authFields'
import config from '../../config'
import { MiscTypes } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'

/**
 * Extracts all query parameters from the current URL.
 *
 * @returns {Object} An object containing all query parameters.
 */
interface AuthenticatePluginProps {
  authInfo: any
  plugData: any
  backButton: boolean
  isBasic: boolean
  origin: string
}
function AuthenticatePlugin({
  authInfo,
  plugData,
  backButton,
  orgid,
  projectid,
  scriptid,
  userid,
  level,
  authidtoupdatetoken,
  mode,
  isUpdate,
  redirectUrl,
  message,
  buttonMessage,
  isBasic = false,
  origin = ''
}: AuthenticatePluginProps) {
  const authLevelId = level === 'project' ? projectid : orgid
  const [authData, setAuthData] = useState({})
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  const handleBackButton = async () => {
    if (!backButton) return
    if (isBasic) {
      navigate(-1)
      return
    }
    let url = `${config.servicePage}/${plugData?.rowid}`
    const { search } = window.location

    const queryParams = new URLSearchParams(search)
    queryParams.delete('actionid')

    const updatedSearch = queryParams.toString()
    if (updatedSearch) {
      url += `?${updatedSearch}`
    }

    navigate(url, {
      state: {
        pluginDetails: plugData
      }
    })
  }

  const handleBasicVerifyAuth = async () => {
    const authDetails = {
      service_id: authInfo?.pluginrecordid,
      service_name: authInfo?.pluginname,
      user_id: userid,
      script_id: scriptid,
      project_id: projectid,
      org_id: orgid,
      connection_label: JSON.stringify({
        [authInfo?.connectionlabelkey]: authInfo?.connectionlabelvalue
      }),
      info: {
        path: authInfo?.authenticationpaths,
        whitelist: authInfo?.whitelistdomains,
        skipwhitelistvalidation: authInfo?.skipwhitelistvalidation
      },
      is_connection_label_masked: authInfo?.isconnectionlabelmasked || false,
      auth_version_id: authInfo?.rowid,
      access_level_id: authLevelId,
      id: authidtoupdatetoken?.length ? authidtoupdatetoken : undefined,
      meta_data: {
        description: ''
      }
    }
    const dataToSend = {
      authData,
      code: authInfo?.testcode,
      authDetails: { ...authDetails },
      updateInDB: authidtoupdatetoken?.length > 0
    }

    const res = await verifyBasicAuth(dataToSend)
    if (res?.data?.success === true) {
      successToast('Verified')
      if (window.opener) {
        window.opener.postMessage(
          { ...res?.data?.data },
          mode === MiscTypes.EMBED_MODE ? process.env.REACT_APP_EMBED_URL : process.env.REACT_APP_FRONTEND_URL
        )
        if (origin === 'setting') {
          const details = (await getCompanyBilling(orgid))?.data?.data
          await updateCompanyBilling(orgid, { ...details, discounted_app_id: authInfo?.pluginrecordid, callBack: true })
        }
        window.close()
      } else {
        navigate(config.authCongoPage, { state: { message: message, button: buttonMessage, redirectUrl: redirectUrl } })
      }
    } else if (window.opener) {
      window.opener.postMessage(
        { error: 'Authentication failed. Please check your credentials and try again. If the problem persists, contact support.' },
        mode === MiscTypes.EMBED_MODE ? process.env.REACT_APP_EMBED_URL : process.env.REACT_APP_FRONTEND_URL
      )
      window.close()
    }
  }
  const verifyButton = async () => {
    const authfields = authInfo?.authfields
    const { authentication } = authfields || {}
    if (!authentication?.fields) {
      return true
    }
    return authentication?.fields.every((field) => !field.required || (authData[field.key] && authData[field.key] !== ''))
  }

  const handleBasicVerifyAuthFinal = async () => {
    setLoading(true)
    const isVerified = await verifyButton()
    if (isVerified) {
      await handleBasicVerifyAuth()
    } else {
      errorToast('Please enter data in the required fields.')
    }
    setLoading(false)
  }

  const [showContent, setShowContent] = useState(false)
  useEffect(() => {
    if (!authInfo?.authfields?.authentication?.fields?.length) {
      handleBasicVerifyAuthFinal()
    } else {
      setShowContent(true)
    }
  }, [])

  if (!showContent && loading)
    return (
      <Box className='w-screen h-screen grid place-items-center'>
        <Typography variant='h1'> Connecting ....</Typography>
      </Box>
    )
  if (!showContent) return null

  return (
    <Box className='p-4 flex-col-center-center gap-3'>
      <Typography variant='h4' className='mt-5 w-100'>
        {isUpdate === 'true' ? 'Update' : 'Add New'} Connection
      </Typography>
      <Box
        className='flex-start-center w-100 pr-2 my-2'
        onClick={() => {
          handleBackButton()
        }}
      >
        {backButton && <ArrowBackIosIcon fontSize='small' />}
        <Avatar alt={plugData?.name} src={plugData?.iconurl} variant='extrasmall' className='mr-2' />
        <Typography variant='h5' className='mr-3 '>
          {' '}
          {plugData?.name}
        </Typography>
      </Box>
      {/* <Typography variant='h6' className=' w-100'>
        Verify your connection
      </Typography> */}
      <Box className='w-100 flex-col gap-2'>
        <AuthFields authType={authInfo?.type} authFields={authInfo?.authfields} setAuthData={setAuthData} />
        {/* <AuthLevel setAuthLevel={setAuthLevel} authLevel={authLevel} /> */}
      </Box>
      {authInfo?.rowid && (
        <Box className='w-100 flex-start-center'>
          <Button
            variant='outlined'
            className=' color-black bg-white'
            startIcon={loading ? <CircularProgress size={18} /> : null}
            disabled={loading}
            onClick={handleBasicVerifyAuthFinal}
          >
            {isUpdate === 'true' ? 'Update ' : 'Add '} Connection
          </Button>
        </Box>
      )}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(AuthenticatePlugin), [
    'orgid',
    'projectid',
    'scriptid',
    'userid',
    'level',
    'authidtoupdatetoken',
    'mode',
    'isUpdate',
    'redirectUrl',
    'message',
    'buttonMessage'
  ])
)
