import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Typography, Box, Menu } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import './AdaptiveComponentForModalAndPage.scss'
import { updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'

function AdaptiveComponentForModalAndPage({
  isModal,
  subheading,
  footer,
  body,
  heading,
  selectedName,
  description = '',
  newOrgButtonAndSearchbar = null,
  darkbg = false,
  closeOnChangeOf = '',
  profileMenu = null
}) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const orgnameref = React.useRef(null)

  const { mode } = useCustomSelector((state) => ({
    mode: state.appInfo.mode
  }))
  useEffect(() => {
    if (mode === MiscTypes.OAUTH_MODE) {
      setAnchorEl(orgnameref.current)
    }
  }, [])

  useEffect(() => {
    handleClose()
  }, [closeOnChangeOf])
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(updateAppInfoState({ isProjectSliderOpen: true }))
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const pageContent = (
    <Box
      className={`flex flex-col min-h-full w-full  pb-0 items-center justify-start min-w-[400px] ${
        !isModal ? 'workspace__page p-11 gap-2' : ''
      }`}
    >
      <Box className='flex flex-col w-full'>
        <Box className='flex justify-between'>
          <Box>
            {!isModal && heading && (
              <Typography variant='h3' orgPageHeading='true' className='text-nowrap'>
                {heading}
              </Typography>
            )}
            {!isModal && description && <Typography>{description}</Typography>}
          </Box>

          {profileMenu && profileMenu}
        </Box>
      </Box>

      <Box className='w-full flex flex-col h-full'>
        {subheading && (
          <Typography variant='subtitle1' className='color-black' align='center'>
            {subheading}
          </Typography>
        )}
        {newOrgButtonAndSearchbar && newOrgButtonAndSearchbar}

        {body && <Box className='workspace__orglist w-full flex flex-col gap-1'>{body}</Box>}
        {footer && <Box className='footer'>{footer}</Box>}
      </Box>
    </Box>
  )
  if (isModal)
    return (
      <Box
        className={`select_workspace flex justify-start items-center  gap-1 text-ellipsis overflow-hidden ${anchorEl ? 'selected ' : ''} `}
      >
        <Box
          className='flex justify-start items-center w-full cursor-pointer text-ellipsis overflow-hidden gap-1'
          ref={orgnameref}
          onClick={handleClick}
          aria-controls='account-menu'
          aria-haspopup='true'
          aria-expanded='true'
        >
          {selectedName}
          {anchorEl ? (
            <ExpandLessIcon color={darkbg ? 'white' : 'black'} fontSize='small' />
          ) : (
            <ExpandMoreIcon color={darkbg ? 'white' : 'black'} fontSize='small' />
          )}
        </Box>

        <Menu anchorEl={anchorEl} id='account-menu' open={Boolean(anchorEl)} onClose={handleClose}>
          {pageContent}
        </Menu>
      </Box>
    )
  return pageContent
}
export default React.memo(addUrlDataHoc(React.memo(AdaptiveComponentForModalAndPage), [ParamsEnums.orgId]))
