import { forwardRef } from 'react'
import { TextField } from '@mui/material'

const UpdateTitleOrDescriptionOfAuth = forwardRef(({ value, setValue, handleDoneButton, handleToggleAndTitle = null }, ref) => {
  const handleOnChange = (e) => {
    if (handleToggleAndTitle) handleToggleAndTitle(e)
    else setValue(e.target.value)
  }

  const handleKeyDown = async (e) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      await handleDoneButton()
    }
  }

  return (
    <TextField
      value={value}
      onChange={(e) => {
        e.stopPropagation()
        handleOnChange(e)
      }}
      autoFocus
      inputRef={ref}
      placeholder='Enter Title...'
      noborder='true'
      projectSlider='true'
      commentstep='true'
      fullWidth
      type='text'
      onClick={(e) => {
        e.stopPropagation()
      }}
      onKeyDown={handleKeyDown}
      className='!mb-2'
    />
  )
})

UpdateTitleOrDescriptionOfAuth.displayName = 'UpdateTitleOrDescriptionOfAuth'

export default UpdateTitleOrDescriptionOfAuth
