import React, { useEffect, useRef, useState } from 'react'
import Joi from 'joi'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useValidator } from 'react-joi'
import { useDispatch } from 'react-redux'
import { Box } from '@mui/material'
import { closeModalRedux } from '../../../store/appInfo/appInfoSlice.ts'
import { selectActiveOrgs } from '../../../store/orgs/orgsSelector'
import { errorToast } from '../../customToast'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import { $ReduxCoreType } from '../../../types/reduxCore.ts'

export default function CreateInputModalV2({
  id,
  onClose = () => {},
  handleSubmit = () => {},
  setValue,
  setDomain,
  title,
  contentText,
  label,
  buttonTag
}) {
  const inputRef = useRef(null)
  const dispatch = useDispatch()
  const [isSubmitBtnDisable, setIsSubmitBtnDisable] = useState(true)
  const orgList = useCustomSelector((state: $ReduxCoreType) => selectActiveOrgs(state))
  const { modalType } = useCustomSelector((state: $ReduxCoreType) => ({
    modalType: state?.appInfo?.modalType
  }))
  // Use useEffect to set autofocus when the component mounts
  useEffect(() => {
    inputRef.current.focus()
  }, [])

  // Joi implementation
  const { state, setData, setExplicitField } = useValidator({
    initialData: {
      [id]: null
    },
    schema: Joi.object({
      [id]: Joi.string().min(5).max(50).required()
    }),
    explicitCheck: {
      [id]: false
    },
    validationOptions: {
      abortEarly: true
    }
  })

  const createInputJoi = (e) => {
    e.persist()
    setData((old) => ({
      ...old,
      [id]: e.target.value
    }))
  }

  const closeModal = () => {
    if (id === 'orgtitle' && orgList.length === 0) {
      errorToast('Create atleast one Organization')
      return
    }
    onClose('')
    dispatch(closeModalRedux())
  }

  const onSubmit = async (e) => {
    setIsSubmitBtnDisable(true)
    const result = await handleSubmit(e)
    if (result) {
      dispatch(closeModalRedux())
    }
    setIsSubmitBtnDisable(false)
  }

  const handleOnChange = (e) => {
    const inputValue = e.target?.value
    if (inputValue && inputValue.length > 0) {
      setIsSubmitBtnDisable(false)
    } else {
      setIsSubmitBtnDisable(true)
    }
    setValue(inputValue)
  }

  const handlePluginDomain = (e) => {
    const inputValue = e.target?.value
    if (inputValue && inputValue.length > 0) {
      setIsSubmitBtnDisable(false)
    } else {
      setIsSubmitBtnDisable(true)
    }
    setDomain(inputValue)
  }

  return (
    <Box className='w-100' key={title} component='form' onSubmit={onSubmit}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        <TextField
          inputProps={{ maxLength: 50 }}
          inputRef={inputRef}
          error={state?.$errors?.[id].length === 0 ? false : !!state.$errors?.[id]}
          key={title}
          margin='dense'
          id={id}
          name={id}
          label={label}
          type='text'
          fullWidth
          variant='standard'
          onChange={(e) => {
            handleOnChange(e)
            createInputJoi(e)
          }}
          onBlur={() => setExplicitField(`${id}`, true)}
        />
        {modalType === 'createPlugin' && (
          <TextField
            inputProps={{ maxLength: 50 }}
            // inputRef={inputRef}
            // error={state?.$errors?.[id].length === 0 ? false : !!state.$errors?.[id]}
            required
            // key={title}
            margin='dense'
            // id={id}
            name='Plugin domain'
            label='Plugin domain'
            placeholder='Ex. viasocket.com'
            type='text'
            fullWidth
            variant='standard'
            onChange={(e) => {
              handlePluginDomain(e)
              // createInputJoi(e)
            }}
            // onBlur={() => setExplicitField(`${id}`, true)}
          />
        )}
      </DialogContent>
      <DialogActions className='px-4'>
        <Button type='submit' disabled={isSubmitBtnDisable} variant='contained'>
          {buttonTag}
        </Button>
        <Button onClick={closeModal}>Cancel</Button>
      </DialogActions>
    </Box>
  )
}
