import { useState, MouseEvent } from 'react'
import { ArrowLeftIcon } from '@mui/x-date-pickers'
import { Box, lighten, MenuItem, Typography } from '@mui/material'

interface MenuState {
  anchorEl: HTMLElement | null
  childData: any
  pathToPass: string | null
  name?: string
}

interface RecursiveMenuProps {
  data: any
  parentAnchorEl: HTMLElement | null
  level?: number
  useIt?: (arg: { content: string }) => void
  path?: string
  name?: string
}

const initialMenuState: MenuState = { anchorEl: null, childData: null, pathToPass: null }

/**
 * RecursiveMenu component to render nested menus.
 * @param {Object} props - Component props.
 * @param {any} props.data - Data to render in the menu.
 * @param {HTMLElement | null} props.parentAnchorEl - Parent element to anchor the menu.
 * @param {number} [props.level=0] - Level of the menu for styling.
 * @param {Function} [props.useIt=() => {}] - Callback function when a menu item is clicked.
 * @param {string} [props.path=''] - Path to the current menu item.
 * @param {string} [props.name=''] - Name of the current menu item.
 * @returns {JSX.Element} The rendered RecursiveMenu component.
 */
export function RecursiveMenu({
  data,
  parentAnchorEl,
  level = 0,
  useIt = () => {},
  path = '',
  name = ''
}: RecursiveMenuProps): JSX.Element {
  const [menuState, setMenuState] = useState<MenuState>({ anchorEl: null, childData: null, pathToPass: path })
  const position = parentAnchorEl?.getBoundingClientRect()
  const handleListItemHover = (event: MouseEvent<HTMLElement>, item: any, newPath: string, name?: string) => {
    if (isObject(item) && (Object.keys(item)?.length || item.length)) {
      setMenuState({
        anchorEl: event.currentTarget,
        childData: item,
        pathToPass: newPath,
        name
      })
    } else {
      setMenuState(initialMenuState) // Close child menu when it's a leaf node
    }
  }

  const isObject = (val: any): val is object => val && typeof val === 'object'

  const menuWidth = 300 // Assuming minWidth of 200px
  const spaceOnLeft = position?.left ?? 0
  const spaceOnRight = window.innerWidth - (position?.right ?? 0)

  let openToLeft = true // Default to opening left
  if (spaceOnLeft >= menuWidth) {
    openToLeft = true
  } else if (spaceOnRight >= menuWidth) {
    openToLeft = false
  } else {
    // Not enough space on either side, choose the side with more space
    openToLeft = spaceOnLeft > spaceOnRight
  }

  const parentStyle = {
    height: '100vh',
    position: 'fixed',
    top: 0,
    zIndex: 1000000 + level,
    minWidth: '200px',
    overflowY: 'scroll',
    paddingTop: `${position?.top}px`,
    ...(openToLeft ? { right: window.innerWidth - position?.left } : { left: position?.right }),
    pointerEvents: 'none'
  }

  const style = {
    background: 'rgba(255, 255, 255, 0.9)' /* Semi-transparent white */,
    backdropFilter: 'blur(1px)' /* Blurry effect */,
    border: '0.2px solid #ccc',
    maxWidth: '300px',

    zIndex: 1000055 + level,
    boxShadow: '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)',
    height: 'fit-content',
    pointerEvents: 'auto !important'
  }

  return parentAnchorEl ? (
    <>
      <Box sx={parentStyle}>
        <Box id={`menu-${path}`} sx={style}>
          {Array.isArray(data)
            ? data.map((item, index) => {
                const newPath = `${path}[${index}]`
                const isLeafNode = !isObject(item)
                const selected = menuState?.pathToPass === newPath
                return (
                  <MenuItem
                    key={newPath}
                    onMouseEnter={(e) => {
                      if (!isLeafNode) handleListItemHover(e, item, newPath)
                      if (isLeafNode && menuState?.pathToPass) setMenuState(initialMenuState)
                    }}
                    sx={selected ? { backgroundColor: lighten('#0288d1', 0.85), color: 'white' } : {}}
                    className='flex-start-center gap-2'
                    onClick={() => useIt(newPath)}
                  >
                    {!isLeafNode ? <ArrowLeftIcon color='gray' /> : <Box sx={{ width: '24px' }} />}
                    <Typography
                      className=' text-overflow-eclipse'
                      sx={selected ? { color: 'white' } : {}}
                    >{`${name}[${index}]`}</Typography>
                    {isLeafNode && (
                      <Typography variant='smallgrey' className='show-value text-overflow-eclipse'>
                        {`${item}`}
                      </Typography>
                    )}
                  </MenuItem>
                )
              })
            : Object.keys(data).map((key) => {
                const item = data[key]
                const newPath = path.startsWith('context.req') ? `${path}?.['${key}']` : `${path}${path ? '.' : ''}${key}`
                const isLeafNode = !isObject(item)
                const selected = menuState?.pathToPass === newPath
                return (
                  <MenuItem
                    sx={selected ? { backgroundColor: lighten('#0288d1', 0.85), color: 'white' } : {}}
                    key={key}
                    className='flex-start-center gap-2'
                    onMouseEnter={(e) => {
                      if (!isLeafNode) handleListItemHover(e, item, newPath, key)
                      if (isLeafNode && menuState?.pathToPass) setMenuState(initialMenuState)
                    }}
                    onClick={() => useIt(newPath)}
                  >
                    {!isLeafNode ? <ArrowLeftIcon color='gray' /> : <Box sx={{ width: '24px' }} />}
                    <Typography className=' text-overflow-eclipse' sx={selected ? { color: 'white' } : {}}>
                      {key}
                    </Typography>
                    {isLeafNode && (
                      <Typography variant='smallgrey' className='show-value text-overflow-eclipse'>
                        {`${item}`}
                      </Typography>
                    )}
                  </MenuItem>
                )
              })}
        </Box>
      </Box>

      {menuState?.childData && (
        <RecursiveMenu
          data={menuState.childData}
          parentAnchorEl={menuState.anchorEl}
          level={level + 1}
          useIt={useIt}
          path={menuState.pathToPass}
          name={menuState?.name}
        />
      )}
    </>
  ) : null
}
