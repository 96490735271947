import React, { useState } from 'react'
import { useNavigate, useLocation, Link as RouterLink } from 'react-router-dom'
import { Box, Stack } from '@mui/system'
import { Avatar, Collapse, IconButton, List, ListItem, ListItemButton, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import config from '../../config'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { handleLogOut } from './WorkspacesComponent.tsx'

interface SelectedWorkspaceProps {
  orgId: string
  open: boolean
  usersList: Array<any>
  ChatWithAI: boolean
  closeChat: any
}
function SelectedWorkspace({ orgId, open, usersList }: SelectedWorkspaceProps) {
  const { orgObj } = useCustomSelector((state) => ({
    orgObj: state?.orgs?.orgs?.[orgId]
  }))
  const orgIcon = orgObj?.meta?.iconUrl

  const navigate = useNavigate()
  const handleBackButton = () => navigate('/org')
  const [showOnHover, setShowOnHover] = useState(false)

  const handleNavigations = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, to: string) => {
    e.stopPropagation()
    switch (to) {
      case 'orgs':
        navigate(config.orgBaseUrl)
        break

      case 'setting':
        navigate(`${config.orgBaseUrl}/${orgId}/${to}`)
        break
      case 'templates':
        navigate(`${config.orgBaseUrl}/${orgId}/${to}`)
        break

      case 'members':
        navigate(`${config.orgBaseUrl}/${orgId}/invite`)
        break
      case 'oauth':
        navigate(`${config.orgBaseUrl}/${orgId}/oauth`)
        break

      case 'pgBuilder':
        navigate(`${config.developerBaseUrl}/${orgId}`)
        break

      case 'integrations':
        navigate(`/integrations/${orgId}`)
        break

      case 'Logout':
        handleLogOut(navigate)
        break

      default:
        console.log(`Unhandled navigation case: ${to}`)
        break
    }
  }

  const ListArray = [
    [{ title: 'Switch Workspace', path: 'orgs' }],
    [
      { title: 'Workspace Settings', path: 'setting', selectedItem: 'setting' },
      { title: `Members (${usersList ? usersList.length : 0})`, path: 'members', selectedItem: 'invite' }
    ],
    [
      { heading: 'Developer Platform' },
      { title: 'API Doc', path: 'https://techdoc.viasocket.com' },
      { title: 'OAuth Connections', path: 'oauth', selectedItem: 'oauth' }
    ],
    [
      { heading: 'Do you own Saas Project?' },
      {
        title: 'Plugin Builder',
        subTitle: 'Create and customize plugins for extended functionality.',
        path: 'pgBuilder',
        selectedItem: config.developerBaseUrl
      },
      {
        title: 'Embed (Integrations)',
        subTitle: 'Connect and configure third-party services.',
        path: 'integrations',
        selectedItem: 'integrations'
      }
    ],
    [{ title: 'Log Out', path: 'Logout' }]
  ]

  const location = useLocation()

  return (
    <Box className={`flex flex-col ${open ? ' h-screen bg' : ''}`} sx={{ backgroundColor: open ? '#e0e0e0' : '' }}>
      <Box
        className='flex justify-start items-center gap-2 pl-3 py-3'
        onMouseEnter={() => setShowOnHover(true)}
        onMouseLeave={() => setShowOnHover(false)}
      >
        <Box>
          {showOnHover ? (
            <IconButton
              onClick={handleBackButton}
              sx={{
                width: '24px',
                height: '24px'
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconWrapper
              size='24px'
              iconUrl={orgIcon}
              component={
                orgIcon ? (
                  <img src={orgIcon} alt='icon' width='100%' />
                ) : (
                  <Avatar variant='square'>
                    <Typography fontWeight='600'>{orgObj?.name?.toUpperCase()?.substring(0, 2) || ''}</Typography>
                  </Avatar>
                )
              }
            />
          )}
        </Box>
        <Box className='flex items-center cursor-pointer ' maxWidth='calc(100% - 35px)'>
          <Typography fontWeight='600' className='ml-2 text-truncate !text-base'>
            {orgObj?.name || ''}
          </Typography>
          <ExpandMoreIcon
            expandAcoordion
            big
            isExpanded={open}
            selectedWorkspace
            sx={{
              fontWeight: 600,
              strokeWidth: 2,
              color: 'inherit'
            }}
          />
        </Box>
      </Box>
      {/* {!open && <Divider variant='sharp' className='mt-3' />} */}

      <Collapse in={open} timeout='auto' unmountOnExit>
        <Stack className='w-full'>
          {ListArray.map((group) => (
            <Box key={group}>
              <Box className='my-3'>
                {group.map((item) => (
                  <Box key={item} className={`${item?.heading && 'mb-2'}`}>
                    {item?.heading ? (
                      <Typography key={item} variant='h7' className='pl-3-5'>
                        {item.heading}
                      </Typography>
                    ) : (
                      <List key={item} disablePadding>
                        <ListItem
                          disablePadding
                          disableGutters
                          onClick={(e) => handleNavigations(e, item.path)}
                          className={` ${item?.path === 'orgs' ? 'color-link' : ''} w-full`}
                          sx={{
                            height: 'fit-content'
                          }}
                          {...(item.title === 'API Doc'
                            ? {
                                component: RouterLink,
                                to: item.path,
                                target: '_blank',
                                rel: 'noopener noreferrer',
                                sx: { color: 'inherit', margin: 0, padding: 0 }
                              }
                            : {})}
                        >
                          <ListItemButton
                            className='w-full p-1'
                            sx={{
                              backgroundColor: location.pathname.includes(item.selectedItem)
                                ? (theme) => theme.palette.action.hover // Material-UI hover color
                                : 'inherit'
                            }}
                          >
                            <Box className='pl-3 flex flex-col gap-0-5'>
                              <Typography
                                variant='body2'
                                className={`${location.pathname.includes(item.selectedItem) ? '!font-semibold' : ''}`}
                              >
                                {item.title}
                              </Typography>
                              {item.subTitle && (
                                <Typography variant='caption' className='opacity-60 line-height'>
                                  {item.subTitle}
                                </Typography>
                              )}
                            </Box>
                          </ListItemButton>
                        </ListItem>
                      </List>
                    )}
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Stack>
      </Collapse>
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(SelectedWorkspace), [ParamsEnums.orgId, ParamsEnums.projectId]))
