import WebhookIcon from '@mui/icons-material/Webhook'
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import IconWrapper from '../../../../IconWrapper/IconWrapper.tsx'

export default function ShowTriggerIcon({ triggerInfo, size = '28px' }) {
  switch (triggerInfo?.triggerType) {
    case 'webhook':
      return <WebhookIcon />
    case 'cron':
      return <AccessAlarmIcon />
    case 'email':
      return <AlternateEmailIcon />
    case 'hook':
    case 'polling':
    case 'trigger':
      return <IconWrapper iconUrl={triggerInfo?.iconUrl || 'https://cdn-icons-png.flaticon.com/512/380/380127.png'} size={size} />
    default:
      return <AddCircleIcon />
  }
}
