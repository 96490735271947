// import { current } from '@reduxjs/toolkit';
/* eslint-disable-next-line import/no-cycle */
import { removeUser, saveUser } from './userThunk'

export const initialState = {
  status: 'idle',
  userLogIn: false,
  userLogOut: true,
  userName: '',
  userEmail: '',
  userId: '',
  userProfilePic: '',
  createdAt: '',
  onboardingStatus: {
    script: true
  },
  currentCompany: {}
}

export const reducers = {
  add(state, payload) {
    if (payload.payload) {
      const { name, id, email, profile_pic } = payload.payload
      state.userName = name
      state.userId = id
      state.userEmail = email
      state.userProfilePic = profile_pic
      state.userLogIn = true
      state.userLogOut = false
    }
  },
  remove(state) {
    state.userName = ''
    state.userId = ''
    state.userEmail = ''
    state.userProfilePic = ''
    state.userLogIn = false
    state.userLogOut = true
  },
  addUserId(state, payload) {
    state.id = payload.payload.id
  },

  updateOnboardingStatus(state, payload) {
    state.onboardingStatus = { ...state.onboardingStatus, ...payload.payload }
  }
}

export function extraReducers(builder) {
  builder
    //   // save User
    .addCase(saveUser.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveUser.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const { name, id, email, profile_pic, created_at, currentCompany, meta } = payload.payload
        state.userName = name
        state.userId = id
        state.userEmail = email
        state.userProfilePic = profile_pic
        state.userLogIn = true
        state.userLogOut = false
        state.createdAt = created_at
        state.currentCompany = currentCompany
        state.meta = meta
      }
    })
    .addCase(saveUser.rejected, (state) => {
      state.status = 'failed'

      // MDBToast.error("Unable to fetch jamaats.");
    })

    //   remove User

    .addCase(removeUser.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(removeUser.fulfilled, (state) => {
      state.status = 'succeeded'
      state.userName = ''
      state.userId = ''
      state.userEmail = ''
      state.userProfilePic = ''
      state.userLogIn = false
      state.userLogOut = true
    })
    .addCase(removeUser.rejected, (state) => {
      state.status = 'failed'
      // MDBToast.error("Unable to fetch jamaats.");
    })
}
