import { useRef } from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, TextField } from '@mui/material'
import '../../App.css'

/**
 * SearchBar component for searching functionality.
 *
 * @param {Function} handleSearchBar - Function to handle search bar input change.
 * @param {boolean} darkbg - Flag to determine if the search bar has a dark background.
 * @param {string} height - Height of the search bar.
 * @param {boolean} autoFocus - Flag to determine if the search bar should be autofocused.
 * @param {Object} parentRef - Reference to the parent element.
 * @param {string} placeholder - Placeholder text for the search bar.
 * @param {string} value - Value of the search bar input.
 * @param {Function} onFocus - Function to handle search bar focus event.
 * @param {Function} onKeyDown - Function to handle key down event on the search bar.
 */

function SearchBar({
  handleSearchBar = () => {},
  autoFocus = true,
  parentRef = { current: null },
  placeholder = 'Search…',
  value = undefined,
  disabled = false,
  onFocus = () => {},
  onKeyDown = () => {},
  customStyles = {},
  ...props
}) {
  const searchbarRef = useRef()
  const sentValueFromSearchbar = (e) => {
    handleSearchBar(e.target.value)
  }

  return (
    <TextField
      {...props?.styles}
      className='bg-white'
      defaultValue=''
      disabled={disabled}
      inputRef={searchbarRef}
      fullWidth
      onChange={sentValueFromSearchbar}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <SearchIcon color='gray' fontSize='medium' />
          </InputAdornment>
        )
      }}
      sx={customStyles}
      autoFocus={autoFocus}
      onKeyDown={(e) => {
        e.stopPropagation()
        onKeyDown(e)
        if (e.key === 'Enter') {
          handleSearchBar(e.target.value)
        }
      }}
      onBlur={() => {
        parentRef?.current?.classList?.remove('large-width')
        parentRef?.current?.classList?.add('normal-width')
      }}
      onFocus={() => {
        parentRef?.current?.classList?.add('large-width')
        parentRef?.current?.classList?.remove('normal-width')
        onFocus()
      }}
      id='search'
      placeholder={placeholder}
      value={value}
    />
  )
}

export default SearchBar
