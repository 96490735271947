import React from 'react'
import { Box, Typography } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { MiscTypes, ParamsEnums } from '../../enums'
import { useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import IconWrapper from '../IconWrapper/IconWrapper.tsx'
import { SkeletonForListOfPlugs } from '../../pages/Metrics/SkeletonLoader.tsx'

function AppsCategoryWise({
  orgId,
  projectId,
  triggerServiceIdToSkip,
  category = '',
  onClickOnElement = () => {},
  type = 'action'
}: {
  orgId: string
  projectId: string
  category: string
  triggerServiceIdToSkip?: string
  onClickOnElement?: (plug: any) => void
  type: string
}): JSX.Element {
  const { filteredPlugins } = useCustomSelector((state) => {
    const filteredServices =
      (state.appInfo.mode === MiscTypes.EMBED_MODE
        ? state.projects.embedProject[orgId]?.['active']?.[projectId]?.settings?.config?.filteredServices
        : state.appInfo.localConfiguration?.filteredServices) || {}
    return {
      filteredPlugins: Object.keys(filteredServices) || []
    }
  })

  const { data: plugins, isLoading: loading } = useFetchPlugins(orgId, [category], filteredPlugins)
  const pluginsArray = loading ? [] : Object.values(plugins || {})?.filter((plug) => plug.rowid !== triggerServiceIdToSkip)
  const pluginsArrayLength = pluginsArray?.length

  return (
    <Box className='flex flex-col w-full'>
      <Typography className='p-2' variant='button' sx={{ color: 'text.secondary' }}>
        {category}
      </Typography>

      {loading
        ? SkeletonForListOfPlugs
        : pluginsArray?.map((plug, index) => {
            return (
              <Box
                sx={{
                  borderBottom: pluginsArrayLength - 1 !== index ? '0.2px solid #ccc' : 'unset',
                  opacity: type === 'action' || (plug?.istriggeravailable && plug?.triggercount !== '0') ? 1 : 0.8
                }}
                key={plug.rowid}
                onClick={() => onClickOnElement(plug)}
                className={`px-3 py-2 ${
                  type === 'action' || (plug?.istriggeravailable && plug?.triggercount !== '0') ? 'cursor-pointer hoverable-block' : ''
                } w-full gap-2 flex justify-between items-center`}
              >
                <Box className='flex justify-start items-center gap-3'>
                  <IconWrapper iconUrl={plug.iconurl} size='36px' />
                  <Box className='flex flex-col'>
                    <Typography variant='h7'>{plug?.name}</Typography>
                    <Typography behaviour='colored' color='gray' className='one-line-trim'>
                      {type === 'trigger' && (!plug?.istriggeravailable || plug?.triggercount === '0')
                        ? 'No trigger available.'
                        : plug?.category?.join(', ')}
                    </Typography>
                  </Box>
                </Box>
                <ArrowForwardIosIcon className='opacity-0' fontSize='small' />
              </Box>
            )
          })}
    </Box>
  )
}
export default React.memo(addUrlDataHoc(React.memo(AppsCategoryWise), [ParamsEnums.projectId, ParamsEnums.orgId]))
