import SecurityIcon from '@mui/icons-material/Security'
import { InputFieldsType } from '../../../types/Plugin.ts'
/* eslint-disable no-template-curly-in-string */

export const categories = [
  'Accounting',
  'Ads and Conversion',
  'AI Tools',
  'Analytics',
  'App Builder',
  'App Families',
  'Bookmark Managers',
  'Business Intelligence',
  'Calendar',
  'Call Tracking',
  'Commerce',
  'Communication',
  'Contact Management',
  'Content and Files',
  'CRM',
  'Customer Appreciation',
  'Customer Support',
  'Dashboards',
  'Databases',
  'Developer Tools',
  'Devices',
  'Documents',
  'Drip Emails',
  'eCommerce',
  'Education',
  'Email',
  'Email Newsletters',
  'Engineering',
  'Event Management',
  'Fax',
  'File Management and Storage',
  'Filters',
  'Fitness',
  'Forms and Surveys',
  'Fundraising',
  'Gaming',
  'HR Talent and Recruitment',
  'Human Resources',
  'Images and Design',
  'Internet of Things',
  'Proposal and Invoice Management',
  'IT Operations',
  'Online Courses',
  'Lifestyle and Entertainment',
  'Marketing',
  'Marketing Automation',
  'News and Lifestyle',
  'Notes',
  'Notifications',
  'Payment Processing',
  'Phone and SMS',
  'Printing',
  'Product Management',
  'Productivity',
  'Project Management',
  'Reviews',
  'Sales and CRM',
  'Scheduling and Booking',
  'Security and Identity Tools',
  'Server Monitoring',
  'Signatures',
  'Social Media Accounts',
  'Social Media Marketing',
  'Spreadsheets',
  'Support',
  'Taxes',
  'Team Chat',
  'Team Collaboration',
  'Time Tracking Software',
  'Task Management',
  'Transactional Email',
  'Transcription',
  'Top Free Apps',
  'URL Shortener',
  'Video and Audio',
  'Video Conferencing',
  'Webinars',
  'Website Builders',
  'Website and App Building'
]
export const JSON_PLUGIN_DETAILS = [
  {
    key: 'name',
    type: 'input',
    label: 'App Name*',
    placeholder: 'Provide your APP name',
    required: true,
    value: ''
  },
  {
    key: 'description',
    type: 'input',
    label: 'APP Description*',
    placeholder: 'Add some app details',
    required: true,
    value: '',
    multiline: true,
    minRows: 8
  },
  {
    key: 'domain',
    type: 'input',
    label: 'Domain*',
    placeholder: 'Ex. viasocket.com',
    required: true,
    value: ''
  },
  {
    key: 'audience',
    type: 'dropdown',
    label: 'Audience*',
    required: true,
    value: 'public',
    placeholder: 'select Audience',
    fields: ['Private', 'Public']
  },
  {
    key: 'category',
    type: 'multipleSelect',
    label: 'Category*',
    required: true,
    value: 'support',
    placeholder: 'select Category',
    fields: categories
  },
  {
    key: 'whitelistdomains',
    type: 'multipleselect',
    label: 'White listed urls*',
    required: true,
    textFieldPlaceHolder: 'Enter url to whitelist',
    infoText: 'We will allow api endpoints for action/triggers with this domains only'
  },
  {
    key: 'tags',
    type: 'multipleselect',
    label: 'Tags/Keywords',
    required: false
  },
  {
    key: 'iconurl',
    type: 'input',
    label: 'Icon Url',
    required: false
  },
  {
    key: 'brandcolor',
    type: 'input',
    label: "App's Primary Color",
    required: false,
    defaultValue: '#FFFFFF',
    toolTipText: 'Provide primary color of website'
  },

  {
    key: 'havestaticip',
    type: 'checkbox',
    label: 'Requires fixed IP ',
    required: false,
    value: '',
    defaultValue: false,
    toolTipText:
      'Check this box if your application requires a dedicated IP for making API calls.  If selected, you will be provided with the necessary IP details for whitelisting purposes.'
  }
]

const ActionTypes = {
  DROPDOWN: 'Add Dropdown',
  SINGLE: 'Add a Single Input Field',
  GROUP: 'Add Group Input Field',
  DYNAMICJSON: 'Add Dynamic JSON Generator',
  JSONCODEBLOCK: 'Add Javascript Code Block'
}
Object.freeze(ActionTypes)
export default ActionTypes

export const CLIENT_AUTH_FORM = (authtype, granttype) => {
  if (authtype === DH_AUTH_TYPES.auth1) {
    return [
      {
        key: 'clientid',
        type: 'input',
        label: 'Consumer Key',
        placeholder: 'Provide your Consumer Key',
        required: true,
        value: ''
      },
      {
        key: 'clientsecret',
        type: 'input',
        label: 'Consumer Secret',
        placeholder: 'Provide your Consumer Secret',
        required: true,
        value: ''
      }
    ]
  }
  return granttype === GRANT_TYPE.implicit
    ? [
        {
          key: 'clientid',
          type: 'input',
          label: 'Client Id',
          placeholder: 'Provide your client id',
          required: true,
          value: ''
        }
      ]
    : [
        {
          key: 'clientid',
          type: 'input',
          label: 'Client Id',
          placeholder: 'Provide your client id',
          required: true,
          value: ''
        },
        {
          key: 'clientsecret',
          type: 'input',
          label: 'Client Secret',
          placeholder: 'Provide your client secret',
          required: true,
          value: ''
        }
      ]
}

export const PLUGIN_SETUP_STEPS = [
  { label: 'Plugin Details', value: 'pluginDetails' },
  { label: 'Authentication', value: 'auth' },
  { label: 'Action', value: 'action' },
  { label: 'Triggers', value: 'trigger' }
]

export const tableName = {
  plugins: 'plugins',
  oauthdetails: 'oauth_details',
  actions: 'actions',
  actionVersion: 'action_version'
}

export const ActionVersionStatusEnum = {
  drafted: 'drafted',
  published: 'published',
  unpublished: 'unpublished'
}

export const BASIC_AUTH_FIELDS = {
  authentication: {
    type: 'basic',
    fields: [{ key: 'auth_key', label: 'auth key', help: '', type: 'string', required: true }]
  }
}
export const AUTH1_AUTH_FIELDS = {
  authentication: {
    type: 'Auth1.0',
    fields: []
  }
}
export const AUTH2_AUTH_FIELDS = {
  authentication: {
    type: 'Auth2.0',
    fields: null
  }
}

export const AUTH2_CLIENT_CREDENTIALS_AUTH_FIELDS = {
  authentication: {
    type: 'Auth2.0',
    fields: [
      {
        key: 'clientid',
        label: 'Client ID',
        type: 'string',
        required: true,
        help: 'Enter client ID here.'
      },
      {
        key: 'clientsecret',
        label: 'Client Secret',
        type: 'string',
        required: true,
        help: 'Enter client secret here.'
      }
    ]
  }
}

export const AUTH2_PASSWORD_CREDENTIALS_AUTH_FIELDS = {
  authentication: {
    type: 'Auth2.0',
    fields: [
      {
        key: 'username',
        label: 'Username',
        type: 'string',
        required: true,
        help: ''
      },
      {
        key: 'password',
        label: 'Password',
        type: 'password',
        required: true,
        help: ''
      }
    ]
  }
}

export const DUMMY_ACTION_JSON = [
  {
    key: 'username',
    label: 'username',
    type: 'string',
    required: true
  },
  {
    key: 'email',
    label: 'email',
    type: 'string',
    required: true
  },
  {
    key: 'contact',
    label: 'contact',
    type: 'dropdown',
    required: true
  }
]

export const SAMPLE_CODE: { [key: string]: any } = {
  // Basic: {
  //   perform:
  //     "try{ \n    // this api runs with data entered by user in plugin fields  \nconst Perform_Api_Url = 'Enter your Perform_Api_Url here'    \n// for example if your auth ask auth_token from user then path will be    \n// context?.authData?.auth_token   \nconst token = context?.authData?.['your auth field key name']  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_Api_Url , data_to_send,{  \n        headers: {\n       'Content-Type':'application/json',  \n        Authorization : token  \n    }\n       }  \n)  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   performlist:
  //     "try{ \n    const Perform_List_Api_Url = 'Enter your Perform_List_Api_Url here'  \n// if your auth takes auth_token from user then path will be  \n // context?.authData?.auth_token  \nconst token = context?.authData?.['your auth field key name']  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_List_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : token  \n    }  \n})  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   performsubscribe:
  //     "try{ \n    const Perform_Subscribe_Api_Url = 'Enter your Perform_Subscribe_Api_Url here'  \n// if your auth takes auth_token from user then path will be  \n // context?.authData?.auth_token  \nconst token = context?.authData?.['your auth field key name']  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_Subscribe_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : token  \n    }  \n})  \nreturn response?.data  \n \n}catch(e){ \n    throw new Error(e) \n} \n",
  //   performunsubscribe:
  //     "try{ \n    const Perform_UnSubscribe_Api_Url = 'Enter your Perform_UnSubscribe_Api_Url here'  \n// if your auth takes auth_token from user then path will be  \n // context?.authData?.auth_token  \nconst token = context?.authData?.['your auth field key name']  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here  \n     // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_UnSubscribe_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : token  \n    }  \n})  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   testcode:
  //     "try { \n    // TEST api is generally a get api which is used to test your token  \n// response of this api is used create a connection Label  \nconst Test_Api_Url = 'your test api url'  \nconst token = context.authData.['your token field key']  \n const response = await axios.get(Test_Api_Url, {  \n    headers: {  \n      authorization: accessToken,  \n      'Content-Type':'application/json'  \n    }  \n  });  \n// return success key true or false  \n// based on this key we will verify token entered by User  \n// if your api gives 200 or 201 code even when token is wrong or inValid  \n// then you have to handle condition below on the bases of status message or text  \n  if (response?.status >= 200 && response?.status < 400) {  \n    return {success: true , data : response?.data}  \n}else{  \n    throw new Error(response?.data)  \n} \n \n} catch (e) { \n    throw new Error(e) \n} \n",
  //   transferoption:
  //     "\ntry{\n    let url = 'https://example.com/getAll';              // list all api url\n\nif(context?.inputData?.transferOption?.offset) {\n    url += `?offset=${context?.inputData?.transferOption?.offset}`;      // adding offset parameter to url, if you have required some other parameters too than store it\n}\n\nconst response = await axios.get(url, {\n    headers: { Authorization: `Bearer ${context?.authData?.auth_key}` }\n});\nreturn {\n    data: response?.data?.records,                          // records should be an array of json, the format of every json should be same as the format of the value passed through the flow to hit trigger.\n    offset: response?.data?.offset                  // extra parameter for next run.\n};\n\n}catch(error){\n    console.log(error);\n    return {};\n}",
  //   modifytriggerdata:
  //     "const Modify_Trigger_Data_Api_Url = 'Enter your Modify_Trigger_Data_Api_Url here' \n const access_token = context?.authData?.accesstokencode?.access_token \nconst data_to_send = { \n    // you can send data entered by user in plugin fields here  \n    // data entered is access able at context?.inputData?.['field key'] \n    // field key is key entered by you when creating inputJson  \n} \nconst response = await axios.post(Manipulate_Trigger_Data_Api_Url , data_to_send,{ \n    headers:{ \n        'Content-Type':'application/json', \n        Authorization : access_token \n    } \n}) \nreturn response?.data \n"
  // },
  // 'Auth2.0': {
  //   perform:
  //     "try{ \n    // this api runs with data entered by user in plugin fields   \nconst Perform_Api_Url = 'Enter your Perform_Api_Url here'    \nconst access_token = context?.authData?.accesstokencode?.access_token   \nconst data_to_send = {   \n    // you can send data entered by user in plugin fields here    \n    // data entered is access able at context?.inputData?.['field key']   \n    // field key is key entered by you when creating inputJson    \n}   \nconst response = await axios.post(Perform_Api_Url , data_to_send,{   \n        headers: { \n       'Content-Type':'application/json',   \n        Authorization : `Bearer ${access_token}` \n       }   \n    }   \n)   \nreturn response?.data   \n \n}catch(e){ \n        throw new Error(e) \n} \n",
  //   performlist:
  //     "try{ \n    const Perform_List_Api_Url = 'Enter your Perform_List_Api_Url here'  \n const access_token = context?.authData?.accesstokencode?.access_token  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_List_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : `Bearer ${access_token}`  \n    }  \n})  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   performsubscribe:
  //     "try{ \n    const Perform_Subscribe_Api_Url = 'Enter your Perform_Subscribe_Api_Url here'  \n const access_token = context?.authData?.accesstokencode?.access_token  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_Subscribe_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : `Bearer ${access_token}` \n    }  \n})  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   performunsubscribe:
  //     "try{ \n    const Perform_UnSubscribe_Api_Url = 'Enter your Perform_UnSubscribe_Api_Url here'  \nconst access_token = context?.authData?.accesstokencode?.access_token  \nconst data_to_send = {  \n    // you can send data entered by user in plugin fields here   \n    // data entered is access able at context?.inputData?.['field key']  \n    // field key is key entered by you when creating inputJson   \n}  \nconst response = await axios.post(Perform_UnSubscribe_Api_Url , data_to_send,{  \n    headers:{  \n        'Content-Type':'application/json',  \n        Authorization : `Bearer ${access_token}` \n    }  \n})  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   testcode:
  //     "try{  \n    // TEST api is generally a get api which is used to test your token \n  // response of this api is used create a connection Label   \nconst Test_Api_Url = 'your test api url'   \nconst accessToken = context.authData.accesstokencode.access_token   \n const response = await axios.get(Test_Api_Url, {   \n    headers: {   \n      authorization: `Bearer ${accessToken}`,   \n      'Content-Type':'application/json'   \n    }   \n  });   \n// return success key true or false   \n// based on this key we will verify token entered by User   \n// if your api gives 200 or 201 code even when token is wrong or inValid   \n// then you have to handle condition below on the bases of status message or text   \n  if (response?.status >= 200 && response?.status < 400) {   \n    return {success: true , data : response?.data}   \n}else{   \n    throw new Error(response?.data) \n}  \n  \n}catch(e){  \n    throw new Error(e)  \n}  \n",
  //   revokeapicode:
  //     "try{ \n    // your data is available in context you can access from there or copy path  \n// eg. context?.authData?.clientid  \nconst Revoke_Token_Api_Url = 'your revoke token api url';  \nconst data = {  \n    clientId : context.authData.clientid,  \n     clientSecret : context.authData.clientsecret,  \n     access_token : context.authData.accesstokencode.access_token  \n     // add your additional fields if they exist  \n};  \n \nconst response = await axios.post(Revoke_Token_Api_Url,data)  \n return response?.data  \n}catch(e){ \n throw new Error(e)} \n \n",
  //   accesstokencode:
  //     "try{ \n    // your data is available in context you can access from there or copy path  \n// eg. context?.authData?.clientid  \nconst Access_Token_Api_Url = 'your access token api url';  \nconst data = {  \n    clientId : context?.authData?.clientid,  \n    clientSecret : context?.authData?.clientsecret,  \n    code : context?.authData?.Authorization?.code , \n    grant_type:`authorization_code`, \n    redirect_uri:`${context.authData.redirecturl}` \n    // add your additional fields if they exist   \n}; \n \nconst headers = {'Content-Type':`application/x-www-form-urlencoded`} \n \nconst response = await axios.post(Access_Token_Api_Url,data,{headers})  \nreturn response?.data  \n \n}catch(e){ \nthrow new Error(e) \n}  \n",
  //   refreshtokencode:
  //     "try{ \n    // your data is available in context you can access from there or copy path  \n// eg. context?.authData?.clientid  \nconst Refresh_Token_Api_Url = 'your refresh token api url';  \nconst data = {  \n    clientId : context?.authData?.clientid,  \n    clientSecret : context?.authData?.clientsecret,  \n    accessToken : context?.authData?.accesstokencode?.access_token  //your expired accessToken  \n    // add your additional fields if they exist  \n};  \nconst response = await axios.post(Refresh_Token_Api_Url,data)  \nreturn response?.data  \n}catch(e){ \n    throw new Error(e) \n} \n \n",
  //   transferoption:
  //     "\ntry{\n    let url = 'https://example.com/getAll';              // list all api url\n\nif(context?.inputData?.transferOption?.offset) {\n    url += `?offset=${context?.inputData?.transferOption?.offset}`;      // adding offset parameter to url, if you have required some other parameters too than store it\n}\n\nconst response = await axios.get(url, {\n    headers: { Authorization: `Bearer ${context?.authData?.auth_key}` }\n});\nreturn {\n    data: response?.data?.records,                          // records should be an array of json, the format of every json should be same as the format of the value passed through the flow to hit trigger.\n    offset: response?.data?.offset                  // extra parameter for next run.\n};\n\n}catch(error){\n    console.log(error);\n    return {};\n}",
  //   modifytriggerdata:
  //     "const Modify_Trigger_Data_Api_Url = 'Enter your Modify_Trigger_Data_Api_Url here' \n const access_token = context?.authData?.accesstokencode?.access_token \nconst data_to_send = { \n    // you can send data entered by user in plugin fields here  \n    // data entered is access able at context?.inputData?.['field key'] \n    // field key is key entered by you when creating inputJson  \n} \nconst response = await axios.post(Manipulate_Trigger_Data_Api_Url , data_to_send,{ \n    headers:{ \n        'Content-Type':'application/json', \n        Authorization : access_token \n    } \n}) \nreturn response?.data \n"
  // },
  Basic: {
    perform: 'Enter JS code to perform an API call',
    performlist: 'Enter JS code to perform an API call to list sample data',
    performsubscribe: 'Enter JS code to perform an API call to Subscribe',
    performunsubscribe: 'Enter JS code to perform an API call to Unsubscribe',
    testcode: 'Enter JS code to test the Authentication',
    transferoption: 'Enter JS code to handle transfer options',
    modifytriggerdata: 'Enter JS code to modify the trigger data'
  },
  'Auth2.0': {
    perform: 'Enter JS code to perform an API call',
    performlist: 'Enter JS code to perform an API call to list sample data',
    performsubscribe: 'Enter JS code to perform an API call to Subscribe',
    performunsubscribe: 'Enter JS code to perform an API call to Unsubscribe',
    testcode: 'Enter JS code to test the Authentication',
    revokeapicode: 'Enter JS code to revoke a Access Token',
    accesstokencode: 'Enter JS code to retrieve Access Token',
    refreshtokencode: 'Enter JS code to refresh a token and obtain a new Access Token',
    transferoption: 'Enter JS code to handle transfer options',
    modifytriggerdata: 'Enter JS code to modify the data'
  },

  auth_dropdown:
    'return [\n    {\n        label: "label1",\n        value:"value1",\n        sample: "sample1"\n    },\n    {\n        label: "label2",\n        value:"value2",\n        sample: "sample2"\n    },\n    {\n        label: "label3",\n        value:"value3",\n        sample: "sample3"\n    }\n    ]'
}

export const OBJECTS_TO_PARSE_DATA = {
  authKeysToParse: ['testcode', 'refreshtokencode', 'accesstokencode', 'queryparams', 'revokeapicode'],
  actionKeys: ['perform', 'performsubscribe', 'performlist', 'performunsubscribe', 'transferoption', 'modifytriggerdata']
}

export const KEYS_TO_GET_USEDVARIABLES = {
  authKeys: ['testcode', 'refreshtokencode', 'accesstokencode', 'revokeapicode']
}

export const OBJECTS_TO_PARSE = Object.freeze(OBJECTS_TO_PARSE_DATA)
export const SECTIONS = {
  auth: 'auth',
  pluginDashboard: 'pluginDashboard',
  pluginDetails: 'pluginDetails',
  action: 'action',
  trigger: 'trigger',
  customAction: 'customAction',
  transfer: 'transfer',
  analytics: 'analytics'
}

export const ACTION_TYPE = {
  action: 'action',
  trigger: 'trigger',
  customAction: 'custom_action'
}

export const DH_SLIDERS = ['performsubscribe', 'performlist', 'performunsubscribe', 'perform', 'transferoption', 'modifytriggerdata']
export const DH_AUTH_SLIDERS = ['accesstokencode', 'refreshtokencode', 'testcode', 'revokeapicode']
export const DH_AUTH_TYPES = { basic: 'Basic', auth2: 'Auth2.0', noAuth: 'NoAuth', auth1: 'Auth1' }
export const INPUT_FIELDS_SUGGESTIONS = [
  {
    display: 'dropdown',
    id: '{\n  "key": "enter_key_here",\n  "type": "dropdown",\n  "label": "label here",\n  "help": "help text here",\n  "required": true,\n  "options": [\n      {\n      "label": "label here1",\n      "sample": "sample text here1",\n      "value": "value here1"\n      },\n      {\n      "label": "label here2",\n      "sample": "sample text here2",\n      "value": "value here2"\n      }\n  ]\n}',
    value: 'static'
  },
  {
    value: 'date',
    display: 'date',
    id: '{ \n    "key": "enter_key_for_date_field", \n    "type": "date", \n    "label": "Enter Date", \n    "placeholder": "YYYY-MM-DD", \n    "help": "help text here", \n    "required": true \n} \n'
  },
  {
    display: 'html',
    value: 'html',
    id: '{\n  "key": "enter_key_here",\n  "type": "html",\n  "label": "label here",\n  "help": "help text here",\n  "required": true\n}'
  },
  {
    display: 'markdown',
    value: 'markdown',
    id: '{\n  "key": "enter_key_here",\n  "type": "markdown",\n  "label": "label here",\n  "help": "help text here",\n  "required": true\n}'
  },
  {
    display: 'number',
    value: 'number',
    id: '{\n  "key": "enter_key_here",\n  "type": "number",\n  "label": "label here",\n  "help": "help text here",\n  "required": true\n}'
  },
  {
    display: 'dropdown',
    id: '{\n  "key": "enter_key_here",\n  "type": "dropdown",\n  "label": "label here",\n  "help": "help text here",\n  "required": true,\n  "optionsGenerator": "optionsGenerator code here"\n}',
    value: 'dynamic'
  },
  {
    display: 'string',
    value: 'string',
    id: '{\n  "key": "enter_key_here",\n  "type": "string",\n  "label": "label here",\n  "placeholder": "Enter Text",\n  "help": "help text here",\n  "required": true\n}'
  },
  {
    display: 'Input Groups',
    id: '{\n   "key": "enter_key_here",\n   "type": "input groups",\n   "label": "label here",\n  "help": "help text here",\n "fields": [\n      {\n      "key": "enter_key_here1",\n      "label": "label here1",\n      "placeholder": "placeholder here1",\n      "type": "string",\n      "help": "help text here",\n      "required": true\n    },\n      {\n      "key": "enter_key_here2",\n      "type": "dropdown",\n      "label": "label here",\n      "placeholder": "placeholder here1",\n      "help": "help text here",\n      "required": true,\n      "options": []\n    }\n  ]\n}',
    value: 'static'
  },
  {
    display: 'Input Groups',
    id: '{\n  "key": "enter_key_here",\n  "type": "input groups",\n  "label": "label here",\n  "help": "help text here",\n  "fieldsGenerator": "fields Generator code here"\n}',
    value: 'dynamic'
  },
  {
    display: 'dictionary',
    id: '{\n  "key": "enter_key_here",\n  "type": "dictionary",\n  "label": "label here",\n  "help": "help text here",\n "template": { \n      "key": { \n        "type": "string", \n          "placeholder": "Enter key" \n      }, \n      "value": { \n        "type": "string", \n        "placeholder": "Enter value" \n      } \n    } \n\n}',
    value: 'json'
  },
  {
    display: 'Multi Select',
    id: '{ \n    "key": "username", \n    "type": "multiselect", \n    "label": "username", \n    "options": [ \n      { \n        "label": "A", \n        "value": "A", \n        "sample": "A" \n      }, \n      { \n        "label": "B", \n        "value": "B", \n        "sample": "B" \n      }, \n      { \n        "label": "C", \n        "value": "C", \n        "sample": "C" \n      } \n    ], \n    "required": true \n  } \n',
    value: 'Multi Select'
  },
  {
    display: 'boolean',
    id: '{\n  "key": "enter_key_here",\n  "type": "boolean",\n  "label": "label here",\n  "help": "help text here",\n  "required": true,\n  "options": [\n      {\n      "label": "Yes",\n   "value": true \n      },\n      {\n      "label": "No",\n   "value": false \n      }\n  ]\n}',
    value: 'boolean'
  },
  {
    display: 'aifield',
    id: '{ \n    "key": "XfDxOeon", \n    "type": "aifield", \n    "label": "Sample Label", \n    "help": "Enter help text", \n    "required": true, \n    "prompt": "Write a prompt to guide the AI.", \n    "suggestionGenerator": " // Enter JS code response of which will be sent to AI later used by ai to generate results" \n  } \n',
    value: 'aifield'
  },
  {
    display: 'Attachment',
    value: 'Attachment',
    id: '{\n  "key": "enter_key_here",\n  "type": "attachment",\n  "label": "Sample Label",\n  "help": "help text here",\n  "required": true\n}'
  }
]

export const FieldsMenu: { label: string; sampleObj: InputFieldsType }[] = deepFreeze([
  {
    label: 'Dropdown',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      help: 'Sample Help Text',
      type: 'dropdown',
      required: true,
      children: [
        {
          sample: 'first',
          value: 'first',
          label: 'first'
        },
        {
          sample: 'second',
          value: 'second',
          label: 'second'
        },
        {
          sample: 'third',
          value: 'third',
          label: 'third'
        }
      ]
    }
  },
  {
    label: 'Dropdown with Dynamic options',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      help: 'Sample Help Text',
      type: 'dropdown',
      required: true,
      source: 'return [{sample:"test",label:"sample Children",value:"test"}]'
    }
  },
  {
    label: 'Input group',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      help: 'Sample Help Text',
      type: 'input groups',
      children: [
        {
          key: 'first',
          help: 'sample help text',
          label: 'first',
          type: 'string',
          placeholder: 'placeholder',
          required: true
        },
        {
          key: 'second',
          help: 'sample help text',
          label: 'second',
          type: 'string',
          placeholder: 'placeholder',
          required: true
        },
        {
          key: 'third',
          help: 'sample help text',
          label: 'third',
          type: 'string',
          placeholder: 'placeholder',
          required: true
        }
      ]
    }
  },
  {
    label: 'Input group with Dynamic fields',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      help: 'Sample Help Text',
      type: 'input groups',
      source: "return [{key:'test',label:'sample Children',type:'string',required:true}]"
    }
  },
  {
    label: 'Multi Select',
    sampleObj: {
      key: 'username',
      type: 'multiselect',
      label: 'Sample Label',
      help: 'Sample Help Text',
      children: [
        {
          label: 'A',
          value: 'A',
          sample: 'A'
        },
        {
          label: 'B',
          value: 'B',
          sample: 'B'
        },
        {
          label: 'C',
          value: 'C',
          sample: 'C'
        }
      ],
      required: true
    }
  },
  {
    label: 'Multi Select with Dynamic options',
    sampleObj: {
      key: 'username',
      type: 'multiselect',
      label: 'Sample Label',
      help: 'Sample Help Text',
      source: 'return [{sample:"A",label:"A",value:"A"},{sample:"B",label:"B",value:"B"},{sample:"C",label:"C",value:"C"}]',
      required: true
    }
  },
  {
    label: 'Boolean',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      help: 'Sample Help Text',
      type: 'boolean',
      required: true,
      children: [
        {
          value: true,
          label: 'Yes'
        },
        {
          value: false,
          label: 'No'
        }
      ]
    }
  },
  {
    label: 'Text Input',
    sampleObj: {
      key: 'sampleKey',
      label: 'Sample Label',
      placeholder: 'placeholder',
      help: 'Sample Help Text',
      type: 'string',
      required: true
    }
  },
  {
    label: 'HTML',
    sampleObj: {
      key: 'enter_key_here',
      type: 'html',
      label: 'Sample Label',
      help: 'Sample Help Text',
      required: true
    }
  },
  {
    label: 'Markdown',
    sampleObj: {
      key: 'enter_key_here',
      type: 'markdown',
      label: 'Sample Labels',
      help: 'Sample Help Text',
      required: true
    }
  },
  {
    label: 'Dicitionary',
    sampleObj: {
      key: 'enter_key_here',
      type: 'dictionary',
      label: 'Sample Label',
      help: 'Sample Help Text',
      template: {
        key: {
          type: 'string',
          placeholder: 'Enter key'
        },
        value: {
          type: 'string',
          placeholder: 'Enter value'
        }
      }
    }
  },
  {
    label: 'AI Field',
    sampleObj: {
      key: 'aiField',
      type: 'aifield',
      label: 'Sample Label',
      help: 'Enter help text',
      required: true,
      prompt: 'Write a prompt to guide the AI.',
      suggestionGenerator: ' // Enter JS code response of which will be sent to AI later used by ai to generate results'
    }
  },
  {
    label: 'Date',
    sampleObj: {
      key: 'date_key',
      type: 'date',
      label: 'Sample Date Label',
      help: 'Enter help text',
      required: true
    }
  },
  {
    label: 'Number',
    sampleObj: {
      key: 'number_key',
      type: 'number',
      label: 'Sample Number Label',
      help: 'Enter help text',
      required: true
    }
  },
  {
    label: 'Help',
    sampleObj: {
      key: 'sample_key',
      type: 'help',
      help: 'Enter help text'
    }
  },
  {
    label: 'Attachment',
    sampleObj: {
      key: 'attachment',
      type: 'attachment',
      label: 'Sample Label',
      help: 'Enter help text',
      required: true
    }
  }

  // {
  //   label: 'Show fields Based on Selected option',
  //   sampleObj: {
  //     key: 'hidenFields_sample_key',
  //     type: 'hidefields',
  //     label: 'Sample Label',
  //     children: [
  //       {
  //         label: 'A',
  //         value: 'A',
  //         sample: 'A'
  //       },
  //       {
  //         label: 'B',
  //         value: 'B',
  //         sample: 'B'
  //       },
  //       {
  //         label: 'C',
  //         value: 'C',
  //         sample: 'C'
  //       }
  //     ],
  //     fieldsToHide: {
  //       A: ['image', 'attachment'],
  //       B: ['attachment', 'file'],
  //       C: ['image', 'file']
  //     }
  //   }
  // }
])

export const GRANT_TYPE = {
  authorizationCode: 'Authorization Code',
  implicit: 'Implicit',
  clientCredentials: 'Client Credentials',
  passwordCredentials: 'Password Credentials'
}

export const STATUS_TYPE = {
  published: 'published',
  unpublished: 'unpublished',
  deleted: 'deleted',
  integration_only: 'integration_only'
}

export const DEFAULT_CATEGORIES = ['CREATE', 'GET', 'UPDATE', 'DELETE']

export const DH_FLOW_FILTERS = {
  GET_PLUGIN_DETAIL_START: 'getPluginDetails',
  GET_ALL_PLUGIN_START: 'getAllPlugins',
  UPDATE_PLUGIN_DETAILS_START: 'updatePluginDetails',
  GET_AUTH_DETAIL_START: 'getAuthDetails',
  UPDATE_AUTH_DETAIL_START: 'updateAuthDetails',
  GET_ALL_ACTION_START: 'getAllActions',
  GET_ACTION_DETAIL_START: 'getActionDetails',
  UPDATE_ACTION_DETAILS_START: 'updateActionDetails',
  DELETE_ACTION_START: 'deleteAction',
  GET_ACTION_VERSION_START: 'getActionVersions',
  UPDATE_ACTION_VERSION_DETAILS_START: 'updateActionVersionDetails',
  DELETE_ACTION_VERSION_START: 'deleteActionVersion',
  DH_GET_PARTICULAR_FIELD_API: 'dhGetParticularFieldApi'
}
export const alertMessage = {
  published: 'Are you sure you want to publish the plugin? Once published, the app will be available in the app marketplace.',
  unpublished: 'Are you sure you want to unpublish the plugin? Once unpublished, it will no longer be visible in the app marketplace.',
  deleted:
    'Are you sure you want to delete the plugin? Once deleted, it will be removed from the app marketplace. However, you can recover the plug-in by contacting support@viasocket.com.',
  integration_only: 'The app will soon be listed under Integrations, allowing users to request Beta Access.'
}

export function actionOrTriggerApiConfig(sectionKey, triggerTypeLocal) {
  return sectionKey === SECTIONS?.action
    ? [
        {
          title: 'Perform*',
          discription: 'Enter the code which we will make the request and send the input form data.',
          slug: 'perform'
        }
      ]
    : triggerTypeLocal === 'hook'
    ? [
        {
          title: 'Subscribe* ',
          discription:
            // "Setting Up Webhook Subscriptions: This assists in configuring workflow to subscribe to your API's events via webhooks, allowing customization of data and headers for successful subscription. It emphasizes the importance of storing subscription details for later management.",
            'Please provide a webhook endpoint below to test your subscription API. After entering, you can access your webhook URL using the path **context.inputData.hookUrl**. To create a webhook, you can utilize services like [Https dump](https://httpdump.app/).',
          slug: 'performsubscribe'
        },

        {
          title: 'Sample data (Perform List)*',
          discription:
            "This GET request gathers sample data in the plugin editor for testing trigger events and field mapping. It's crucial for user experience, especially in public apps. Ensure the response mirrors webhook-triggered events to prevent issues with mapped fields during live runs.",
          slug: 'performlist'
        },
        {
          title: 'Perform (Modify data before sending on flow)',
          discription:
            "If specific webhook data shouldn't activate the workflow, create code within this editor to produce an object that mirrors the format of the sample data precisely. If this code returns an array, we'll run the user's flow for each item individually",
          slug: 'modifytriggerdata'
        },
        {
          title: 'Unsubscribe* ',
          discription:
            'This request notifies your API that workflow is no longer listening for trigger events, often when the workflow is deactivated. Ensure correct configuration of request details for successful unsubscription.',
          slug: 'performunsubscribe'
        },
        {
          title: 'Transfer Data (Optional)',
          discription:
            'Transfer in Viasocket allows users to perform bulk operations using their historical data, enabling access to pre-existing data for ETL (Extract, Transform, Load) tasks.',
          slug: 'transferoption'
        }
      ]
    : [
        {
          title: 'Perform*',
          discription:
            "Enter the code which we will make the request to get data. If this code returns an array, we'll run the user's flow for each item individually",
          slug: 'perform'
        },
        {
          title: 'Sample data (Perform List)*',
          discription:
            "This GET request gathers sample data in the plugin editor for testing trigger events and field mapping. It's crucial for user experience, especially in public apps. Ensure the response mirrors webhook-triggered events to prevent issues with mapped fields during live runs.",
          slug: 'performlist'
        },
        {
          title: 'Transfer Data  (Optional)',
          discription:
            'Transfer in Viasocket allows users to perform bulk operations using their historical data, enabling access to pre-existing data for ETL (Extract, Transform, Load) tasks.',
          slug: 'transferoption'
        }
      ]
}

const AUTH2_FIELDS = [
  {
    id: 'authfields',
    panelKey: 'authfields',
    title: 'Configure your Fields',
    help: 'Create the necessary fields for authorization, including input fields (e.g., subdomain, auth key), password fields, and dropdowns (e.g., country code).'
  },
  {
    id: 'redirecturl',
    panelKey: 'redirecturl',
    title: 'Copy your OAuth Redirect URL',
    help: "Set up the OAuth Redirect URL by copying it into your OAuth provider's application "
  },
  {
    id: 'auth2Credentials',
    panelKey: 'auth2Credentials',
    title: 'Enter Application Credentials',
    help: 'Provide your OAuth 2.0 client ID and client secret for application authentication.'
  },
  {
    id: 'authorizationEndPointConfiguration',
    panelKey: 'authorizationEndPointConfiguration',
    title: 'Configure Authorization Endpoint',
    help: 'Set up the authorization endpoint URL and parameters required for OAuth 2.0 authorization.'
  },
  {
    id: 'accesstokencode',
    panelKey: 'accesstokencode',
    title: 'Configure Access Token API',
    help: 'Provide the code for the access token API to exchange the authorization grant for an access token.'
  },
  {
    id: 'refreshtokencode',
    panelKey: 'refreshtokencode',
    title: ' Configure Refresh Token API',
    help: 'Allows the client application to obtain a new access token without re-authentication.'
  },
  {
    id: 'revokeapicode',
    panelKey: 'revokeapicode',
    title: 'Configure Revoke Token API',
    help: 'Provide the code for the API to revoke OAuth2 tokens as needed.'
  },
  {
    id: 'testcode',
    panelKey: 'testcode',
    title: 'Configure Test (Me) API',
    help: 'Provide the URL for testing and verifying your OAuth 2.0 authentication setup.'
  },
  {
    id: 'connectionLabel',
    panelKey: 'connectionLabel',
    title: 'Add Connection Label',
    help: 'Name your authentication connection uniquely for easy identification and management.'
  },
  {
    id: 'whiteListDomains',
    panelKey: 'whiteListDomains',
    title: 'Add Urls to Whitelist',
    help: 'We will allow api endpoints for Action/Triggers with this domains only'
  },
  {
    id: 'authUniqueKey',
    panelKey: 'authUniqueKey',
    title: 'Add Unique Authentication Identifier',
    help: "This field lets us store a unique identifier for user's connection to this service. While not required for all services, providing it helps us manage your tokens more efficiently. If you create a new token, we can update the existing one instead of creating duplicates, ensuring your latest token stays active. If you're unsure, you can leave it blank. Avoid using any hardcoded characters."
  },
  {
    id: 'appeandHeaders',
    panelKey: 'appeandHeaders',
    title: 'Set Request Parameters',
    help: 'Provide key-value pairs for headers, query parameters, or body to be added to each request made with this authentication.'
  }
]

const AUTH1_FIELDS = [
  {
    id: 'auth2Credentials',
    panelKey: 'auth2Credentials',
    title: 'Enter Application Credentials',
    help: 'Provide your OAuth 1.0 Consumer key and Consumer secret for application authentication.'
  },
  {
    id: 'redirecturl',
    panelKey: 'redirecturl',
    title: 'Copy your OAuth Redirect URL',
    help: "Set up the OAuth Redirect URL by copying it into your OAuth provider's application "
  },
  {
    id: 'auth1Urls',
    panelKey: 'auth1Urls',
    title: 'Configure OAuth1 Endpoint',
    help: 'Set up the authorization endpoint URL required for OAuth1 authorization.'
  },
  {
    id: 'testcode',
    panelKey: 'testcode',
    title: 'Configure Test (Me) API',
    help: 'Provide the URL for testing and verifying your OAuth 2.0 authentication setup.'
  },
  {
    id: 'connectionLabel',
    panelKey: 'connectionLabel',
    title: 'Add Connection Label',
    help: 'Name your authentication connection uniquely for easy identification and management.'
  },
  {
    id: 'whiteListDomains',
    panelKey: 'whiteListDomains',
    title: 'Add Urls to Whitelist',
    help: 'We will allow api endpoints for Action/Triggers with this domains only'
  },
  {
    id: 'authUniqueKey',
    panelKey: 'authUniqueKey',
    title: 'Add Unique Authentication Identifier',
    help: "This field lets us store a unique identifier for user's connection to this service. While not required for all services, providing it helps us manage your tokens more efficiently. If you create a new token, we can update the existing one instead of creating duplicates, ensuring your latest token stays active. If you're unsure, you can leave it blank. Avoid using any hardcoded characters."
  },
  {
    id: 'appeandHeaders',
    panelKey: 'appeandHeaders',
    title: 'Set Request Parameters',
    help: 'Provide key-value pairs for headers, query parameters, or body to be added to each request made with this authentication.'
  }
]

const BASIC_FIELDS = [
  {
    id: 'authfields',
    panelKey: 'authfields',
    title: 'Configure your Fields',
    help: '**You can add authentication credentials here to test and set up your authentication. These credentials will not be saved.**    ',
    IconComponent: SecurityIcon,
    defaultExpanded: true
  },
  {
    id: 'testcode',
    panelKey: 'testcode',
    title: 'Configure Test (Me) API',
    help: 'Provide the URL for testing and verifying the authentication credentials.'
  },
  {
    id: 'connectionLabel',
    panelKey: 'connectionLabel',
    title: 'Add Connection Label',
    help: 'Name your authentication connection uniquely for easy identification and management.'
  },
  {
    id: 'whiteListDomains',
    panelKey: 'whiteListDomains',
    title: 'Add Urls to Whitelist',
    help: 'We will allow api endpoints for Action/Triggers with this domains only'
  },
  {
    id: 'appeandHeaders',
    panelKey: 'appeandHeaders',
    title: 'Set Request Parameters',
    help: 'Provide key-value pairs for headers, query parameters, or body to be added to each request made with this authentication.'
  }
]

const getAuth2Fields = (granttype) => {
  const fields = [...AUTH2_FIELDS]
  if (granttype === GRANT_TYPE.clientCredentials || granttype === GRANT_TYPE.passwordCredentials) {
    fields.splice(1, 3)
  }
  if (granttype === GRANT_TYPE.implicit) {
    fields.splice(4, 1) // Remove access token API
  }
  return fields
}

export const AccordianFieldsForAuthSection = (granttype, currentAuthType) => {
  switch (currentAuthType) {
    case DH_AUTH_TYPES.auth2:
      return getAuth2Fields(granttype)
    case DH_AUTH_TYPES.auth1:
      return AUTH1_FIELDS
    default:
      return BASIC_FIELDS
  }
}

export const panelKeyAndStepsNameMapping = (key: string) => {
  switch (key) {
    case 'authenticationpaths':
      return 'appeandHeaders'

    case 'authrequrl':
    case 'queryparams':
      return 'authorizationEndPointConfiguration'

    case 'connectionlabelkey':
    case 'connectionlabelvalue':
      return 'connectionLabel'

    default:
      return key
  }
}

export const unSavedFieldsDhEnums: { [key: string]: string } = {
  authfields: 'Configure Your Fields',
  redirecturl: 'Redirect URL',
  auth2Credentials: 'Application Credentials',
  authenticationFields: 'Configure Authorization Endpoint',
  accesstokencode: 'Access Token Code',
  refreshtokencode: 'Refresh Token Code',
  revokeapicode: 'Revoke API Code',
  testcode: 'Test/Me API Code',
  connectionLabel: 'Connection Label',
  authUniqueKey: 'Unique Authentication Identifier',
  appeandHeaders: 'Set Request Parameters',
  perform: 'Perform API',
  performsubscribe: 'Subscribe Code',
  performlist: 'Sample Data(Perform List) Code',
  modifytriggerdata: 'Modify Trigger Data Code',
  performunsubscribe: 'Unsubscribe Code',
  transferoption: 'Transfer Option',
  inputjson: 'Input Builder'
}

function deepFreeze<T>(obj: T): T {
  Object.freeze(obj)

  Object.getOwnPropertyNames(obj).forEach((prop) => {
    const value = (obj as any)[prop]
    if (value !== null && typeof value === 'object' && !Object.isFrozen(value)) {
      deepFreeze(value)
    }
  })

  return obj
}

export const VERSION_VERIFICATION_STATUS = {
  NOT_VERIFIED: 'NOT_VERIFIED',
  VERIFIED: 'VERIFIED',
  L1_VERIFIED: 'L1_VERIFIED',
  L2_VERIFIED: 'L2_VERIFIED',
  L2_REJECTED: 'L2_REJECTED',
  L3_VERIFIED: 'L3_VERIFIED',
  L3_REJECTED: 'L3_REJECTED'
}
interface VerificationStatusConfig {
  label: string
  colorClass: string
}
export const verificationStatusMap: Record<string, VerificationStatusConfig> = {
  L1_VERIFIED: {
    label: 'Beta Verified',
    colorClass: 'text-green-600'
  },
  L2_VERIFIED: {
    label: 'Preview Verified',
    colorClass: 'text-green-600'
  },
  L2_REJECTED: {
    label: 'Preview Rejected',
    colorClass: 'text-red-600'
  },
  VERIFIED: {
    label: 'Verified',
    colorClass: 'text-green-600'
  },
  NOT_VERIFIED: {
    label: 'Not Verified',
    colorClass: 'text-red-600'
  }
}
