import { Alarm, Webhook } from '@mui/icons-material'
// import CodeIcon from '@mui/icons-material/Code'
import { JSIcon,pathIcon,interventionIcon,apiIcon,aiIcon,delayIcon,commentIcon,memoryIcon} from '../assests/assestsIndex.ts'
import IconWrapper from '../components/IconWrapper/IconWrapper.tsx'

/**
 * Built-in tools object containing predefined actions.
 * Each action has a unique row ID, name, and a corresponding component.
 *
 * @type {Object}
 * @property {string} pluginname - The name of the plugin.
 * @property {string} pluginrowid - The unique row ID of the plugin.
 * @property {JSX.Element} iconComponent - The icon component representing the plugin.
 * @property {Array<Object>} actions - The list of actions available in the built-in tools.
 * @property {string} actions[].rowid - The unique row ID of the action.
 * @property {string} actions[].name - The name of the action.
 * @property {JSX.Element} actions[].component - The icon component representing the action.
 */

export const builtInToolsArray = [
  {
    rowid: 'ifGroup',
    name: 'Multiple Paths (If Conditions)',
    component: <IconWrapper component={<img src={pathIcon} alt=''/>} size='34px' />,
    description: 'Branch the flow into different directions based on conditions or decisions.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Multiple-Paths'
  },
  {
    rowid: 'function',
    name: 'JS Code',
    component: <IconWrapper component={<img src={JSIcon} alt='' />} size='34px'/>,
    description: 'Run custom task, logic or calculations with JS code.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/functions'
  },
  // {
  //   rowid: 'variable',
  //   name: 'Variable',
  //   component: <CodeIcon className='!h-full !w-full' />,
  //   description: 'Transform existing data or use it as configurable data.',
  //   link: 'https://viasocket.com/faq/features/variable'
  // },
  {
    rowid: 'api',
    name: 'HTTP API Request',
    component: <IconWrapper component={<img src={apiIcon} alt='' />} size='34px'/>,
    description: 'Connect and exchange data with external services.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/custom-api-integration'
  },
  {
    rowid: 'human-intervention',
    name: 'Human Intervention',
    component: <IconWrapper component={<img src={interventionIcon} alt='' />} size='34px'/>,
    description: 'Pause the workflow until manual approval is given to proceed.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Human-Intervention'
  },
  {
    rowid: 'ai-step',
    name: 'Custom Logic (AI)',
    component: <IconWrapper component={<img src={aiIcon} alt='' />} size='34px'/>,
    description: 'Ask AI and use its answer in the flow.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/ai'
  },
  {
    rowid: 'memory',
    name: 'Workspace Memory',
    component: <IconWrapper component={<img src={memoryIcon} alt='' />} size='34px'/>,
    description: 'Memory is available across the workspace in any flow.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Memory'
  },

  {
    rowid: 'delay-step',
    name: 'Delay',
    component: <IconWrapper component={<img src={delayIcon} alt='' />} size='34px'/>,
    description: 'Run remaining flow after custom logic-based delay.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/Delay'
  },
  {
    rowid: 'comment',
    name: 'Comment',
    component: <IconWrapper component={<img src={commentIcon} alt=''/>} size='34px'/>,
    description: 'Add notes or explanations to clarify steps.',
    link: 'https://viasocket.com/faq/viasocket-flow/In--built-Tools/comment'
  }
]

export const ownTriggers = [
  {
    rowid: 'webhook',
    name: 'Webhook',
    component: <Webhook className='!h-full !w-full' />,
    description: 'When triggered by API call or webhook.'
  },
  {
    rowid: 'cron',
    name: 'Cron/Repeater',
    component: <Alarm className='!h-full !w-full' />,
    description: 'Runs flow at regular intervals.'
  }
  // {
  //   rowid: 'email',
  //   name: 'Email',
  //   component: <Email className='!h-full !w-full' />,
  //   description: 'Starts flow when email arrives.'
  // }
]

/**
 * Function to convert an array of plugins into an array of actions.
 * Filters actions based on the provided search query.
 *
 * @param {Array} pluginArray - Array of plugins to be converted.
 * @param {string} searchQuery - The search query to filter actions.
 * @returns {Array} - Array of actions filtered by the search query.
 */
export function convertPluginArrayToActionArray(pluginArray, searchQuery) {
  searchQuery = (searchQuery || '')?.trim()
  if (!searchQuery?.trim()) return pluginArray
  const resultArray = []
  const uniqueActionIds = new Set()
  pluginArray.forEach((plugin, pluginIndex) => {
    const { pluginname, iconurl, actions, pluginrowid, searched } = plugin

    if (actions?.length) {
      actions?.forEach((action, actionIndex) => {
        if (!uniqueActionIds.has(action.rowid) && (!searchQuery.includes('Memory') || action.rowid !== 'memory')) {
          uniqueActionIds.add(action.rowid)
          resultArray.push({
            pluginname: action.name,
            pluginrowid: action.rowid,
            pluginverified: action.pluginverified,
            iconurl: iconurl,
            group: `${pluginname} ${!action.pluginverified ? '_notVerifiedBySocket' : ''}`,
            category: action.category,
            isAiAction: action.isaiaction || false,
            used_count: action.used_count,
            groupId: pluginrowid,
            iconComponent: action.component,
            type: 'action',
            actionIndex,
            pluginIndex,
            searched
          })
        }
      })
    }
  })
  return resultArray
}
