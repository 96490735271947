import { createSlice } from '@reduxjs/toolkit'

import { initialState, reducers, extraReducers } from './authReducer'

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers,
  extraReducers
})

export const { remove, addCurrentAuth, updateExistingAuth, addNewAuth, deleteAnAuth, setRelatedFlows } = authSlice.actions

export default authSlice.reducer
