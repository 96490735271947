import { Box, Card, List, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import EditNoteIcon from '@mui/icons-material/EditNote'
import { useNavigate } from 'react-router-dom'
import { styled } from '@mui/system'
import { ParamsEnums, Tabnames } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import EditPencil from '../../components/Icons/EditPencil.tsx'
import ShowTriggerIcon from '../../components/functiondashboard/workFlow/workFlowNavbar/workFlowTitleDetail/ShowTriggerIcon.tsx'
import { getVersionDetails } from '../../utils/utilities'

const CustomListItemButton = styled(ListItemButton)(() => ({
  borderBottom: '1px solid var( --col-grey-two)'
}))

function CompleteAndIncompleteAutomationsList({ projectId, orgId, setSearchParams, allChats = [], onCountsUpdate, isEmbedUrl }) {
  const navigate = useNavigate()
  const { scripts, recentFlowsIds } = useCustomSelector((state) => ({
    scripts: state.scripts.scripts,
    recentFlowsIds: state.scripts?.orderOfScriptsLastUpdated
  }))

  function navigateToOrgOrFlow(flowId) {
    const flow = scripts?.[flowId]
    if (isEmbedUrl) {
      navigate(`/integrations/embed/${orgId}/${flow?.project_id}/workflow/${flowId}/${Tabnames.DRAFT}`)
    } else if (flow) {
      navigate(`/projects/${orgId}/${flow?.project_id}/workflows/${flowId}/draft`)
    } else {
      setSearchParams({ chatId: flowId })
    }
  }

  const activeAutomations = allChats?.filter((chat) => scripts?.[chat?.sub_thread_id]?.metadata?.published?.versioning?.length)
  const incompleteAutomations = allChats?.filter((chat) => !scripts?.[chat?.sub_thread_id]?.metadata?.published?.versioning?.length)
  const activeFlows = recentFlowsIds?.filter(
    (flowId) =>
      !allChats?.find((chat) => chat.sub_thread_id === flowId) &&
      (scripts?.[flowId]?.project_id === projectId || !projectId) &&
      scripts?.[flowId]?.metadata?.published?.versioning?.length &&
      scripts?.[flowId]?.status !== '0'
  )

  const incompleteFlows = recentFlowsIds?.filter(
    (flowId) =>
      !allChats?.find((chat) => chat.sub_thread_id === flowId) &&
      (scripts?.[flowId]?.project_id === projectId || !projectId) &&
      !scripts?.[flowId]?.metadata?.published?.versioning?.length &&
      scripts?.[flowId]?.status !== '0'
  )

  useEffect(() => {
    if (onCountsUpdate) {
      onCountsUpdate({
        activeAutomationsCount: activeAutomations?.length || 0,
        incompleteAutomationsCount: incompleteAutomations?.length || 0,
        activeFlowsCount: activeFlows?.length || 0,
        incompleteFlowsCount: incompleteFlows?.length || 0
      })
    }
  }, [activeAutomations, incompleteAutomations, activeFlows, incompleteFlows, onCountsUpdate])

  return (
    <Box className='w-full flex gap-4'>
      {activeAutomations?.length > 0 || activeFlows?.length > 0 ? (
        <List disablePadding className='overflow-y-auto w-1/2'>
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            Active
          </Typography>
          <Card>
            {activeAutomations?.map((item) => (
              <Tooltip key={item?.sub_thread_id} title={item?.display_description || 'No description available'} arrow placement='top'>
                <CustomListItemButton fullWidth onClick={() => navigateToOrgOrFlow(item?.sub_thread_id)}>
                  <EditNoteIcon />
                  <ListItemText className='!ml-3' primary={item?.display_name || 'Unnamed Automation'} />
                </CustomListItemButton>
              </Tooltip>
            ))}

            {activeFlows?.map((flowId) => {
              const flow = scripts?.[flowId]
              const trigger = flow?.json_script?.trigger
              const { draftChangesAvailable } = getVersionDetails(flow)

              return (
                <Tooltip key={flowId} title={flow?.metadata?.description || 'No description available'} arrow placement='top'>
                  <CustomListItemButton fullWidth onClick={() => navigateToOrgOrFlow(flowId)}>
                    <ShowTriggerIcon triggerInfo={trigger} size='20px' />
                    <ListItemText className='!ml-3' primary={flow.title || `${trigger?.serviceName || trigger?.triggerType} Flow`} />
                    {draftChangesAvailable && <EditPencil />}
                  </CustomListItemButton>
                </Tooltip>
              )
            })}
          </Card>
        </List>
      ) : null}
      {/* <Divider orientation='vertical' flexItem /> */}
      {incompleteAutomations?.length > 0 || incompleteFlows?.length > 0 ? (
        <List disablePadding className='overflow-y-auto w-1/2'>
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            Inactive
          </Typography>
          <Card>
            {incompleteAutomations?.map((item) => (
              <Tooltip key={item?.sub_thread_id} title={item?.description || 'No description available'} arrow placement='top'>
                <CustomListItemButton fullWidth onClick={() => navigateToOrgOrFlow(item?.sub_thread_id)}>
                  <EditNoteIcon />
                  <ListItemText className='!ml-3' primary={item?.display_name || 'Unnamed Automation'} />
                </CustomListItemButton>
              </Tooltip>
            ))}

            {incompleteFlows?.map((flowId) => {
              const flow = scripts?.[flowId]
              const trigger = flow?.json_script?.trigger

              return (
                <Tooltip key={flowId} title={flow?.metadata?.description || 'No description available'} arrow placement='top'>
                  <CustomListItemButton fullWidth onClick={() => navigateToOrgOrFlow(flowId)}>
                    <ShowTriggerIcon triggerInfo={trigger} size='20px' />
                    <ListItemText className='!ml-3' primary={flow.title || `${trigger?.serviceName || trigger?.triggerType} Flow`} />
                  </CustomListItemButton>
                </Tooltip>
              )
            })}
          </Card>
        </List>
      ) : null}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(CompleteAndIncompleteAutomationsList), [
    ParamsEnums.projectId,
    ParamsEnums.orgId,
    'chatId',
    ParamsEnums.isEmbedUrl
  ])
)
