import { createAsyncThunk } from '@reduxjs/toolkit'
import { remove, updateExistingAuth } from './authSlice'
import { getAllAuthorizationDataByOrgId, updateTitleOrDescriptionAuthorizationData } from '../../api/index'
import { errorToast } from '../../components/customToast'

const removeAuth = createAsyncThunk('auth/removeAuth', async (_payload, { dispatch }) => {
  dispatch(remove())
  return 5
})

export const getAllAuth = createAsyncThunk('auth/getAllAuth', async (orgId, { rejectWithValue }) => {
  if (!orgId) return []
  try {
    const response = await getAllAuthorizationDataByOrgId(orgId)
    return response?.data?.data || []
  } catch (error) {
    return rejectWithValue(error.message)
  }
})

export const updateAuthDescriptionAndAccessLevel = createAsyncThunk(
  'auth/updateAuthDescriptionAndAccessLevel',
  async ({ orgId, authId, accessLevelAndDescription }, { dispatch, rejectWithValue }) => {
    try {
      if (accessLevelAndDescription?.description) {
        await updateTitleOrDescriptionAuthorizationData(orgId, authId, accessLevelAndDescription)
      }
      dispatch(updateExistingAuth({ authId, ...accessLevelAndDescription }))

      return { authId, description: accessLevelAndDescription.description }
    } catch (error) {
      errorToast(error?.message || 'Failed to update description')
      return rejectWithValue(error.message || 'Failed to update description')
    }
  }
)

export default removeAuth
