import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import './responseAndConsoleTabsV2.scss'
import Box from '@mui/material/Box'
import { Alert, AlertTitle, Card, Link, Tab, Tabs, Typography } from '@mui/material'
import ConsoleTab from '../functiondashboard/tabs/consoleTab/consoleTab'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import ShowPayload from '../functiondashboard/apiSliderDashboard/apiPayload/showPayload'
import { BlockTypes, LOCAL_NOTFICATION_REQUIRED_FIELDS, ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import LocalNotification from '../Notifications/localNotification.tsx'
import Tree from '../functiondashboard/workFlow/Tree'
import { setKeyValueInstance } from '../../store/stepsV2/stepSliceV3.ts'
import LogsDataTimeChip from '../LogsDataTimeChip/LogsDataTimeChip.tsx'
import { doesValueExists } from '../../utils/utilities'

type ResponseAndConsoleTabsProps = {
  showPayloadTab: boolean
  showConsoleTab: boolean
  slugName: string
  stepId: string
  tabName: string
  inVariableSlider?: boolean
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  )
}
/**
 * @param {boolean} showPayloadTab - Indicates whether to show the payload tab.
 * @param {boolean} showConsoleTab - Indicates whether to show the console tab.
 * @param {string} scriptId - The ID of the section or script.
 * @param {string} slugName - The slug name.
 * @param {string} stepId - The ID of the version or step.
 * @param {string} tabName - The name of the tab.
 * @param {boolean} [inVariableSlider=false] - Indicates whether the component is inside a variable slider.
 * @param {boolean} [inModal=false] - Indicates whether the component is inside a modal.
 */
function ResponseAndConsoleTabs({
  showPayloadTab,
  showConsoleTab,
  slugName: currentSlugName,
  stepId,
  tabName,
  sectionIdOrScriptId: scriptId,
  inVariableSlider = false,
  versionIdOrStepId,
  sectionKey,
  inModal = false
}: ResponseAndConsoleTabsProps) {
  const dispatch = useDispatch()
  const slugName = sectionKey || currentSlugName
  const { sampleData, lastInvocationData, currentStepType, aiHelpTextAndCode } = useCustomSelector((state: $ReduxCoreType) => ({
    aiHelpTextAndCode: state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]?.aiHelpTextAndCode,
    lastInvocationData: state.invocationV2?.[scriptId]?.invocationData,
    currentStepType: state.appInfo.currentStepType,
    sampleData:
      state.appInfo.currentStepType === BlockTypes.TRIGGER
        ? state.stepsDataV3?.[scriptId]?.[stepId]?.[tabName]?.pluginData?.sampleData
        : state.flowJsonV2?.[scriptId]?.flowJson?.blocks?.[slugName]?.sampleData
  }))
  const [responseToShow, setResponseToShow] = useState(
    currentStepType === BlockTypes.TRIGGER
      ? lastInvocationData?.requestSnapshot?.body
      : currentStepType === BlockTypes.VARIABLE
      ? lastInvocationData?.vals?.[slugName]
      : lastInvocationData?.responseSnapshot?.[slugName]
  )
  const [sampleDataToShow, setSampleDataToShow] = useState(sampleData)
  const [value, setValue] = useState('RESPONSE')

  useEffect(() => {
    if (showConsoleTab) {
      if (!lastInvocationData?.responseSnapshot?.[slugName]?.toString() && lastInvocationData?.console?.[slugName]) setValue('CONSOLE')
      else if (value !== 'RESPONSE') setValue('RESPONSE')
    }
  }, [lastInvocationData?.responseSnapshot?.[slugName]])

  useEffect(() => {
    let updateResponseToShow = {}
    switch (currentStepType) {
      case BlockTypes.VARIABLE:
        if (typeof lastInvocationData?.vals?.[slugName] === 'function')
          updateResponseToShow = lastInvocationData?.vals?.[slugName]?.toString()
        else updateResponseToShow = lastInvocationData?.vals?.[slugName]
        break
      case BlockTypes.TRIGGER:
        updateResponseToShow = lastInvocationData?.requestSnapshot?.body
        break
      default:
        updateResponseToShow = lastInvocationData?.responseSnapshot?.[slugName]
        break
    }
    setResponseToShow(updateResponseToShow)
  }, [lastInvocationData?.responseSnapshot?.[slugName], lastInvocationData?.vals?.[slugName], lastInvocationData?.requestSnapshot?.body])
  useEffect(() => {
    if (doesValueExists(sampleData)) {
      setSampleDataToShow(sampleData)
      if (!doesValueExists(responseToShow)) {
        setValue('SAMPLE_DATA')
      }
    }
    if (doesValueExists(responseToShow) && value === 'SAMPLE_DATA') {
      setValue('RESPONSE')
    }
  }, [sampleData, responseToShow])

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue)
  }
  const parentName = currentStepType === BlockTypes.TRIGGER ? 'body' : slugName

  const rootpath =
    currentStepType === BlockTypes.TRIGGER ? 'context?.req' : inVariableSlider ? 'context?.vals' : slugName ? `context?.res` : ''

  return (
    <Box className='column w-100 gap-2 mt-2 p-2'>
      {aiHelpTextAndCode?.gpt_response && (
        <Card sx={{ backgroundColor: '#53c1f2' }}>
          <Alert icon={false} type='markdown-wrapper' className='flex-start-top ml-2 p-2' sx={{ backgroundColor: 'rgb(246 251 254)' }}>
            <AlertTitle>Socket AI</AlertTitle>
            <Typography>
              {typeof aiHelpTextAndCode?.gpt_response === 'object'
                ? aiHelpTextAndCode?.gpt_response?.message || aiHelpTextAndCode?.gpt_response?.text
                : aiHelpTextAndCode?.gpt_response}
            </Typography>
            {aiHelpTextAndCode?.corrected_by_ai && (
              <Link
                component='button'
                onClick={() => {
                  dispatch(
                    setKeyValueInstance({
                      ...aiHelpTextAndCode,
                      id: versionIdOrStepId,
                      gpt_response: undefined,
                      aiHelpTextAndCode: undefined
                    })
                  )
                }}
              >
                Apply Changes
              </Link>
            )}
          </Alert>
        </Card>
      )}
      {inModal ? <Box className='response-and-console-tab' /> : null}
      <LocalNotification
        type={LOCAL_NOTFICATION_REQUIRED_FIELDS.type}
        address={`${stepId}.${LOCAL_NOTFICATION_REQUIRED_FIELDS.address}`}
        message={LOCAL_NOTFICATION_REQUIRED_FIELDS.message}
      />
      {lastInvocationData?.console?.[slugName] !== undefined || responseToShow !== undefined || sampleDataToShow !== undefined ? (
        <Box>
          <LogsDataTimeChip />
          <Box className='column flex-grow pos-rel disable-border ' key={slugName}>
            <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
              {responseToShow !== undefined && (
                <Tab label='Response' value='RESPONSE' id='simple-tab-0' aria-controls='simple-tabpanel-0' />
              )}
              {responseToShow === undefined && doesValueExists(sampleDataToShow) && (
                <Tab label='Sample Data' value='SAMPLE_DATA' id='simple-tab-0' aria-controls='simple-tabpanel-0' />
              )}
              {showConsoleTab && lastInvocationData?.console?.[slugName] && (
                <Tab label='Console' value='CONSOLE' id='simple-tab-1' aria-controls='simple-tabpanel-1' />
              )}
              {showPayloadTab && <Tab label='Payload' value='PAYLOAD' id='simple-tab-2' aria-controls='simple-tabpanel-2' />}
            </Tabs>
            <Box className='w-100 p-3'>
              <TabPanel value={value} index='RESPONSE'>
                <Tree data={responseToShow} parentName={parentName} path={`${rootpath}?.${parentName}`} />
              </TabPanel>
              <TabPanel value={value} index='SAMPLE_DATA'>
                <Tree data={sampleDataToShow} parentName={parentName} path={`${rootpath}?.${parentName}`} />
              </TabPanel>
              <TabPanel value={value} index='CONSOLE'>
                <ConsoleTab console={lastInvocationData?.console?.[slugName]} />
              </TabPanel>
              <TabPanel value={value} index='PAYLOAD'>
                <ShowPayload />
              </TabPanel>
            </Box>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ResponseAndConsoleTabs), [
    ParamsEnums.slugName,
    ParamsEnums.stepId,
    ParamsEnums.tabName,
    ParamsEnums.sectionIdOrScriptId,
    ParamsEnums.versionIdOrStepId,
    ParamsEnums.sectionKey
  ])
)
