import { Add } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Button, IconButton, Modal, Tooltip, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ParamsEnums, Tabnames, THUNK_CALL_STATUS } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import CreateNewFlowModal from '../createNewFlowModal/CreateNewFlowModal.tsx'
import CustomModal from '../customModal/customModal'
import { saveScripts } from '../../store/scripts/scriptsThunk'
import config from '../../config'

interface CreateNewPopoverProps {
  orgId?: string
  projectId?: string
  defaultProjectId?: string
  isButton?: boolean
  isText?: boolean
  toolTipText?: string
}

/**
 * CreateNewPopover component for creating new items in a project dashboard.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {string} props.projectId - The ID of the current project.
 * @param {string} props.defaultProjectId - The default project ID to use if projectId is not provided.
 * @returns {JSX.Element} The rendered CreateNewPopover component.
 */

function CreateNewPopover({
  orgId,
  projectId,
  defaultProjectId,
  isButton = true,
  isText = false,
  toolTipText = ''
}: CreateNewPopoverProps) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { length, modalType, userShowDemo } = useCustomSelector((state) => ({
    length: state.scripts?.orderOfScriptsLastUpdated?.length,
    modalType: state.appInfo.modalType,
    userShowDemo: state.user?.meta?.showDemo
  }))
  const finalProjectId = defaultProjectId || projectId
  const [showDemo, setShowDemo] = useState(false)
  const createFlow = (): void => {
    if (userShowDemo && length < 5) {
      setShowDemo(true)
    }

    const project_Id = finalProjectId
    dispatch(
      saveScripts({
        project_id: project_Id,
        org_id: orgId
      })
    ).then((e) => {
      if (e?.meta?.requestStatus === THUNK_CALL_STATUS?.FULFILLED) {
        navigate(`${config.projectsBaseUrl}/${orgId}/${project_Id}${config.workflowBaseUrl}/${e.payload.id}/${Tabnames.DRAFT}`)
      }
    })
  }

  return (
    <Box key={finalProjectId}>
      {isButton ? (
        <Box className='mx-2'>
          <Button variant='contained' onClick={() => createFlow()}>
            Create New Flow
            <AddIcon fontSize='small' />
          </Button>
        </Box>
      ) : isText ? (
        <Typography
          variant='h4'
          className='text-[var(--col-error)] cursor-pointer mx-1'
          style={{ borderBottom: '2px dotted' }}
          onClick={createFlow}
        >
          Create a flow manually
        </Typography>
      ) : (
        <Tooltip title={toolTipText} placement='top'>
          <IconButton variant='outlined' onClick={() => createFlow()}>
            <Add variant='customSize' folderBtn className='group-hover:text-black' />
          </IconButton>
        </Tooltip>
      )}
      <Box
        onClick={(e) => {
          e.stopPropagation() // Prevent the click from propagating to the parent
          e.preventDefault() // Prevent the default action
        }}
      >
        <CustomModal openModal={modalType === `create_new_flow_${finalProjectId}`} width='1100px'>
          {/* <AppsExplore finalProjectId={finalProjectId} /> */}
          <CreateNewFlowModal finalProjectId={finalProjectId} />
          {/* <CreateNewFlowModal finalProjectId={finalProjectId} /> */}
        </CustomModal>
      </Box>
      <Modal open={showDemo} onClose={() => setShowDemo(false)}>
        <Box
          sx={{
            position: 'absolute',
            right: '0',
            width: '100%',
            height: '100%'
          }}
          onClick={(e) => e.stopPropagation()}
        >
          <iframe
            title='Supademo'
            src='https://video-faq.viasocket.com/embed/cm8hbquis00f97q3vvsyva7e3?embed_v=2%22'
            width='100%'
            height='100%'
            frameBorder='0'
            allowFullScreen
          />
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 65,
              zIndex: 10,
              backgroundColor: 'rgba(255, 255, 255, 0.8)',
              boxShadow: 2,
              borderRadius: '10%'
            }}
          >
            <IconButton
              onClick={() => setShowDemo(false)}
              sx={{
                backgroundColor: 'white',
                color: 'black',
                width: 36,
                height: 36,
                '&:hover': { backgroundColor: 'rgba(255, 255, 255, 1)', borderRadius: '10%', color: 'black' }
              }}
            >
              ✖
            </IconButton>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(CreateNewPopover), [ParamsEnums.orgId, ParamsEnums.projectId]))
