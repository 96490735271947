import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchProjects } from '../../store/projects/projectsThunk'
import { errorToast } from '../../components/customToast'
import config from '../../config'
import './projectPage.scss'
import { updateAppInfoState, closeModalRedux } from '../../store/appInfo/appInfoSlice.ts'
import getAllUsers from '../../store/orgUsers/orgUsersThunk'
import { ApiTypes, ParamsEnums } from '../../enums'
import { $ReduxCoreType } from '../../types/reduxCore.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { listOfUsers } from '../../store/orgUsers/orgUsersSelector'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import SelectedWorkspace from '../../components/workspaceComponent/selectedWorkspace.tsx'
import AuthorizeOrgModal from '../../components/oauthAuthorization/OauthAuthorizationModal'
import { switchOrgId } from '../../utils/utilities'
import PluginRequestForm from '../../components/plugin/pluginComponents/pluginRequestForm.tsx'
import VideoPlayer from '../../components/videoPlayer/videoPlayer.tsx'
import ProjectSliderOptionsAndButtons from './ProjectSliderOptionsAndButtons.tsx'
import { getAllAuth } from '../../store/auth/authThunk'

// eslint-disable-next-line

function ProjectPageV2(props: { orgId: any; projectId: any }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const orgId = props?.orgId
  const projectId = props?.projectId

  const { modalType, mode, usersList, allProjects, currentOrgId, isProjectSliderOpen } = useCustomSelector((state: $ReduxCoreType) => ({
    modalType: state.appInfo.modalType,
    mode: state.appInfo.mode,
    usersList: listOfUsers(state),
    allProjects: state?.projects?.projects[orgId],
    currentOrgId: state.appInfo?.currentOrgId,
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen
  }))
  const activeProjects = allProjects?.['active'] || {}
  const deletedProjects = allProjects?.['deleted'] || {}

  const getUsersList = async () => {
    dispatch(getAllUsers({ orgId }))
  }

  const checkProjectPageRoute = () => {
    const res = activeProjects[projectId] || deletedProjects[projectId]
    if (res === null && projectId) {
      navigate(`${config.projectsBaseUrl}`)
    }
  }

  useEffect(() => {
    checkProjectPageRoute()
  }, [projectId])

  useEffect(() => {
    if (orgId) dispatch(getAllAuth(orgId))
  }, [orgId])

  useEffect(() => {
    const handleProjects = async () => {
      if (orgId?.length) {
        if (orgId !== currentOrgId) {
          try {
            await switchOrgId(orgId, orgId)
          } catch (error) {
            errorToast("You don't have access to this organization")
            navigate(`${config.orgBaseUrl}`)
          }
        }
        dispatch(updateAppInfoState({ currentOrgId: orgId }))
        if (mode !== ApiTypes.EMBED) dispatch(fetchProjects({ orgId, type: ApiTypes.FLOW }))
        getUsersList()
      }
    }
    handleProjects()
  }, [orgId])

  const [open, setOpen] = useState(false)

  const handleSelectedOpen = () => {
    setOpen(!open)
  }

  return (
    <Box
      className={`relative flex flex-col justify-between items-center overflow-y-scroll border-r-sharp project-page-cont ${
        isProjectSliderOpen ? 'h-screen' : ''
      } z-[150] ${open ? 'bg-[var(--col-dark-grey-background)]' : 'bg-[var(--col-main-container)]'}`}
    >
      <Box
        className={`!absolute w-full overflow-scroll-y z-[1] max-h-screen ${
          open ? 'bg-[var(--col-dark-grey-background)]' : 'bg-[var(--col-main-container)]'
        }`}
      >
        <Box className='flex flex-col selected-workspace cursor-pointer w-full' onClick={handleSelectedOpen}>
          <SelectedWorkspace open={open} usersList={usersList} />
        </Box>

        {!open && <ProjectSliderOptionsAndButtons open={open} />}
      </Box>

      <AuthorizeOrgModal />

      <PluginRequestForm />

      <VideoPlayer
        openModal={['webhook', 'email', 'cron'].includes(modalType)}
        type={modalType}
        onClose={() => dispatch(closeModalRedux())}
      />
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(ProjectPageV2), [ParamsEnums.projectId, ParamsEnums.orgId]))
