/* eslint-disable */
import { Add } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React, { useState } from 'react'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import ContextVariableMenu from './ContextVariableMenu.tsx'

function AddVariableButton({ useIt = () => {}, isHidden = false, usedRef }: { useIt: () => void; isHidden: boolean; usedRef: any }) {
  const [anchorEl, setAnchorEl] = useState(null)
  return (
    <>
      {' '}
      <IconButton
        ref={usedRef}
        className={`bg-primary m-2  ${!anchorEl && isHidden ? 'opacity-0' : ''}`}
        onClick={(e) => {
          e.stopPropagation()
          setAnchorEl(e.currentTarget)
        }}
      >
        <Add color='white' fontSize='small' />
      </IconButton>
      {anchorEl && <ContextVariableMenu useIt={useIt} parentAnchorEl={anchorEl} setParentAnchorEl={setAnchorEl} />}
    </>
  )
}
export default React.memo(addUrlDataHoc(React.memo(AddVariableButton), []))
