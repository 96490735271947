import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { convertScriptToTemplate, getGlobalData } from './api'
import { ParamsEnums } from './enums'
import addUrlDataHoc from './hoc/addUrlDataHoc.tsx'

function AddTemplateButton({ orgId, setTemplates = () => {} }) {
  const searchRef = useRef('') // Track the current search value
  const debounceTimeoutRef = useRef(null) // Reference for debounce timeout

  const [open, setOpen] = useState(false)
  const [searchedFlows, setSearchedFlows] = useState({ data: [], isSearching: false })

  const handleClickOpen = () => setOpen(true)

  const handleClose = () => {
    setSearchedFlows({ data: [], isSearching: false })
    setOpen(false)
  }

  const triggerSearch = async (searchValue) => {
    if (!searchValue || /^\s*$/.test(searchValue)) {
      setSearchedFlows({ data: [], isSearching: false })
      return
    }

    try {
      const data = await getGlobalData(searchValue, orgId)
      setSearchedFlows({ data: data.data.data.data, isSearching: true })
    } catch (error) {
      setSearchedFlows({ data: [], isSearching: false })
    }
  }

  const handleSearchChange = (e) => {
    const value = e.target.value
    searchRef.current = value // Update the current search value

    // Clear any existing debounce timeout
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current)
    }

    // Set a new debounce timeout
    debounceTimeoutRef.current = setTimeout(() => {
      // Only trigger search if the value matches the current input
      if (value === searchRef.current) {
        triggerSearch(value)
      }
    }, 300)
  }

  useEffect(() => {
    // Clean up the debounce timeout when the component unmounts
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current)
      }
    }
  }, [])

  const convertToTemplate = async (flowObj) => {
    const newTemplate = await convertScriptToTemplate(flowObj)
    const { identifier, metadata, title } = newTemplate
    const newTemplateObj = {
      id: identifier,
      metadata,
      title,
      org_id: orgId
    }
    setTemplates((prev) => [...prev, newTemplateObj])
    handleClose()
  }

  return (
    <>
      <Box className='p-2'>
        <Button variant='outlined' onClick={handleClickOpen}>
          Create New Template
        </Button>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '500px',
            height: '500px'
          }
        }}
      >
        <DialogTitle>Create Template</DialogTitle>
        <DialogContent className='w-full gap-2 flex flex-col'>
          <DialogContentText>Search and choose a flow to make it template</DialogContentText>
          <TextField autoFocus onChange={handleSearchChange} placeholder='Search Flows' fullWidth />

          <DialogContentText>
            {searchedFlows?.data?.length
              ? 'List of flows'
              : 'Flows should include either a title or a description to be eligible for listing and template creation.'}
          </DialogContentText>
          {searchedFlows?.data?.map((projectGroup) => (
            <List key={projectGroup?.project_id}>
              {projectGroup?.script_id?.map((option) => (
                <ListItem disablePadding onClick={() => convertToTemplate(option)} key={option.id}>
                  <ListItemButton>
                    <ListItemText primary={option.title || `${option.serviceName || option.serviceId} Flow`} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          ))}
        </DialogContent>
        <DialogActions className='px-3'>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default React.memo(addUrlDataHoc(React.memo(AddTemplateButton), [ParamsEnums.orgId]))
