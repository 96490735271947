import cloneDeep from 'lodash.clonedeep'
import { MiscTypes } from '../../enums'
import {
  saveProjects,
  fetchProjects,
  removeProjects,
  updateProjects,
  changeProjectStatus,
  addOneProjectThunk,
  moveProjectThunk
} from './projectsThunk'

export const initialState = {
  projects: {},
  status: 'idle',
  isLoading: true,
  fetchError: '',
  embedProject: {
    webhookurl: ''
  }
}
let previousProjects = {}
export const reducers = {}

export function extraReducers(builder) {
  builder
    // save Projects
    .addCase(saveProjects.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(saveProjects.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      const projectType = payload.payload.type === MiscTypes.EMBED_MODE ? 'embedProject' : 'projects'
      if (payload?.payload?.payload) {
        const { id, org_id, status } = payload.payload.payload

        const statusKey =
          String(status)?.toLowerCase().trim() === '0' ? 'deleted' : String(status)?.toLowerCase().trim() === '2' ? 'paused' : 'active'

        if (!state[projectType][org_id]) {
          state[projectType][org_id] = {}
        }
        state[projectType][org_id][statusKey] = state[projectType][org_id]?.[statusKey]
          ? { ...state[projectType][org_id]?.[statusKey], [id]: payload.payload.payload }
          : { [id]: payload.payload.payload }
      }
    })
    .addCase(saveProjects.rejected, (state) => {
      state.status = 'failed'
    })

    // fetch Projects
    .addCase(fetchProjects.pending, (state) => {
      state.status = 'loading'
      state.isLoading = true
    })
    .addCase(fetchProjects.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      const projectType = payload.payload.type === MiscTypes.EMBED_MODE ? 'embedProject' : 'projects'
      if (!state[projectType]) state[projectType] = {}
      if (payload.payload.orgId) state[projectType][payload.payload.orgId] = {}
      if (payload.payload) {
        if (Array.isArray(payload?.payload?.projectsData)) {
          payload.payload.projectsData.forEach((project) => {
            const { id, org_id, status } = project
            const statusKey =
              String(status)?.toLowerCase().trim() === '0' ? 'deleted' : String(status)?.toLowerCase().trim() === '2' ? 'paused' : 'active'
            if (!state[projectType][org_id]) {
              state[projectType][org_id] = {}
            }
            state[projectType][org_id][statusKey] = state[projectType][org_id]?.[statusKey]
              ? { ...state[projectType][org_id]?.[statusKey], [id]: project }
              : { [id]: project }
          })
        }
      }
      state.isLoading = false
      state.fetchError = ''
    })
    .addCase(fetchProjects.rejected, (state) => {
      state.status = 'failed'
      state.isLoading = false
      state.fetchError = `Oops! We couldn't fetch the data`
    })

    //   remove Projects
    .addCase(removeProjects.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(removeProjects.fulfilled, (state) => {
      state.status = 'succeeded'
      state.projects = {}
    })
    .addCase(removeProjects.rejected, (state) => {
      state.status = 'failed'
    })

    //   update Function
    .addCase(updateProjects.pending, (state, payload) => {
      previousProjects = cloneDeep(state.projects)
      const { org_id, project_id, title, settings } = payload.meta.arg.dataTosend
      const { type } = payload.meta.arg
      const targetProjects = type === 'embed' ? 'embedProject' : 'projects'
      if (project_id in state[targetProjects][org_id].active) {
        state[targetProjects] = {
          ...state[targetProjects],
          [org_id]: {
            ...state[targetProjects][org_id],
            active: {
              ...state[targetProjects][org_id].active,
              [project_id]: {
                ...state[targetProjects][org_id].active[project_id],
                title: title,
                settings: settings
              }
            }
          }
        }
      } else if (project_id in state[targetProjects][org_id].paused) {
        state[targetProjects] = {
          ...state[targetProjects],
          [org_id]: {
            ...state[targetProjects][org_id],
            paused: {
              ...state[targetProjects][org_id].paused,
              [project_id]: {
                ...state[targetProjects][org_id].active[project_id],
                title: title,
                settings: settings
              }
            }
          }
        }
      } else {
        state[targetProjects] = {
          ...state[targetProjects],
          [org_id]: {
            ...state[targetProjects][org_id],
            deleted: {
              ...state[targetProjects][org_id].deleted,
              [project_id]: {
                ...state[targetProjects][org_id].deleted[project_id],
                title: title
              }
            }
          }
        }
      }
      state.status = 'loading'
    })
    .addCase(updateProjects.fulfilled, (state, action) => {
      state.status = 'succeeded'
      const projectType = action?.payload?.type === MiscTypes.EMBED_MODE ? 'embedProject' : 'projects'
      if (action.payload.payload) {
        const { id, org_id, status } = action.payload.payload
        const statusKey =
          String(status)?.toLowerCase().trim() === '0' ? 'deleted' : String(status)?.toLowerCase().trim() === '2' ? 'paused' : 'active'
        if (!state[projectType][org_id]) {
          state[projectType][org_id] = {}
        }
        state[projectType][org_id][statusKey] = { ...state[projectType][org_id][statusKey], [id]: action.payload.payload }
      }
      previousProjects = {}
    })
    .addCase(updateProjects.rejected, (state) => {
      state.projects = { ...previousProjects }
      state.status = 'failed'
      previousProjects = {}
    })

    // update script status
    .addCase(changeProjectStatus.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(changeProjectStatus.fulfilled, (state, payload) => {
      state.status = 'succeeded'
      if (payload.payload) {
        const {
          status,
          projectInfo,
          projectInfo: { id, org_id }
        } = payload.payload
        // Check the status and determine where to store the data
        const statusKey =
          String(status)?.toLowerCase().trim() === '0' ? 'deleted' : String(status)?.toLowerCase().trim() === '2' ? 'paused' : 'active'
        // const otherStatusKey = statusKey === 'deleted' ? 'active' : 'deleted'
        // Create or update the corresponding storage area
        if (!state.projects[org_id]) {
          state.projects[org_id] = {}
        }
        // if (state.projects[org_id][otherStatusKey] && state.projects[org_id][otherStatusKey][id]) {
        delete state.projects[org_id]?.['active']?.[id]
        delete state.projects[org_id]?.['deleted']?.[id]
        delete state.projects[org_id]?.['paused']?.[id]
        // }
        // Update the specific project information
        state.projects[org_id][statusKey] = { ...state.projects[org_id][statusKey], [id]: { ...projectInfo, status } }
      }
    })
    .addCase(changeProjectStatus.rejected, (state) => {
      state.status = 'failed'
    })

    // Adding single project inside a project reducer.
    .addCase(addOneProjectThunk.fulfilled, (state, payload) => {
      const projectType = payload.payload.type === MiscTypes.EMBED_MODE ? 'embedProject' : 'projects'
      const { id, org_id, status } = payload.payload.singleProjectData
      if (!state[projectType][org_id]) state[projectType][org_id] = {}
      const statusKey = String(status)?.toLowerCase()?.trim() === '0' ? 'deleted' : 'active'
      if (!state[projectType][org_id][statusKey]) {
        state[projectType][org_id][statusKey] = {}
        state[projectType][org_id][statusKey] = { ...state[projectType][org_id][statusKey], [id]: payload.payload.singleProjectData }
      }
      state[projectType][org_id][statusKey] = { ...state[projectType][org_id][statusKey], [id]: payload.payload.singleProjectData }
    })
    .addCase(addOneProjectThunk.pending, (state) => {
      state.status = 'loading'
    })
    .addCase(addOneProjectThunk.rejected, (state) => {
      state.status = 'failed'
    })

    // moving project from one org to another org
    .addCase(moveProjectThunk.fulfilled, (state, payload) => {
      // Determine the project type based on the payload type
      const projectType = payload.payload.type === MiscTypes.EMBED_MODE ? 'embedProject' : 'projects'

      // Destructure the necessary properties from the payload
      const { id, old_org_id, new_org_id, status } = payload.payload

      // Determine the status key based on the status value
      const statusKey = String(status)?.toLowerCase().trim() === '0' ? 'deleted' : 'active'

      // Get the project from the state
      const project = state[projectType][old_org_id][statusKey][id]

      // Delete the project from the old organization
      delete state[projectType][old_org_id][statusKey][id]

      // Move the project to the new organization
      state[projectType][new_org_id][statusKey][id] = project
    })
    .addCase(moveProjectThunk.pending, (state) => {
      // Set the status to 'loading' when the moveProjectThunk is pending
      state.status = 'loading'
    })
    .addCase(moveProjectThunk.rejected, (state) => {
      // Set the status to 'failed' when the moveProjectThunk is rejected
      state.status = 'failed'
    })
}
