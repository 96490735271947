import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import { Box, Collapse, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getGlobalData } from '../../api/index'
import config from '../../config'
import { ParamsEnums, Tabnames } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import './search.scss'

function GlobalSearch({ orgId, filterQuery, scriptId }) {
  const navigate = useNavigate()
  const [globalData, setGlobalData] = useState({ data: [], isSearching: false })

  const { allProjects } = useCustomSelector((state) => ({
    allProjects: state?.projects?.projects[orgId]
  }))

  useEffect(() => {
    const fetchData = async () => {
      if (!filterQuery || /^\s*$/.test(filterQuery)) {
        setGlobalData((prev) => ({ ...prev, isSearching: false }))
        return
      }
      try {
        setGlobalData((prev) => ({ ...prev, isSearching: true }))
        const data = await getGlobalData(filterQuery, orgId)
        setGlobalData({ data: data.data.data.data, isSearching: true })
      } catch (error) {
        setGlobalData((prev) => ({ ...prev, isSearching: false }))
      }
    }

    const debounceTimeout = setTimeout(() => {
      fetchData()
    }, 250) // 500ms debounce delay

    return () => clearTimeout(debounceTimeout)
  }, [filterQuery, orgId])

  const groupedOptions = useMemo(() => {
    const grouped = globalData?.data?.reduce((acc, project) => {
      project.script_id.forEach((script) => {
        const groupKey = project.project_id === `proj${orgId}` ? script.serviceName || script.serviceId : project.project_id

        if (!acc[groupKey]) acc[groupKey] = []
        acc[groupKey].push({ ...script, project_id: project.project_id })
      })
      return acc
    }, {})

    return grouped || {}
  }, [globalData, orgId])

  const handleOptionClick = (option) => {
    if (option?.id) {
      navigate(`${config.projectsBaseUrl}/${orgId}/${option?.project_id}${config.workflowBaseUrl}/${option?.id}/${Tabnames.DRAFT}`)
    }
  }
  const [expanded, setExpanded] = useState(() => {
    // Initialize all groups as expanded by default
    const groups = Object.keys(groupedOptions)
    return groups.reduce((acc, group) => ({ ...acc, [group]: true }), {})
  })

  return (
    <Box className='w-full flex flex-col overflow-y-auto'>
      <Typography>Search results</Typography>
      {Object.keys(groupedOptions || {}).length > 0 ? (
        Object.entries(groupedOptions || {}).map(([group, options]) => (
          <div key={group}>
            <ListItemButton disableGutters className='pl-1' onClick={() => setExpanded((prev) => ({ ...prev, [group]: !prev[group] }))}>
              <ArrowForwardIosSharpIcon expandAcoordion isExpanded={!expanded[group]} />
              <ListItemText>
                <Typography variant='smallHeading'>
                  {allProjects?.active?.[group]?.title || allProjects?.deleted?.[group]?.title || group}
                </Typography>
              </ListItemText>
            </ListItemButton>
            <Collapse in={!expanded[group]} timeout='auto' unmountOnExit>
              <Box className='border-l-2 border-gray-200 ml-3'>
                {options.map((option) => (
                  <ListItem disablePadding active={option?.id === scriptId} key={option.id} className='pl-6'>
                    <ListItemButton onClick={() => handleOptionClick(option)} disableGutters className='px-2'>
                      <ListItemText primary={option.title || `${option.serviceName || option.serviceId} Flow`} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Box>
            </Collapse>
          </div>
        ))
      ) : (
        <Typography variant='body1' className='text-gray-500 text-center'>
          No search results found
        </Typography>
      )}
    </Box>
  )
}

export default React.memo(addUrlDataHoc(React.memo(GlobalSearch), [ParamsEnums.orgId, ParamsEnums.scriptId]))
