// refactored version of sliderLayout component
import { Box } from '@mui/system'
import React, { Suspense, lazy, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Outlet, useLocation } from 'react-router-dom'
import { ParamsEnums } from '../../enums'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { closeModalRedux, updateAppInfoState } from '../../store/appInfo/appInfoSlice.ts'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import './sliderLayoutV2.scss'
import CustomModal from '../customModal/customModal'
import DuplicateFlowModal from '../functiondashboard/workFlow/duplicateFlowModal/duplicateFlowModal.tsx'
import FlowDelayModal from '../FlowDelayModal/FlowDelayModal.tsx'

const AppIntegrationAndCronSlider = lazy(() => import('../whenSlider/appIntegrationAndCronSlider.tsx'))

function SliderLayoutV2({ projectId, scriptId, interfaceId, serviceId, eventId }) {
  // USE-DISPATCH.
  const dispatch = useDispatch()
  // USE-LOCATION.
  const location = useLocation()
  // PARAMETER.
  const { isProjectSliderOpen, modalType } = useCustomSelector((state) => ({
    isProjectSliderOpen: state.appInfo.isProjectSliderOpen,
    modalType: state.appInfo.modalType
  }))

  useEffect(() => {
    dispatch(
      updateAppInfoState({
        isPluginActionSelectOpen: false,
        isProjectSliderOpen: !scriptId && !interfaceId ? Boolean(serviceId) || Boolean(projectId) : isProjectSliderOpen
      })
    )
  }, [projectId, serviceId])

  return (
    <Box className='flex w-100'>
      {serviceId && !scriptId && !eventId && !location.pathname.includes('appsexplore') && (
        <Suspense fallback={<div>Loading...</div>}>
          <AppIntegrationAndCronSlider />
        </Suspense>
      )}
      <Outlet />

      <CustomModal openModal={modalType === 'duplicateScript' || modalType === 'MoveScript'} onClose={() => dispatch(closeModalRedux())}>
        <Box>
          <DuplicateFlowModal title={modalType === 'duplicateScript' ? 'Duplicate Flow' : 'Move Flow'} />
        </Box>
      </CustomModal>
      <CustomModal openModal={modalType === 'delayModal'} onClose={() => dispatch(closeModalRedux())}>
        <Box>
          <FlowDelayModal />
        </Box>
      </CustomModal>
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(SliderLayoutV2), [
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.interfaceId,
    ParamsEnums.embedding,
    ParamsEnums.serviceId,
    ParamsEnums.eventId,
    ParamsEnums.orgId
  ])
)
