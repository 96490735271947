import { useNavigate } from 'react-router-dom'
import { Box, Card, List, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material'
import WebhookIcon from '@mui/icons-material/Webhook'
import React, { useMemo } from 'react'
import { styled } from '@mui/system'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../../enums'
import { useCustomSelector } from '../../../utils/deepCheckSelector'
import config from '../../../config'
import IconWrapper from '../../IconWrapper/IconWrapper.tsx'
import { getAppIntegrationIds } from '../../../store/scripts/scriptsSelector'
import EditPencil from '../../Icons/EditPencil.tsx'

const CustomListItemButton = styled(ListItemButton)(() => ({
  borderBottom: '1px solid var( --col-grey-two)'
}))

function getCommonScriptsBetweenServices(service1, service2) {
  const service1scripts = service1?.active || []
  const service2scripts = service2?.active || []

  const commonScripts = service1scripts.filter((script) => service2scripts.includes(script))

  return commonScripts
}

type EnabledFlowsProps = {
  projectId?: string
  firstServiceId: string
  orgId: string
  serviceId?: string
  eventId?: string
  isEmbedUrl?: boolean
}

interface FlowCardProps {
  script: any
  orgId: string
  serviceId?: string
  firstServiceId: string
  isEmbedUrl?: boolean
  navigate: (url: string) => void
}

function FlowCard({ script, orgId, serviceId, firstServiceId, isEmbedUrl, navigate }: FlowCardProps) {
  const serviceIdToNavigate = serviceId || firstServiceId
  const flowUrl = isEmbedUrl
    ? `/integrations/embed/${orgId}/${script?.project_id}${serviceId ? `/service/${serviceId}` : ''}/workflow/${script.id}/${
        Tabnames.DRAFT
      }`
    : `${config.projectsBaseUrl}/${orgId}/${script?.project_id}${serviceIdToNavigate ? `/service/${serviceIdToNavigate}` : ''}${
        config.workflowBaseUrl
      }/${script.id}/${Tabnames.DRAFT}`

  return (
    <Box key={script.id} onClick={() => navigate(flowUrl)}>
      <Tooltip key={script.id} title={script?.metadata?.description || 'No description available'} arrow placement='top'>
        <CustomListItemButton fullWidth>
          {script?.json_script?.trigger?.iconUrl ? (
            <IconWrapper iconUrl={script?.json_script?.trigger?.iconUrl} size='20px' />
          ) : (
            <IconWrapper component={<WebhookIcon className='w-full h-full' />} size='20px' />
          )}
          <ListItemText
            className='!ml-3'
            primary={script?.title || `${script?.json_script?.trigger?.serviceName || script?.json_script?.trigger?.triggerType} Flow`}
          />
          {Boolean(Object.entries(script?.metadata?.drafted?.versioning || {})?.length) && <EditPencil />}
        </CustomListItemButton>
      </Tooltip>
    </Box>
  )
}

/**
 * EnabledFlows component displays a list of enabled flows for a given project and service.
 *
 * @component
 * @param {Object} props - The component props
 * @param {string} [props.projectId] - The ID of the project
 * @param {string} props.firstServiceId - The ID of the first service
 * @param {string} props.orgId - The ID of the organization
 * @param {string} [props.serviceId] - The ID of the second service (optional)
 * @param {string} [props.eventId] - The ID of the event (optional)
 * @param {boolean} [props.isEmbedUrl] - Flag indicating if the component is used in an embedded URL
 *
 * @returns {JSX.Element|null} The rendered EnabledFlows component or null if no enabled flows
 *
 * @description
 * This component fetches and displays a list of enabled flows based on the provided service and event IDs.
 * It allows navigation to individual flow pages and shows the status of each flow.
 */
function EnabledFlows({ projectId, firstServiceId, orgId, serviceId, eventId, isEmbedUrl }: EnabledFlowsProps) {
  const navigate = useNavigate()
  const { scriptList, enabledPluginsObject } = useCustomSelector((state) => ({
    scriptList: Object.values(state.scripts.scripts)
      .filter((script) => !projectId || script.project_id === projectId)
      .reduce((acc, script) => {
        acc[script.id] = script
        return acc
      }, {}),
    enabledPluginsObject: getAppIntegrationIds(state, projectId)
  }))

  const scriptIdList = useMemo(() => {
    let arrayOfScriptids = enabledPluginsObject?.[firstServiceId]?.active || []
    if (eventId)
      arrayOfScriptids =
        enabledPluginsObject?.[firstServiceId]?.triggers?.[eventId] || enabledPluginsObject?.[firstServiceId]?.actionid?.[eventId] || []
    else if (serviceId)
      arrayOfScriptids = enabledPluginsObject?.[serviceId]
        ? getCommonScriptsBetweenServices(enabledPluginsObject?.[firstServiceId], enabledPluginsObject?.[serviceId])
        : []

    return arrayOfScriptids
  }, [eventId, enabledPluginsObject, serviceId, firstServiceId])

  const activeScripts = scriptIdList.filter((scriptId) => {
    const script = scriptList[scriptId]
    return script && Boolean(script?.metadata?.published?.versioning?.length) && script?.status !== '0'
  })

  const inactiveScripts = scriptIdList.filter((scriptId) => {
    const script = scriptList[scriptId]
    return script && !script?.metadata?.published?.versioning?.length && script?.status !== '0'
  })

  return (
    <Box className='w-full flex gap-4'>
      {/* Active Scripts */}
      {activeScripts?.length > 0 && (
        <List disablePadding className='overflow-y-auto w-1/2'>
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            Active
          </Typography>
          <Card>
            {activeScripts?.map((scriptId) => {
              const script = scriptList[scriptId]
              return script ? (
                <FlowCard
                  script={script}
                  orgId={orgId}
                  serviceId={serviceId}
                  firstServiceId={firstServiceId}
                  isEmbedUrl={isEmbedUrl}
                  navigate={navigate}
                />
              ) : null
            })}
          </Card>
        </List>
      )}

      {/* Inactive Scripts */}
      {inactiveScripts?.length > 0 && (
        <List disablePadding className='overflow-y-auto w-1/2'>
          <Typography variant='h6' sx={{ color: 'text.secondary' }}>
            Inactive
          </Typography>
          <Card>
            {inactiveScripts?.map((scriptId) => {
              const script = scriptList[scriptId]
              return script ? (
                <FlowCard
                  script={script}
                  orgId={orgId}
                  serviceId={serviceId}
                  firstServiceId={firstServiceId}
                  isEmbedUrl={isEmbedUrl}
                  navigate={navigate}
                />
              ) : null
            })}
          </Card>
        </List>
      )}
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(EnabledFlows), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.eventId,
    ParamsEnums.isEmbedUrl
  ])
)
