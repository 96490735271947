import React, { useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import Avatar from '@mui/material/Avatar'
import { TextField, Typography, Box, Button, Container, CircularProgress, Tooltip, Card } from '@mui/material'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import { getScopesOfParticularAuth, getPluginData } from '../../api'
import { useFetchActionsTriggers, useFetchPlugins } from '../../react-query/allPluginsData/allPluginsDataQueries.ts'
import config from '../../config'
import { EventPageSkeleton } from '../../components/skeleton/eventPageSkeleton'
import { AuthData } from '../../types/Authdata.ts'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import IOSSwitch from '../../components/common/IOSSwitch.tsx'

function EventPage({
  serviceId,
  authidtoupdatetoken,
  isUpdate: update,
  authrowid,
  mode,
  filteredActions: emebedActionsFilter,
  skipactionselection
}) {
  const isUpdate = update === 'true'
  const authIdToUpdate = authidtoupdatetoken
  const navigate = useNavigate()
  const { state: locationState } = useLocation()
  const [searchAction, setSearchAction] = useState('')
  const [selectedPlugin, setSelectedPlugin] = useState(locationState?.pluginDetails || {}) // getting locationState?.pluginDetails from previous component [ServicePage]
  const { data: actionList, isLoading } = useFetchActionsTriggers(
    serviceId || '',
    'both',
    emebedActionsFilter ? (emebedActionsFilter || '')?.split(',') : undefined
  )
  const { data: pluginData, isLoading: isPluginLoading } = isUpdate
    ? useFetchPlugins(serviceId || '', [], [serviceId as string]) || {}
    : { isLoading: false }
  const [allActionList, setAllActionList] = useState([])

  const [selectedActions, setSelectedActions] = useState({}) // new state for selected actions
  const [unmatchingActions, setUnmatchingActions] = useState([])
  const [authInfo, setAuthInfo] = useState({})
  const [showLoading, setShowLoading] = useState(!!authrowid && !isUpdate) // to show loading spinner, when checking valid actions for preferecd auth.
  // calls initially to mark the actions selected if the authIdToUpdate is passed
  const getAuthTokenScopesAndUpdateState = async (allActionsLocal) => {
    const authData: AuthData = await getScopesOfParticularAuth(authIdToUpdate)
    const scopes = authData.scope || []

    const response = await getPluginData({ pluginrecordid: serviceId ?? '', rowid: authData.auth_version_id ?? '' }, 'auth')
    setAuthInfo(response)

    const actionsWithScopesInAuthData = allActionsLocal.filter((action: any) => {
      const actionScopes = action?.scopes?.split(response?.[0]?.scopeseperatedby === 'space' ? ' ' : ',') || []
      return actionScopes?.every((scope) => scopes.includes(scope)) && action?.authidlookup === authData.auth_version_id
    })

    const unmatchingActions = allActionsLocal.filter((action: any) => action?.authidlookup !== authData.auth_version_id)
    setUnmatchingActions(unmatchingActions)

    const mapping = {}
    actionsWithScopesInAuthData.forEach((action: any) => {
      mapping[action.rowid] = action
    })
    setSelectedActions(mapping)
  }

  const filteredActions = Object.values(actionList || [])?.filter(
    (action: any) =>
      action?.authtype?.toLowerCase() !== 'noauth' &&
      action?.type?.toLowerCase() !== 'custom_action' &&
      action?.name?.toLowerCase()?.includes(searchAction?.toLowerCase())
  )

  useEffect(() => {
    const allActionsLocal = Object.values(actionList || [])?.filter((action: any) =>
      action?.name?.toLowerCase()?.includes(searchAction?.toLowerCase())
    )
    setAllActionList(actionList ? allActionsLocal : [])
    if (isUpdate && authIdToUpdate) {
      getAuthTokenScopesAndUpdateState(allActionsLocal)
    }
    const authIdLookupCount: any = {}
    let maxAuthIdLookup: {
      authidlookup: string | null
      count: number
    } = { authidlookup: null, count: 0 }
    filteredActions?.forEach((object) => {
      if (object?.authidlookup) {
        authIdLookupCount[object?.authidlookup] = (authIdLookupCount[object?.authidlookup] || 0) + 1
      }
    })
    Object.keys(authIdLookupCount).forEach((key) => {
      if (authIdLookupCount[key] > maxAuthIdLookup.count) {
        maxAuthIdLookup = { authidlookup: key, count: authIdLookupCount[key] }
      }
    })
    const mapping = {}
    filteredActions.forEach((action: any) => {
      if (action?.authidlookup === maxAuthIdLookup.authidlookup) mapping[action.rowid] = action
    })
    setSelectedActions(mapping)
    setUnmatchingActions(filteredActions?.filter((item) => item?.authidlookup !== maxAuthIdLookup.authidlookup))
  }, [actionList])

  useEffect(() => {
    if (!isUpdate || isPluginLoading) return
    setSelectedPlugin(Object.values(pluginData || {})[0])
  }, [isPluginLoading])

  /**
   * Combines the scopes from selected actions and authentication information.
   *
   * This function aggregates scopes from the selected actions and the authentication information.
   * It first determines the separator to use for splitting scopes based on the `scopeseperatedby` property.
   * Then, it collects scopes from the selected actions and the authentication information,
   * removes duplicates, and returns the combined scopes as a single string.
   *
   * @returns {string} A string of unique combined scopes separated by the determined separator.
   */
  const getAuthAndActionScopesCombined = (selectedActionArray, authDataToSend) => {
    let combinedScopes = []
    const scopesSeperator = authDataToSend?.[0]?.scopeseperatedby === 'space' ? ' ' : ','
    combinedScopes =
      selectedActionArray?.length > 0
        ? selectedActionArray.reduce((acc, obj) => {
            return acc.concat(obj?.scopes?.split(scopesSeperator) || []) // scope separator is used to separate from different array and to remove duplicacy of the scopes
          }, [])
        : []
    if (authDataToSend?.[0]?.scopes) {
      combinedScopes = combinedScopes.concat(authDataToSend[0].scopes?.split(scopesSeperator))
    }
    const uniqueScopes = Array.from(new Set(combinedScopes))
    return uniqueScopes.join(scopesSeperator)
  }

  const searchActionData = (e) => {
    setSearchAction(e.target.value)
  }
  /**
   * This function handles the authorization of selected actions.
   * It constructs a URL with the necessary query parameters and navigates to it.
   * The URL includes the service ID and the authentication ID of the first selected action.
   * The function also logs the combined scopes, backButton state, and action ID for debugging purposes.
   *
   * @returns {void}
   */

  const AuthorizeActions = () => {
    // const rowids = state.selectedActions.map((obj) => obj?.actionversionrecordid).join(',')
    const rowids = Object.values(selectedActions)
      ?.map((obj) => obj?.rowid)
      .join(',')
    let url = `/auth/service/${serviceId}/auth/${Object.values(selectedActions)[0]?.authidlookup}`
    const { search } = window.location
    const actionid = rowids
    const queryParams = new URLSearchParams(search)

    queryParams.set('actionid', actionid)
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`
    }

    navigate(url, {
      state: {
        scopes: getAuthAndActionScopesCombined(Object.values(selectedActions), authInfo),
        backButton: true,
        actionId: Object.values(selectedActions)[0]?.actionversionrecordid
      }
    })
  }

  async function navigateToPreferredAuth(fromMarketplace: boolean) {
    const authDataToSend = await getPluginData({ pluginrecordid: serviceId ?? '', rowid: authrowid ?? '' }, 'auth')
    const actionListArray = Object.values(actionList || {})

    const filteredActions = actionListArray?.filter((action) => action?.authidlookup === authrowid)
    const rowids = filteredActions?.map((obj) => obj?.rowid).join(',')
    let url = `/auth/service/${serviceId}/auth/${authrowid}`
    const { search } = window.location
    const actionid = rowids
    const queryParams = new URLSearchParams(search)

    queryParams.set('actionid', actionid)
    if (queryParams.toString()) {
      url += `?${queryParams.toString()}`
    }

    if (!fromMarketplace) {
      setAuthInfo(authDataToSend)
    }
    const navigateToAuthPage = fromMarketplace || filteredActions?.length === actionListArray?.length
    if (navigateToAuthPage) {
      navigate(url, {
        state: {
          scopes: getAuthAndActionScopesCombined(filteredActions, authDataToSend),
          backButton: true,
          actionId: filteredActions?.[0]?.actionversionrecordid
        }
      })
    } else if (filteredActions.length) {
      onSelectAction(filteredActions[0], Object.values(actionList || {}))
    }
    setShowLoading(false)
  }

  useEffect(() => {
    if (authrowid && !isLoading && !authIdToUpdate) {
      setShowLoading(true)
      navigateToPreferredAuth(skipactionselection === 'true')
    }
  }, [authrowid, isLoading])
  /**
   * This function handles the authorization of selected actions.
   * It constructs a URL with the necessary query parameters and navigates to it.
   * The URL includes the service ID and the authentication ID of the first selected action.
   * The function also logs the combined scopes, backButton state, and action ID for debugging purposes.
   *
   * @returns {void}
   */
  const handleBackButton = async () => {
    if (isUpdate) return
    let url = config.servicePage
    const { search } = window.location
    if (search) {
      url += search
    }
    navigate(url)
  }

  /**
   * Handles the selection and deselection of actions for authentication.
   * When an action is selected, it fetches the corresponding authentication data,
   * updates the state with the fetched data, and determines which other actions
   * match or do not match the selected action based on their scopes.
   *
   * @param {object} object - The action object that was clicked.
   */
  const onSelectAction = async (action, filteredAction) => {
    if (unmatchingActions?.some((item) => item?.rowid === action?.rowid)) return

    const updatedSelectedActions = { ...selectedActions }

    if (Object.prototype.hasOwnProperty.call(selectedActions, action.rowid)) {
      delete updatedSelectedActions[action.rowid]
    } else {
      updatedSelectedActions[action.rowid] = action
    }
    setSelectedActions(updatedSelectedActions)

    let unmatchingActionsLocal = unmatchingActions
    if (Object.keys(updatedSelectedActions)?.length === 0) {
      if (isUpdate && authIdToUpdate) {
        unmatchingActionsLocal = filteredAction.filter((currentAction: any) => currentAction?.authidlookup !== authrowid)
      } else {
        unmatchingActionsLocal = []
      }
    } else {
      unmatchingActionsLocal = filteredAction.filter((currentAction: any) => currentAction?.authidlookup !== action?.authidlookup)
    }
    setUnmatchingActions(unmatchingActionsLocal)

    const response = await getPluginData({ pluginrecordid: serviceId ?? '', rowid: action?.authidlookup ?? '' }, 'auth')
    setAuthInfo(response)
  }
  if (showLoading)
    return (
      <Box className='w-screen h-screen flex justify-center items-center'>
        {' '}
        <CircularProgress />
      </Box>
    )
  return (
    <Box className='flex justify-center w-screen min-h-screen bg-[var(--col-grey-background)]'>
      <Container className='p-4' maxWidth='md'>
        <Box className='flex-col gap-3 w-100  h-100'>
          <>
            {/* <ConnectionBreadCrumbs/> */}
            <Box className='flex-col gap-5'>
              <Typography variant='h4'>{isUpdate ? 'Update' : 'Add New'} Connection</Typography>
              {mode !== 'embed' && (
                <Box
                  className='flex-start-center w-fitcontent p-2 '
                  onClick={() => {
                    handleBackButton()
                  }}
                >
                  {!isUpdate && <ArrowBackIosIcon fontSize='small' className='color-grey' />}
                  <Avatar
                    alt={selectedPlugin?.name}
                    src={selectedPlugin?.iconurl}
                    sx={{ height: '25px', width: '25px' }}
                    className='ml-3 mr-3 '
                  />
                  <Typography variant='h6' className='color-grey'>
                    {' '}
                    {selectedPlugin?.name}
                  </Typography>
                </Box>
              )}
            </Box>
            <Typography variant='h4' className='mb-2 '>
              {' '}
              Select all the actions that you want to connect
            </Typography>
          </>

          {actionList && Object.keys(actionList).length >= 10 && (
            <Box className='mb-3'>
              <TextField
                size='small'
                width='small'
                id='services'
                name='services'
                type='text'
                label='Search actions'
                variant='outlined'
                onChange={searchActionData}
                autoFocus
              />
            </Box>
          )}

          <Box>
            {isLoading && <EventPageSkeleton />}
            <Box className='flex flex-col gap-4'>
              {['trigger', 'action'].map((type) => {
                const filterByType = filteredActions?.filter((object) => object?.type?.toLowerCase() === type)
                const selectedActionsLength = Object.keys(selectedActions)?.length
                return filterByType?.length > 0 ? (
                  <Box key={type}>
                    <Typography variant='h5'>
                      {type === 'action' ? `Action${filterByType?.length > 1 ? 's' : ''}` : `Trigger${filterByType?.length > 1 ? 's' : ''}`}
                    </Typography>
                    <Box className='gap-1 h-100 w-100 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3'>
                      {filterByType?.map((object) => {
                        const disableUnMatchedActions = unmatchingActions?.some((item) => item?.rowid === object?.rowid)
                        return (
                          <Tooltip
                            key={object?.rowid}
                            title={
                              !disableUnMatchedActions
                                ? object.name
                                : `This ${type} is not compatible with already selected one${selectedActionsLength > 1 ? 's' : ''}`
                            }
                            placement='top-start'
                          >
                            <Card
                              className={`event-page-actions truncate cursor-pointer ${
                                disableUnMatchedActions && selectedActionsLength !== 0 ? '!bg-[#f1f1f1]' : ''
                              }`}
                              onClick={() => onSelectAction(object, allActionList)}
                              key={object?.rowid}
                              component='button'
                              disabled={disableUnMatchedActions && selectedActionsLength !== 0}
                            >
                              <Typography variant='h7' className={`!truncate ${disableUnMatchedActions ? 'opacity-70' : ''}`}>
                                {object.name}
                              </Typography>
                              <IOSSwitch
                                size='medium'
                                checked={Object.prototype.hasOwnProperty.call(selectedActions, object?.rowid) || false}
                                color='success'
                                disabled={disableUnMatchedActions}
                              />
                            </Card>
                          </Tooltip>
                        )
                      })}
                    </Box>
                  </Box>
                ) : null
              })}
            </Box>
            {filteredActions?.length === 0 && !isLoading && <Typography variant='h3'>No data found</Typography>}
          </Box>
          <Button
            variant='contained'
            endIcon={<ArrowForwardIcon />}
            onClick={AuthorizeActions}
            disabled={!Object.keys(selectedActions).length}
          >
            NEXT
          </Button>
        </Box>
      </Container>
    </Box>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(EventPage), [
    'serviceId',
    'authidtoupdatetoken',
    'isUpdate',
    'authrowid',
    'mode',
    'filteredActions',
    'skipactionselection'
  ])
)
