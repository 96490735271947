import React, { useEffect, useState } from 'react'
import { Box, TextField, Typography, Autocomplete, Button, createFilterOptions } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { useDispatch } from 'react-redux'
import { useCustomSelector } from '../../utils/deepCheckSelector'
import { ParamsEnums } from '../../enums'
import { renameOrgThunk } from '../../store/orgs/orgsThunk'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import './SettingsPanel.scss'
import { extractDomainFromString, validateOrgName } from '../../utils/utilities'
import { selectActiveOrgs } from '../../store/orgs/orgsSelector'
import { errorToast } from '../customToast'
import timezoneData from './timezone'
import { isValidDomainName } from '../../pages/developerHub/utils/utility.ts'
import { getIndustriesList } from '../../api/index'

export function Header({ isCron }) {
  return (
    <Box className={`flex flex-col w-full ${isCron ? 'pl-3 pb-3' : 'p-3'}`}>
      <Typography variant={isCron ? 'h5' : 'h6'}>Edit workspace settings</Typography>
    </Box>
  )
}

export function Name({ handleRenameOnClick, loading, name }) {
  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight='600'>
        Name
      </Typography>
      <TextField
        className='w-full'
        defaultValue={name}
        inputProps={{ maxLength: 25 }}
        disabled={loading}
        dhcustompadding='true'
        onBlur={(event) => {
          const value = event.target.value.trim()
          if (!value) {
            errorToast('Name field cannot be empty')
          } else if (value !== name) {
            handleRenameOnClick(value)
          }
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            handleRenameOnClick(event.target.value.trim())
          }
        }}
      />
    </Box>
  )
}

export function TimeZoneSection({ children, error, timezone, handleTimezoneChange, setTimezone = () => {}, required = false }) {
  const defaultProps = {
    options: timezoneData,
    getOptionLabel: (option: { identifier: string; offSet: string }) => {
      return `${option.identifier} ${option.offSet}`
    }
  }

  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight={!required ? '600' : null}>
        Timezone{required ? '*' : ''}
      </Typography>
      <Autocomplete
        {...defaultProps}
        id='timezone-select'
        options={timezoneData}
        getOptionLabel={(option) => `${option.identifier} (${option.offSet || ''})`}
        value={timezone}
        isOptionEqualToValue={(option, value) => option.identifier === value.identifier && option.offSet === value.offSet}
        onChange={(event, newValue) => {
          if (newValue) {
            setTimezone(newValue)
            handleTimezoneChange(newValue)
          }
        }}
        renderInput={(params) => (
          <TextField {...params} variant='outlined' required={required} error={error} helperText={required ? error?.message : null} />
        )}
      />
      {children}
    </Box>
  )
}

export function IndustrySection({ children }) {
  return <Box className='flex flex-col gap-1'>{children}</Box>
}
// eslint-disable-next-line
IndustrySection.Autocomplete = ({ orgId = '', orgMeta = null, ...props }) => {
  const { selectedIndustry, setSelectedIndustry, industries, setIndustries, setPreferredIndustryObject } = props || {}
  const dispatch = useDispatch()
  const filter = createFilterOptions()

  const addIndustryIntoOrgMeta = async (newValue) => {
    const trimmedValue = newValue?.trim()
    const matchedIndustry = industries?.find((item) => item?.name?.toLowerCase() === trimmedValue?.toLowerCase())
    const newIndustry = {
      name: trimmedValue,
      slug: trimmedValue?.toLowerCase()?.split(' ')?.join('-')
    }

    // let updatedOrgMeta = null
    let updatedPreferredIndustry = null
    if (matchedIndustry) {
      setSelectedIndustry(matchedIndustry)
      updatedPreferredIndustry = {
        ...orgMeta?.preferred_industry,
        preferred: matchedIndustry
      }
    } else {
      updatedPreferredIndustry = {
        preferred: newIndustry,
        addedByUser: newIndustry
      }
      setIndustries((prev) => {
        let updatedList = [...(prev || [])]
        if (newIndustry?.name) {
          updatedList = updatedList?.filter((item) => item?.name !== orgMeta?.preferred_industry?.addedByUser?.name)
          updatedList?.push(newIndustry)
        }
        return updatedList
      })
      setSelectedIndustry(newIndustry)
    }
    setPreferredIndustryObject(updatedPreferredIndustry)
    if (props?.saveInMeta) {
      if (updatedPreferredIndustry?.preferred?.name !== orgMeta?.preferred_industry?.preferred?.name) {
        await dispatch(
          renameOrgThunk({
            body: {
              meta: {
                ...orgMeta,
                preferred_industry: updatedPreferredIndustry
              }
            },
            orgId
          })
        )
      }
    }
  }

  async function fetchIndustryData() {
    try {
      const data = await getIndustriesList()
      if (data?.success) {
        const industryList = [...(data?.data || [])]
        const isPreferredPresent = industryList.some(
          (item) => item?.name?.toLowerCase() === orgMeta?.preferred_industry?.preferred?.name?.toLowerCase()
        )
        if (!isPreferredPresent) {
          const condition = orgMeta?.preferred_industry?.preferred?.name || orgMeta?.preferred_industry?.addedByUser?.name
          if (condition) {
            industryList.push(
              orgMeta?.preferred_industry?.preferred?.name
                ? orgMeta?.preferred_industry?.preferred
                : orgMeta?.preferred_industry?.addedByUser?.name && orgMeta?.preferred_industry?.addedByUser
            )
          }
          if (!orgMeta?.preferred_industry && orgId) setSelectedIndustry(industryList?.[0])
        }
        setIndustries(industryList || [])
      }
    } catch (error) {
      setIndustries([])
    }
  }
  useEffect(() => {
    fetchIndustryData()
  }, [])
  return (
    <Autocomplete
      freeSolo
      componentsProps={{ ...props?.componentProps }}
      autoHighlight
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        const { inputValue } = params
        const isExisting = options.some((option) => inputValue?.toLowerCase() === option?.name?.toLowerCase())
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            name: inputValue,
            title: `Press Enter to add '${inputValue}'`
          })
        }
        return filtered
      }}
      selectOnFocus
      id='free-solo-with-text-demo'
      options={industries}
      getOptionLabel={(option) => option?.name || ''}
      renderOption={({ key, ...props }, option) => (
        <li key={key} {...props}>
          {option?.title || option?.name}
        </li>
      )}
      renderInput={(params) => <TextField {...params} {...props.autcompleteTextfieldProps} variant='outlined' />}
      value={selectedIndustry}
      onChange={(event, newValue) => {
        addIndustryIntoOrgMeta(newValue?.name || newValue)
      }}
    />
  )
}
// eslint-disable-next-line
IndustrySection.Heading = ({ typoProps, spanText = '' }) => {
  return <Typography {...typoProps}>Your Industry {spanText}</Typography>
}
// eslint-disable-next-line
IndustrySection.Example = () => (
  <Typography variant='body2' color='textSecondary'>
    Example: Education
  </Typography>
)
// eslint-disable-next-line
TimeZoneSection.Information = () => (
  <Typography variant='body2' color='textSecondary' gutterBottom>
    All logs, data, and other relevant information will be displayed according to this timezone. Additionally, your Metrics data will be
    stored, and cron jobs will be scheduled based on this timezone.
  </Typography>
)

export function DomainSection({ orgDomain, setOrgDomain }) {
  return (
    <Box className='flex flex-col gap-1'>
      <Typography variant='body1' gutterBottom fontWeight='600'>
        Domain URL
      </Typography>
      <TextField
        className='w-full'
        value={orgDomain}
        dhcustompadding='true'
        onChange={(e) => {
          setOrgDomain(e.target.value)
        }}
      />
      <Typography variant='body2' color='textSecondary'>
        Example: www.msg91.com
      </Typography>
    </Box>
  )
}

function WorkspaceSetting({
  orgId,
  handleDrawerClose = () => {},
  isCron = false,
  orgLoading = false
}: {
  orgId: string
  handleDrawerClose: () => void
  isCron: boolean
  orgLoading: boolean
}) {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { orgObj, sortedOrgList, timezoneIdentifier, timezoneOffset, orgMetaObj } = useCustomSelector((state: any) => {
    const orgObj = state?.orgs?.orgs?.[orgId]
    return {
      orgObj,
      sortedOrgList: selectActiveOrgs(state)?.sort((a, b) => a?.title?.localeCompare(b?.title)) || [],
      timezoneIdentifier: orgObj?.meta?.timezoneIdentifier,
      timezoneOffset: orgObj?.timezone,
      orgMetaObj: orgObj?.meta
    }
  })

  const [orgDomain, setOrgDomain] = useState(orgObj?.meta?.domainUrl)
  const [timezone, setTimezone] = useState<{ identifier: string; offSet: string } | null>(null)

  // Function to handle renaming the organization
  const handleRenameOnClick = async (value: string) => {
    if (!value.trim()) {
      errorToast('Name field cannot be empty')
      return
    }
    if (value.includes(' ')) {
      errorToast('Name field cannot contain spaces')
      return
    }
    if (value && validateOrgName(value, sortedOrgList)) {
      if (!loading) {
        setLoading(true)
        try {
          await dispatch(renameOrgThunk({ body: { title: value }, orgId }))
        } catch (error) {
          errorToast('Unable to update organization. Please try again.')
        } finally {
          setLoading(false)
        }
      }
    }
  }

  // Handle the timezone change logic
  useEffect(() => {
    if (timezoneIdentifier && timezoneOffset) {
      setTimezone({ identifier: timezoneIdentifier, offSet: timezoneOffset })
    }
  }, [timezoneIdentifier, timezoneOffset])

  /**
   * Updates the domain URL in the organization metadata.
   * Validates the domain name before dispatching the update.
   */
  const [orgMeta, setOrgMeta] = useState({})
  const handleTimezoneChange = (newValue: { identifier: string; offSet: string }) => {
    if (newValue) {
      try {
        setOrgMeta({ ...orgObj?.meta, timezoneIdentifier: newValue.identifier })
      } catch (error) {
        errorToast('Unable to update timezone. Please try again.')
      }
    }
  }

  /**
   * below code is for setting the preferred industry.
   * we allow user to add one industry and also sets the preferred industry.
   */

  const [selectedIndustry, setSelectedIndustry] = useState(orgMetaObj?.preferred_industry?.preferred)
  const [preferredIndustryObject, setPreferredIndustryObject] = useState(orgMetaObj?.preferred_industry)
  const [industries, setIndustries] = useState([])

  const handleSaveButton = async () => {
    handleDrawerClose()
    const trimmedOrgDomain = orgDomain ? orgDomain.trim() : ''
    const extractedOrgDomain = extractDomainFromString(trimmedOrgDomain)
    const domainurl = isValidDomainName(extractedOrgDomain)
    if (extractedOrgDomain && !domainurl) {
      errorToast('Domain is not valid')
      return
    }
    const updatedOrgMeta = { ...orgMeta, domainUrl: extractedOrgDomain, preferred_industry: preferredIndustryObject }
    await dispatch(renameOrgThunk({ body: { meta: updatedOrgMeta, timezone: timezone?.offSet }, orgId }))
  }
  const industryHeadingProps = {
    typoProps: {
      variant: 'body1',
      gutterBottom: true,
      sx: {
        fontWeight: 600
      }
    }
  }

  return (
    <Box>
      <Header isCron={isCron} />
      <Box className='px-3 py-1 flex flex-col gap-4'>
        <Name handleRenameOnClick={handleRenameOnClick} loading={loading} name={orgObj?.name} />
        <TimeZoneSection handleTimezoneChange={handleTimezoneChange} setTimezone={setTimezone} timezone={timezone}>
          <TimeZoneSection.Information />
        </TimeZoneSection>
        <IndustrySection>
          <IndustrySection.Heading {...industryHeadingProps} />
          <IndustrySection.Autocomplete
            orgId={orgId}
            orgMeta={orgMetaObj}
            selectedIndustry={selectedIndustry}
            setPreferredIndustryObject={setPreferredIndustryObject}
            setSelectedIndustry={setSelectedIndustry}
            setIndustries={setIndustries}
            industries={industries}
          />
          <IndustrySection.Example />
        </IndustrySection>
        <Box className='flex flex-col gap-4'>
          <DomainSection orgDomain={orgDomain} setOrgDomain={setOrgDomain} />
          <Box className='w-full'>
            <Box className='flex gap-3'>
              <LoadingButton
                onClick={() => {
                  handleSaveButton()
                }}
                variant='contained'
                disabled={loading}
                loading={orgLoading}
              >
                Save
              </LoadingButton>
              <Button variant='outlined' onClick={handleDrawerClose}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
export default addUrlDataHoc(React.memo(WorkspaceSetting), [ParamsEnums.orgId, ParamsEnums.projectId])

export const calculateRemainingDays = (expiry) => {
  if (!expiry) return 0
  const targetDate = new Date(expiry)
  const currentDate = new Date()
  const timeDifference = targetDate - currentDate
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  return daysRemaining
}
