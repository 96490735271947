import React from 'react'
import { TreeItem, SimpleTreeView } from '@mui/x-tree-view'
import { useNavigate } from 'react-router-dom'
import { Box, Typography } from '@mui/material'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import AlarmOnSharpIcon from '@mui/icons-material/AlarmOnSharp'
import WebhookIcon from '@mui/icons-material/Webhook'
import AdjustIcon from '@mui/icons-material/Adjust'
import addUrlDataHoc from '../../hoc/addUrlDataHoc.tsx'
import { ParamsEnums, Tabnames } from '../../enums'
import config from '../../config'
import EditPencil from '../Icons/EditPencil.tsx'
import TriggerErrorTooltipIcon from './triggerErrorComponent.tsx'

function ServiceIcon({ serviceKey }) {
  switch (serviceKey) {
    case 'cron':
      return <AlarmOnSharpIcon />
    case 'webhook':
      return <WebhookIcon />
    case 'email':
      return <AlternateEmailIcon />
    default:
      return <AdjustIcon />
  }
}

function ScriptTreeItem({ scriptId, script, serviceKey, navigate, orgId, finalProjectId }) {
  return (
    <TreeItem
      key={scriptId}
      itemId={scriptId}
      label={
        <Box display='flex' alignItems='center' gap={1}>
          <Typography variant='body2' noWrap>
            {script?.title || '[Untitled]'}
          </Typography>
          <TriggerErrorTooltipIcon scriptId={scriptId} />
          {Object.entries(script?.metadata?.drafted?.versioning || {}).length > 0 && <EditPencil />}
        </Box>
      }
      onClick={() =>
        navigate(
          `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/service/${serviceKey}${config.workflowBaseUrl}/${scriptId}/${Tabnames.DRAFT}`
        )
      }
    />
  )
}

function ServiceTreeItem({ serviceKey, serviceData, allScripts, handleNavigation, navigate, orgId, finalProjectId, currentScriptId }) {
  return (
    <TreeItem
      key={`${finalProjectId}${serviceKey}`}
      itemId={`${finalProjectId}${serviceKey}`}
      label={
        <Box display='flex' alignItems='center' gap={1} onClick={(e) => handleNavigation(e, serviceKey)}>
          <ServiceIcon serviceKey={serviceKey} />
          <Typography variant='body2'>{serviceKey.charAt(0).toUpperCase() + serviceKey.slice(1)}</Typography>
          {serviceData?.active?.length > 0 && <Typography variant='body2'>({serviceData.active.length})</Typography>}
        </Box>
      }
    >
      <SimpleTreeView className='flex flex-col gap-1 my-1' selectedItems={currentScriptId}>
        {serviceData.active.map((scriptId) => (
          <ScriptTreeItem
            key={scriptId}
            scriptId={scriptId}
            script={allScripts?.[scriptId]}
            serviceKey={serviceKey}
            navigate={navigate}
            orgId={orgId}
            finalProjectId={finalProjectId}
          />
        ))}
      </SimpleTreeView>
    </TreeItem>
  )
}

function AppIntegrationTreeItem({
  serviceKey,
  serviceData,
  allScripts,
  handleNavigation,
  navigate,
  orgId,
  finalProjectId,
  currentScriptId
}) {
  return (
    <TreeItem
      key={`${finalProjectId}${serviceKey}`}
      itemId={`${finalProjectId}${serviceKey}`}
      label={
        <Box display='flex' alignItems='center' gap={1} onClick={(e) => handleNavigation(e, serviceKey)}>
          <img src={serviceData.iconUrl} alt='' width='23px' />
          <Typography variant='body2'>{serviceData.serviceName}</Typography>
          {serviceData?.usedAsTrigger?.active?.length > 0 && (
            <Typography variant='body2'>({serviceData.usedAsTrigger.active.length})</Typography>
          )}
        </Box>
      }
    >
      <SimpleTreeView className='flex flex-col gap-1 my-1' selectedItems={currentScriptId}>
        {serviceData.usedAsTrigger.active.map((scriptId) => (
          <ScriptTreeItem
            key={scriptId}
            scriptId={scriptId}
            script={allScripts?.[scriptId]}
            serviceKey={serviceKey}
            navigate={navigate}
            orgId={orgId}
            finalProjectId={finalProjectId}
          />
        ))}
      </SimpleTreeView>
    </TreeItem>
  )
}

function ScriptTreeView({ projectId, orgId, ...props }) {
  const navigate = useNavigate()
  const { allScripts, scriptObject, defaultProjectId, serviceId, scriptId, firstServiceId } = props
  const finalProjectId = defaultProjectId || projectId

  const handleNavigation = (e, serviceKey) => {
    e.stopPropagation()
    navigate(
      !['cron', 'email', 'others'].includes(serviceKey)
        ? `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/appsexplore/servicepage/${serviceKey}`
        : `${config.projectsBaseUrl}/${orgId}/${finalProjectId}/service/${serviceKey}`
    )
  }

  return (
    <SimpleTreeView
      className='flex flex-col gap-1 my-1'
      selectedItems={`${projectId}${firstServiceId || serviceId}`}
      defaultExpandedItems={[`${projectId}${firstServiceId || serviceId}`]}
    >
      {/* Non-App Integrations */}
      {scriptObject &&
        Object.entries(scriptObject).map(
          ([key, value]) =>
            key !== 'appIntegration' &&
            value?.active?.length > 0 && (
              <ServiceTreeItem
                key={key}
                currentScriptId={scriptId}
                serviceKey={key}
                serviceData={value}
                allScripts={allScripts}
                handleNavigation={handleNavigation}
                navigate={navigate}
                orgId={orgId}
                finalProjectId={finalProjectId}
              />
            )
        )}

      {/* App Integrations */}
      {scriptObject?.appIntegration &&
        Object.entries(scriptObject.appIntegration).map(
          ([key, value]) =>
            value?.usedAsTrigger?.active?.length > 0 && (
              <AppIntegrationTreeItem
                key={key}
                serviceKey={key}
                currentScriptId={scriptId}
                serviceData={value}
                allScripts={allScripts}
                handleNavigation={handleNavigation}
                navigate={navigate}
                orgId={orgId}
                finalProjectId={finalProjectId}
              />
            )
        )}
    </SimpleTreeView>
  )
}

export default React.memo(
  addUrlDataHoc(React.memo(ScriptTreeView), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.serviceId,
    ParamsEnums.firstServiceId
  ])
)
