/* eslint-disable */
import { Box, Card, Typography } from '@mui/material'
import IconWrapper from '../../IconWrapper/IconWrapper'
import { ParamsEnums } from '../../../enums'
import addUrlDataHoc from '../../../hoc/addUrlDataHoc'
import React from 'react'
import { generateNewId } from '../../../utils/utilities'

function ShowTriggerGroupingWithActions({ triggers = [], actions = [], createflow = () => {} }) {
  return (
    <Box className='flex flex-col w-full gap-5'>
      {triggers?.map((triggerEvent) => {
        return (
          <Box className='pb-4 w-100 column' key={generateNewId()} id={`${triggerEvent?.rowid}-grouping`}>
            <Box className='flex-start-center px-2 gap-2'>
              {triggerEvent.name !== 'webhook' ? (
                <Typography>
                  When <strong>{triggerEvent.name}</strong> in{' '}
                  <span style={{ display: 'inline-flex' }}>
                    <img src={triggerEvent?.iconurl} alt='' width={18} style={{ verticalAlign: 'middle' }} />{' '}
                  </span>{' '}
                  {triggerEvent?.pluginname}
                </Typography>
              ) : null}
            </Box>
            <Box className='container pt-2 '>
              {actions?.map((pluginEvent) => {
                return (
                  <Box className='grid-item p-2'>
                    <Card
                      className='flex items-center p-2 w-full gap-3 event-box event-box__fullwidth'
                      onClick={() => createflow(triggerEvent, pluginEvent)}
                    >
                      <IconWrapper iconUrl={pluginEvent?.iconurl} size='32px' />
                      <Typography className='service-title'>{pluginEvent.name}</Typography>
                    </Card>
                  </Box>
                )
              })}
            </Box>
          </Box>
        )
      })}
    </Box>
  )
}
export default React.memo(
  addUrlDataHoc(React.memo(ShowTriggerGroupingWithActions), [
    ParamsEnums.orgId,
    ParamsEnums.projectId,
    ParamsEnums.scriptId,
    ParamsEnums.tabName,
    ParamsEnums.isTemplate,
    ParamsEnums.sectionKey,
    ParamsEnums.serviceId,
    ParamsEnums.isEmbedUrl
  ])
)
